import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import {DatePipe} from "@angular/common";
import {MatSnackBar} from '@angular/material/snack-bar';
import {AppState} from "../../../services/app.state";
import {Router} from "@angular/router";
import {environment} from '../../../environments/environment';
import {CommonService} from '../../../services/common.service';
import {UserDataService} from '../../../services/user-data.service';
import {ArticleService} from "../../../services/article.service";

@Component({
  selector: 'app-add-article',
  templateUrl: './add-article.component.html',
  styleUrls: ['./add-article.component.scss']
})
export class AddArticleComponent implements OnInit {

  // article form
  articleForm: FormGroup = this.fb.group({
    source: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
    title: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
    link: ['', [Validators.required]],
    dateOfArticle: ['', [Validators.required]],
    description: ['', [Validators.required]]
  });

  userData: any;
  isArticle: boolean = false;
  article: any;
  documents: any = '';
  AssetsURL: string = environment.assetsURL;

  @ViewChild('uploadFile') uploadFile: ElementRef;
  myfilename = 'Select File';
  isImage: boolean = false;
  imageUploaded: boolean = false;
  isLoading: boolean = false;

  addArticlePayLoad = {
    "header": {
      "message_uuid": "",
      "message_type": "Q",
      "version": "1.0",
      "message_name": "learn_section-U",
      "correlation_uuid": "",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      "user_profile_id": 0,
      "learn_title": "",
      "learn_description": "",
      "learn_link": "",
      "learn_image": "",
      "learn_source": "",
      "learn_date": "",
      "learn_status": ""
    }
  };

  updateArticlePayLoad = {
    "header": {
      "message_uuid": "",
      "message_type": "Q",
      "version": "1.0",
      "message_name": "learn_section-U",
      "correlation_uuid": "",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      "sequence_id": 0,
      "user_profile_id": 0,
      "learn_title": "",
      "learn_description": "",
      "learn_link": "",
      "learn_image": "",
      "learn_source": "",
      "learn_date": "",
      "learn_status": ""
    }
  };

  constructor(private fb: FormBuilder, private snackBar: MatSnackBar,
              private commanService: CommonService,
              private articleService: ArticleService,
              private datepipe: DatePipe, private appState: AppState,
              private router: Router, private userDataService: UserDataService) {
  }

  ngOnInit(): void {
    this.userData = this.userDataService.getUserData();
    let article = this.appState.get("ARTICLE");
    this.article = article;
    this.myfilename = article ? 'Change File' : 'Select File';

    if (article) {
      this.documents = article.learn_image;
      let learn_date = this.datepipe.transform(article.learn_date, 'yyyy-MM-dd');
      this.articleForm = this.fb.group({
        sequence_id: [article.sequence_id],
        user_profile_id: [article.user_profile_id],
        source: [article.learn_source, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
        title: [article.learn_title, [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
        link: [article.learn_link, [Validators.required]],
        dateOfArticle: [learn_date, [Validators.required]],
        description: [article.learn_description, [Validators.required]],
        status: [article.learn_status]
      });
    } else {
      this.articleForm = this.fb.group({
        user_profile_id: [4],
        source: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
        title: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$')]],
        link: ['', [Validators.required]],
        dateOfArticle: ['', [Validators.required]],
        description: ['', [Validators.required]],
        status: ['pending']
      });
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.articleForm.controls[control].hasError(error);
  }

  uploadFileChange(fileInput: any) {
    const imgData = fileInput.target.files;
  
    if (imgData && imgData[0]) {
      var allCheck = this.validateFileInput(fileInput);
      if (allCheck.doCheck) {
        this.myfilename = '';
        let fileSizeLimitMB = 2; 
        if (imgData[0].size > fileSizeLimitMB * 1024 * 1024) {
          alert(`File size exceeds the limit of ${fileSizeLimitMB} MB`);
          return;
        }
        let reader = new FileReader();
        reader.onload = async () => {
          if (allCheck.flag) {
            let documents = await this.commanService.compressFile(reader.result);
            this.documents = documents;
            this.imageUploaded = true;
          }
        };
  
        reader.readAsDataURL(fileInput.target.files[0]);
        this.myfilename = fileInput.target.files[0].name;
      }
    }
  }
  

  validateFileInput(fileInput: any) {
    this.myfilename = 'Select File';
    this.documents = '';
    this.isImage = false;
    let doCheck = true;
    let flag = false;
    if ((fileInput.target.files[0].name.includes('.jpg') || fileInput.target.files[0].name.includes('.jpeg') ||
      fileInput.target.files[0].name.includes('.png')) && fileInput.target.files.length <= 0) {
      doCheck = false;
      this.isImage = true;
      flag = true;
    } else {
      doCheck = true;
      this.isImage = false;
      flag = true;
    }
    return {doCheck: doCheck, flag: flag};
  }

  handleClick() {
    this.uploadFile.nativeElement.value = '';
    this.uploadFile.nativeElement.click();
  }

  async submit() {
    this.isArticle = true;

    if (this.articleForm.invalid || this.documents.trim() == '') {
      return
    }
    let dateOfArticle: any | string = this.datepipe.transform(this.articleForm.controls['dateOfArticle'].value, 'yyyy-MM-dd');
    this.isLoading = true;
    let parseResponse: any;
    if (this.article) {
      this.updateArticlePayLoad.body.sequence_id = this.articleForm.controls['sequence_id'].value;
      this.updateArticlePayLoad.body.user_profile_id = this.articleForm.controls['user_profile_id'].value;
      this.updateArticlePayLoad.body.learn_title = this.articleForm.controls['title'].value;
      this.updateArticlePayLoad.body.learn_description = this.articleForm.controls['description'].value;
      if (this.imageUploaded) {
        this.updateArticlePayLoad.body.learn_image = this.documents;
      }
      this.updateArticlePayLoad.body.learn_source = this.articleForm.controls['source'].value;
      this.updateArticlePayLoad.body.learn_date = dateOfArticle;
      this.updateArticlePayLoad.body.learn_status = this.articleForm.controls['status'].value;
      this.updateArticlePayLoad.body.learn_link = this.articleForm.controls['link'].value;
      this.updateArticlePayLoad.header.created_by = this.userData != undefined ? this.userData.cognitoId : '';

      parseResponse = await this.articleService.getArticle(this.updateArticlePayLoad);
    } else {
      this.addArticlePayLoad.body.user_profile_id = this.articleForm.controls['user_profile_id'].value;
      this.addArticlePayLoad.body.learn_title = this.articleForm.controls['title'].value;
      this.addArticlePayLoad.body.learn_description = this.articleForm.controls['description'].value;
      if (this.imageUploaded) {
        this.addArticlePayLoad.body.learn_image = this.documents;
      }
      this.addArticlePayLoad.body.learn_source = this.articleForm.controls['source'].value;
      this.addArticlePayLoad.body.learn_date = dateOfArticle;
      this.addArticlePayLoad.body.learn_link = this.articleForm.controls['link'].value;
      this.addArticlePayLoad.body.learn_status = this.articleForm.controls['status'].value;
      this.addArticlePayLoad.header.created_by = this.userData != undefined ? this.userData.cognitoId : '';
      parseResponse = await this.articleService.getArticle(this.addArticlePayLoad);
    }
    if (parseResponse) {
      this.isLoading = false;

      this.snackBar.open(parseResponse.message, "", {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ['success-snackbar']
      });
      this.back();
    }
  }

  back() {
    this.router.navigate(['/learn']);
  }

}
