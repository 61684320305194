import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { ListCustomerComponent } from './main/customer/list-customer/list-customer.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { AuthGuard } from './auth-guard.service';
import { ListInsuranceComponent } from './main/insurance/list-insurance/list-insurance.component';
import { UpdateInsuranceComponent } from './main/insurance/update-insurance/update-insurance.component';
import { ChatComponent } from './main/chat/chat.component';
import { DetailsCustomerComponent } from './main/customer/details-customer/details-customer.component';
import { EnrollmentComponent } from './enrollment/enrollment.component';
import { ConfirmationEnrollmentComponent } from './confirmation-enrollment/confirmation-enrollment.component';
import { ListArticleComponent } from './main/learn/list-article/list-article.component';
import { AddArticleComponent } from './main/learn/add-article/add-article.component';
import { DetailsArticleComponent } from './main/learn/details-article/details-article.component';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { FeedbackComponent } from './main/feedback/feedback.component';
import { ListAgentComponent } from './main/agents/list-agent/list-agent.component';
import { ListCustomerLeadComponent } from './main/leads/list-customer-lead/list-customer-lead.component';
import { ListAgentLeadComponent } from './main/leads/list-agent-lead/list-agent-lead.component';
import { LeadDetailsComponent } from './main/leads/lead-details/lead-details.component';
import { ListVoiComponent } from './main/voi/list-voi/list-voi.component';
import { UpdateVoiComponent } from './main/voi/voi-detail/update-voi.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'enrollment',
    component: EnrollmentComponent,
  },
  {
    path: 'confirmation',
    component: ConfirmationEnrollmentComponent,
  },
  {
    path: 'terms',
    component: TermsComponent,
  },
  {
    path: 'policy',
    component: PolicyComponent,
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'customers',
        pathMatch: 'full',
      },
      {
        path: 'customers',
        component: ListCustomerComponent,
      },
      {
        path: 'ivs',
        component: ListVoiComponent,
      },
      {
        path: 'ivs-detail/:id',
        component: UpdateVoiComponent,
      },
      {
        path: 'details-customer',
        component: DetailsCustomerComponent,
      },
      {
        path: 'customer-leads',
        component: ListCustomerLeadComponent,
      },
      {
        path: 'agent-leads',
        component: ListAgentLeadComponent,
      },
      {
        path: 'leads-detail/:id/:profileId',
        component: LeadDetailsComponent,
      },
      {
        path: 'agents',
        component: ListAgentComponent,
      },
      {
        path: 'agent-details',
        loadChildren: () =>
          import('./main/agents/details-agent/details-agent.module').then(
            (m) => m.DetailsAgentModule
          ),
      },
      {
        path: 'insurances',
        component: ListInsuranceComponent,
      },
      {
        path: 'update-insurances',
        component: UpdateInsuranceComponent,
      },
      {
        path: 'learn',
        component: ListArticleComponent,
      },
      {
        path: 'add-learn',
        component: AddArticleComponent,
      },
      {
        path: 'details-learn',
        component: DetailsArticleComponent,
      },
      {
        path: 'chat',
        component: ChatComponent,
      },
      {
        path: 'feedback',
        component: FeedbackComponent,
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
