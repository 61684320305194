import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UserInterface } from '../../../interfaces/user.interface';
import { UserDataService } from '../../../services/user-data.service';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { AppState } from '../../../services/app.state';
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FilterDialogComponent } from '../../shared/filter-dialog/filter-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ProfileService } from '../../../services/profile.service';
import { AgentService } from '../../../services/agent.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-agent',
  templateUrl: './list-agent.component.html',
  styleUrls: ['./list-agent.component.css'],
})
export class ListAgentComponent implements OnInit {
  count_array: any = [];
  count: any = [];
  userData: UserInterface | undefined;
  displayedColumns: string[] = [
    'profilePicture',
    'name',
    'email',
    'personal_phone_number',
    'created_date',
    'action',
  ];

  isLoading: boolean = true;
  dataSource: MatTableDataSource<any> | any = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  page: any = { start: 0, limit: 10 };
  totalRecords = 0;
  isFilter: boolean = false;
  sortD: any = { sortField: 'name', sortOrder: 'desc' };

  customers: any = [];
  errorMessage: string = '';
  AssetsURL: string = environment.assetsURL;
  firebaseUsersCollection = '';
  getAgentListPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'get_agents-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      start: 0,
      limit: 5,
      name: '',
      phoneNumber: '',
      email: '',
      sortField: '', // name, phoneNumber, email
      sortOrder: '', // asc, desc
    },
  };

  // agentStatusChangePayLoad = {
  //   header: {
  //     message_uuid: '',
  //     message_name: 'agents_verification_status-U',
  //     message_type: 'Q',
  //     correlation_uuid: '',
  //     request_id: '',
  //     version: '1.0',
  //     service_completion_status_code: '',
  //     created_by: '',
  //     created_timestamp: new Date().toLocaleString('en-GB'),
  //   },
  //   body: {
  //     cognito_user_id: '',
  //     agent_verification_status: '',
  //   },
  // };

  constructor(
    private afs: AngularFirestore,
    private router: Router,
    private commanService: CommonService,
    private profileService: ProfileService,
    private agentService: AgentService,
    private userDataService: UserDataService,
    public dialog: MatDialog,
    private appState: AppState,
    private datepipe: DatePipe

  ) {}

  ngOnInit() {
    this.isLoading = true;
    this.firebaseUsersCollection = environment.firebaseUsersCollection;
    this.userData = this.userDataService.getUserData();
    this.getAgentListPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    // this.agentStatusChangePayLoad.header.created_by =
    //   this.userData != undefined ? this.userData.cognitoId : '';
    this.getAgentListPayLoad.body.start = this.page.start;
    this.getAgentListPayLoad.body.limit = this.page.limit;
    this.getAllUserList();
  }

  async getAllUserList() {
    this.isLoading = true;
    let parseResponse: any = await this.agentService.getAgentList(
      this.getAgentListPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.customers = parseResponse.agents;
      this.totalRecords = parseResponse.total;
      this.dataSource = new MatTableDataSource(this.customers);
    }
  }

  // async statusChange(event: any, row: any) {
  //   this.agentStatusChangePayLoad.body.cognito_user_id = row.cognitoId;
  //   let newStatus = event.checked === true ? 'approved' : 'pending';
  //   this.agentStatusChangePayLoad.body.agent_verification_status = newStatus;
  //   let parseResponse: any = await this.agentService.updateAgentStatus(
  //     this.agentStatusChangePayLoad
  //   );
  //   if (parseResponse && parseResponse.code === 109) {
  //     row.status = newStatus;
  //   }
  // }

  getCreate(created: string) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'MM-dd-yyyy');
  }

  sortData(sort: Sort) {
    this.sortD.sortField = sort.active;
    this.sortD.sortOrder = sort.direction;
    // this.sort.sortChange.emit(this.sortD);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getAgentListPayLoad.body.start = this.page.start;
    this.getAgentListPayLoad.body.limit = this.page.limit;

    this.getAgentListPayLoad.body.sortField = this.sortD.sortField;
    this.getAgentListPayLoad.body.sortOrder = this.sortD.sortOrder;
    this.getAllUserList();
  }
  pageChanged(event: any) {
    this.page.limit = event.pageSize;
    this.page.start = event.pageIndex * event.pageSize;
    this.getAgentListPayLoad.body.start = this.page.start;
    this.getAgentListPayLoad.body.limit = this.page.limit;
    this.getAllUserList();
  }
  clearFilter = () => {
    this.isFilter = false;
    // this.getAgentListPayLoad.body.start = 0;
    // this.getAgentListPayLoad.body.limit = 10;
    this.getAgentListPayLoad.body.name = '';
    this.getAgentListPayLoad.body.email = '';
    this.getAgentListPayLoad.body.phoneNumber = '';
    this.getAllUserList();
  };
  filterDialog = () => {
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '500px',
      data: {
        header: 'Search Agent',
        name: this.getAgentListPayLoad.body.name,
        email: this.getAgentListPayLoad.body.email,
        phoneNumber: this.getAgentListPayLoad.body.phoneNumber,
        type: 'customer',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.action == 'Search') {
        this.getAgentListPayLoad.body.start = 0;
        this.getAgentListPayLoad.body.limit = 10;
        this.isFilter = true;
        this.getAgentListPayLoad.body.name = '';

        this.getAgentListPayLoad.body.email = '';
        this.getAgentListPayLoad.body.phoneNumber = '';
        let name = result.filterCustomer.name.trim();
        let email = result.filterCustomer.email.trim();
        let phoneNumber = result.filterCustomer.phoneNumber.trim();
        if (name != '') this.getAgentListPayLoad.body.name = name;
        if (email != '') this.getAgentListPayLoad.body.email = email;
        if (phoneNumber != '')
          this.getAgentListPayLoad.body.phoneNumber = phoneNumber;

        this.paginator.firstPage();
        this.getAllUserList();
      }
    });
  };
  showCustomerDetails(row: any) {
    this.appState.set('AGENT', row);
    this.router.navigate(['/details-agent']);
  }
}
