import {Injectable} from '@angular/core';
import AWSAppSyncClient from 'aws-appsync';
// aws-appsync/lib/link/auth-link
import {AUTH_TYPE} from 'aws-appsync-auth-link/lib/auth-link';
import {UserInterface} from '../interfaces/user.interface';
import {UserDataService} from './user-data.service';
import {environment} from "../environments/environment";

@Injectable({ providedIn: 'root' })
export class AppsyncService {
   // hc;
   _hc;
    userData:UserInterface | undefined;
    // aws_exports:{[key:string]: string} = {}

   constructor(private userDataService:UserDataService) {
    this.userData=this.userData?this.userData:this.userDataService.getUserData();
     // this.hc = client.hydrated;
     this._hc = new AWSAppSyncClient({
       url: environment.aws.appSyncGraphQLEndpoint,
       region: environment.aws.projectRegion,
       auth: {
         type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
         jwtToken: async () => (this.userData != undefined ? await this.userData._idToken : null)
         /* async () => (await Auth.currentSession()).getIdToken().getJwtToken() */
       }
     });
   }
   hc() {
     return this._hc.hydrated();
   }
}
