const STAGENAME = "/dev"; // production for use test instead of local

export var allURLS: { [key: string]: string } = {
    //encrypted key
    ENCRYPTSECRETKEY: "@SiMBA.InSuRAnCE",  //"@ShYlD.InTeLLiFY",
    // start endpoint
    WEB: STAGENAME + "/web",

    CHATENCRYPTIONKEY: "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE"

    // end
}  