import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import App from "./App";
import React from "react";

// import ReactDOM from "react-dom";
import ReactDOM from "react-dom/client";

@Component({
  selector: "app-my-component",
  templateUrl: "./MyReactComponentWrapper.html",
  encapsulation: ViewEncapsulation.None,
})
export class MyComponentWrapperComponent implements AfterViewInit, OnDestroy {
  @ViewChild("myReactComponentContainer") containerRef: ElementRef;
  root
  constructor() {}

  // ngOnInit(): void {
  //   throw new Error("Method not implemented.");
  // }

  // ngOnInit(): void {
  //   this.render();
  // }
  // ngOnChanges(changes: SimpleChanges): void {
  //   this.render();
  // }

  ngAfterViewInit() {
    this.root = ReactDOM.createRoot(this.containerRef?.nativeElement);
    this.render();
  }

  ngOnDestroy() {
    this.root.unmount();
  }

  private render() {
    React.version;
    this.root.render(<App />);
  }
}
