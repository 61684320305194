import {Injectable} from '@angular/core';
import {APIWrapperService} from './api-wrapper.service';
import {AWSHelperService} from './aws4-helper.service';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  host: string = "https://fcm.googleapis.com/fcm/send";
  firebaseKeyHeader: string = "key=AAAAARmRs_0:APA91bFAt2gJkh_enJUUE-zMSLWsbzD-_24daHi7gHpTJmU5DSi9GtXFVHFnCURl5cFssYhUM628qN8S4NDs49OhFHuE4Y-8X5ek9P2b6fVbK3e3XpR3KDTJUeAFTc9ssMMtJCnBUXua";

  constructor(private APIWrapperService: APIWrapperService, private awsHelper: AWSHelperService, private http: HttpClient) {

  }

  sendNotification(payLoad: any) {
    const headers = new HttpHeaders({Authorization: this.firebaseKeyHeader});
    try {
      return this.http.post(this.host, payLoad, {'headers': headers})
        .pipe(
          map((response: any) => response)
        )
    } catch (err) {
      return null;
    }
  }
}
