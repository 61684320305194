<div class="ml-5 mr-5" fxLayout="column">
    <div fxLayout style="align-items: center !important;">
        <h5 class="card-title ml-1 font-family-NB mt-4" fxLayout fxFlex="97">Feedback</h5>
    </div>

    <div fxLayout fxLayoutAlign="center" fxLayoutGap="30px" *ngIf="isLoading">
        <mat-spinner style="margin:0 auto;" *ngIf="isLoading" [diameter]="30"></mat-spinner>
    </div>
    <div class="article-container font-family-NSB mb-3" fxLayout fxLayout="column" fxLayoutAlign="start"
        fxLayoutGap="5px" fxLayoutGap.xs="0" [ngClass]="!isLoading ? 'd-block':'d-none'">
        <mat-card class="example-container mb-3" fxLayout fxLayout="column">
            <mat-card-content class="font-family-NSB" fxLayout fxLayout="column">
                <div fxLayout fxLayout="column">
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                        [matSortActive]="sortD.sortField" [matSortDirection]="sortD.sortOrder">

                        <!-- Name Column -->
                        <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
                            <td mat-cell *matCellDef="let row">{{ row.name }}</td>
                        </ng-container>

                        <!-- email Column -->
                        <ng-container matColumnDef="email">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
                            <td mat-cell *matCellDef="let row">{{ row.email }}</td>
                        </ng-container>

                        <!-- phone number Column -->
                        <ng-container matColumnDef="phone_number">
                            <th mat-header-cell *matHeaderCellDef>Phone Number</th>
                            <td mat-cell *matCellDef="let row">{{ row.phone_number }}</td>
                        </ng-container>

                        <!-- rating Column -->
                        <ng-container matColumnDef="rating">
                            <th mat-header-cell *matHeaderCellDef>Rating</th>
                            <td mat-cell *matCellDef="let row">{{ row.rating }}</td>
                        </ng-container>

                        <!-- Description Column -->
                        <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef>Description</th>
                            <td mat-cell *matCellDef="let row">{{ row.description }}</td>
                        </ng-container>

                        <!-- Created Column -->
                        <ng-container matColumnDef="created_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="created_date">Created</th>
                            <td mat-cell *matCellDef="let row">{{ getCreate(row.created_date)}}</td>
                        </ng-container>

                        <tr class="mat-row" *matNoDataRow>
                            <td [colSpan]="displayedColumns.length" class="mat-cell text-center">No data found</td>
                        </tr>

                        <tr class="font-family-NB" mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                        <tr class="font-family-NSB" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
                <mat-paginator [ngClass]="!isLoading && feedbacks.length > 0 ? 'd-block':'d-none'"
                    class="ml-max font-family-NB " fxLayout fxLayoutAlign="end" fxFlex="100" fxFlex.xs="100"
                    fxLayoutGap="10px" (page)="pageChanged($event)"  [pageSize]="page.limit"
                    [pageSizeOptions]="[ 10, 25, 50, 100]">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</div>
