import { Injectable } from '@angular/core';
import { APIWrapperService } from './api-wrapper.service';
import { AWSHelperService } from './aws4-helper.service';
import { allURLS } from './allURL';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VoiService {
  apiUrl = environment.api_endpoint;
  host: string = this.awsHelper.getHost('/profile-info');

  constructor(
    private APIWrapperService: APIWrapperService,
    private awsHelper: AWSHelperService,
    private http: HttpClient
  ) {}

  // get customer list detail.
  getVoiListData(payload: any) {
    return this.http.post(this.apiUrl, payload).toPromise();
  }
  getVoiDetailsData(payload: any) {
    return this.http.post(this.apiUrl, payload).toPromise();
  }
  sendEmailVoi(payload: any) {
    return this.http.post(this.apiUrl, payload).toPromise();
  }
  saveGeneratePDF(payload: any) {
    return this.http.post(this.apiUrl, payload).toPromise();
  }

  // get notifications
  getUserNotifications(payload: any) {
    return this.http.post(this.apiUrl, payload).toPromise();
  }
}
