import {
  doc,
  updateDoc
} from "firebase/firestore";
import React, { useContext, useState } from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { ChatContext } from "../Context/ChatContext";
import { db } from "../firebase";

const ChatHeader = (props: any) => {
  let { data } = useContext(ChatContext);
  const isEmpty = Object.keys(data.user).length === 0;

  if (isEmpty == true) {
    data = JSON.parse(localStorage.getItem("currentUser") || "");
  }
  const [showResolved, setShowResolved] = useState(false);
  const handleCloseResolved = () => setShowResolved(false);
  //Resolved Button Click
  const [isAcceptId, setIsAcceptedId] = useState(0);

  const [resSet, SetRes] = useState(0);
  const resolved = async (id: any) => {
    localStorage.removeItem("selectedUser");
    setShowResolved(true);
    setIsAcceptedId(0);
    SetRes(id);

    let supportInfo = {
      current_support_id: null,
      support_name: null,
      status: "idle",
    };
    const docRef = doc(db, "dev_users", `${id}`);
    const updateStatus = await updateDoc(docRef, supportInfo);

    var elements = document.querySelector('#myuser');
    var newelements = document.querySelector('#idleuser');
    var idleGrp = document.querySelector('#idle-group');
    var txt = document.getElementById('send-msg-txt');

    if (txt) {
      txt.style.display = 'none';
    }

    if (elements) {
      elements.classList.remove('active');
    }

    if (newelements) {
      newelements.classList.add('active');
    }

    if (idleGrp) {
      idleGrp.classList.add('active');
    }
};

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "");
  const supportID = currentUser[0].current_support_id;
  const supportName = currentUser[0].user_name;

  const handleClick = async (id: any) => {
    setIsAcceptedId(id);
    setShow(true);
    SetRes(0);

    let supportInfo = {
      id: id,
      current_support_id: supportID, //Current user Cognitoid
      support_name: supportName, //current User Email Address
      status: "assigned",
    };

    const docRef = doc(db, "dev_users", `${id}`);
    await updateDoc(docRef, supportInfo).then((res)=>{
    }).catch((err)=>{
    })

  };

  let localUser = "";
  if (localStorage.getItem("currentUser") !== null) {
    localUser = JSON.parse(localStorage.getItem("currentUser") || "").id;
  }

  return (
    <>
      {isEmpty && localUser == "0" ? (
        <Col xs={6} md={6} className="chat-title-name">
          <p className='user-header-title'> </p>
        </Col>
      ) :
        data?.user && data.user.status != "idle" ?
          (
            <Col xs={6} md={6} className="chat-action-button">
              {/* {data.user.status}{data.id}{resSet} */}
              {data.user.status == "waiting" && isAcceptId != data.id ? (
                <>
                  <p id={'user-accept-button-' + data.id} className={'user-accept-button-' + data.id}>
                    <Button className="accepted-button mt-3 ml-2 float-right" onClick={() => handleClick(data?.user?.id)}>Accept </Button>
                  </p>

                </>
              )
                : data.user.status == "assigned" && showResolved != true ? (
                  <p style={{ display: resSet != data.id ? 'block' : 'none' }} className='user-resolved-button mt-3 ml-2'><Button className='resolved-button float-right' onClick={() => resolved(data.user.id)}>Resolved</Button></p>
                ) : ''
              }
            </Col>
          ) : ''

      }

      <Modal
        show={show}
        size="sm"
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Successfully Accepted
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>

          Please check the My Users Tab
          <i className='fa fa-check success-check' aria-hidden="true" ></i>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} variant="primary" style={{ textAlign: "center" }}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showResolved}
        size="sm"
        onHide={() => setShowResolved(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            Successfully Resolved
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: "center" }}>

          Successfully resolved , Please check Idle Tab
          <i className='fa fa-check success-check' aria-hidden="true" ></i>

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCloseResolved} variant="primary" style={{ textAlign: "center" }}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
};

export default ChatHeader;
