import { User } from '../models/user.model';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class InsuranceDataService {
    user: any;
    insurancetypSource  = new BehaviorSubject<any>(null);
    getInsuranceType = this.insurancetypSource.asObservable();

    setInsurancetype(insurancetype: any) {
        this.insurancetypSource.next(insurancetype);
        // this.getInsuranceType = this.insurancetype.asObservable();
    }

    ngOnDestroy() {
        this.insurancetypSource.unsubscribe();
    }
}