import React, { useState } from "react";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  getMetadata,
} from "firebase/storage";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  limit,
  updateDoc,
  serverTimestamp,
  getDoc,
  addDoc,
  onSnapshot,
  orderBy,
  limitToLast,
  startAfter,
  endBefore,
} from "firebase/firestore";
import { db, storage } from "./firebase";
import { async } from "@firebase/util";
import * as CryptoJS from "crypto-js";
import { Buffer } from "buffer";
//import { ref, getDownloadURL, uploadBytes,getMetadata } from "./firebase/storage";

const crypto = require("crypto-browserify");
/* Encrypt the Message Content */
const encryptChat = (text: any) => {
  const encryptSecretKey = "@SiMBA.InSuRAnCE";
  const chatEncryptionSecretKey =
    "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
  const mdString = crypto
    .createHash("md5")
    .update(
      Buffer.from(chatEncryptionSecretKey).toString("ASCII"),
      0,
      chatEncryptionSecretKey.length
    )
    .digest("hex");
  const substr = mdString.substring(0, 16);
  const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
  const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
  var encrypted = CryptoJS.AES.encrypt(text, CHATKEY, {
    keySize: 128 / 8,
    iv: CHATIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return encrypted.toString();
};

/* Decrypt the Message content */
const decryptMessage = (stringds: any) => {
  const encryptSecretKey = "@SiMBA.InSuRAnCE";
  const chatEncryptionSecretKey =
    "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
  const mdString = crypto
    .createHash("md5")
    .update(
      Buffer.from(chatEncryptionSecretKey).toString("ASCII"),
      0,
      chatEncryptionSecretKey.length
    )
    .digest("hex");
  const substr = mdString.substring(0, 16);

  const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
  const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
  const decrypted = CryptoJS.AES.decrypt(stringds, CHATKEY, {
    keySize: 128 / 8,
    iv: CHATIV,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
};

const Agent = () => {
  //State to store the message list to view in chat

  const [msgs, setMsgs] = useState([]);
  //Function to send last message and New  message
  const sendAgentMessage = async () => {
    const leadId = "88";
    const agentid = "62";
    const customerId = "71";
    const collectionName = customerId + "_" + agentid; //62_71 first one customer and second Agent Id

    //to send and attachment we will use the Storage of firebase
    /*-------------- Send Attachment Code Starts---------------*/
    //   const imgRef = ref(
    //     storage,
    //     `${user2}/image_`+Math.floor(new Date().getTime())/1000
    //   );

    //   const snap  = await uploadBytes(imgRef, img);
    //   const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
    //   url = dlUrl;//use this url object then encryptChat(url) to send as attachment_url parameter
    //   let mimeType = img.type;
    /*-------------- Send Attachment Code Ends---------------*/

    //Code for the Last Message
    await setDoc(doc(db, "dev_users_agent", `${leadId}`), {
      device_type: "agent",
      id: 71, //Pass your customer id integer only
      last_message: encryptChat("new  message"),
      last_message_timestamp: Math.floor(new Date().getTime()),
      timestamp: Math.floor(new Date().getTime()),
    });
    //Code for the NEW MESSAGE
    await addDoc(
      collection(db, "dev_users_agent", leadId, `${collectionName}`),
      {
        attachment_url: "",
        agent_id: agentid,
        agent_name: "test_agent",
        agent_read: true,
        from: "agent",
        message: encryptChat("new  message"),
        mime_type: "",
        to: "customer",
        timestamp: Math.floor(new Date().getTime()),
        user_name: "",
        user_read: false,
      }
    );
  };
  const readAgentMessage = async () => {
    const leadId = "88";
    const agentid = "62";
    const customerId = "71";
    const collectionName = customerId + "_" + agentid; //62_71 first one customer and second Agent Id
    const msgRef = collection(
      db,
      "dev_users_agent",
      `${leadId}`,
      `${collectionName}`
    );
    //Query for first 10 records

    const next = query(msgRef, orderBy("timestamp", "asc"));
    // Get the last visible document
    const documentSnapshots = await getDocs(next);
    const lastRecord =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    const unsubscribe = onSnapshot(next, (querySnapshot) => {
      let msgData: any = [];
      querySnapshot.forEach((doc) => {
        msgData.push(doc.data());
      });
      setMsgs(msgData); //State to store the Message
    });
  };

  return (
    <>
      {/* <button onClick={sendAgentMessage}>Send A Message</button>
    <div><button onClick={readAgentMessage}>Read Message</button>
        {  msgs.map((m)  => (
            <label  k={m.timestamp}>{decryptMessage(m.message)}</label> 

        )) }

    
    </div> */}
    </>
  );
};

export default Agent;
