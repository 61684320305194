import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../environments/environment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { UserDataService } from '../services/user-data.service';

@Component({
  selector: 'app-enrollment',
  templateUrl: './enrollment.component.html',
  styleUrls: ['./enrollment.component.scss']
})
export class EnrollmentComponent implements OnInit {

  typeOfEnrollment: any='';
  isLoading: boolean = false;

  // insuranceType: string[] = ['Auto','Home','Renters','Umbrella', 'Condo', 'Home Warranty', 'Health', 'Dental','Eye','Flood', 'Pet','Boat', 'Identity Protection','Event', 'Landlord Property Protection'];
  insuranceType:any=[
    {
        "id": "autoinsurance",
        "title": "Auto",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/auto.png"
    },
    {
        "id": "homeinsurance",
        "title": "Home",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/home.png"
    },
    {
        "id": "rentersinsurance",
        "title": "Renters",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/renters.png"
    },
    {
        "id": "umbrellainsurance",
        "title": "Umbrella",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/umbrella.png"
    },
    {
        "id": "condoinsurance",
        "title": "Condo",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/condo.png"
    },
    {
        "id": "homewarrantyinsurance",
        "title": "Home Warranty",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/home-warranty.png"
    },
    {
        "id": "healthinsurance",
        "title": "Health",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/health.png"
    },
    {
        "id": "dentalinsurance",
        "title": "Dental",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/dental.png"
    },
    {
        "id": "eyeinsurance",
        "title": "Eye",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/eye.png"
    },
    {
        "id": "floodinsurance",
        "title": "Flood",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/flood.png"
    },
    {
        "id": "petinsurance",
        "title": "Pet",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/pet.png"
    },
    {
        "id": "boatinsurance",
        "title": "Boat",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/boat.png"
    },
    {
        "id": "identityprotectioninsurance",
        "title": "Identity Protection",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/identity-protection.png"
    },
    {
        "id": "eventinsurance",
        "title": "Event",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/event.png"
    },
    {
        "id": "landlordpropertyprotectioninsurance",
        "title": "Landlord Property Protection",
        "icon": "https://simba-insurance-images.s3.us-west-2.amazonaws.com/insurance-type-icons/landlord-property.png"
    }
]
  /* Agent Enrollment variables Start */
  personalDetailForm: FormGroup;
  isPersonalDetail: boolean = false;

  /* businessDetails using in both agent and vender */
  businessDetailForm: FormGroup;
  isbusinessDetail: boolean = false;

  affiliationDetailForm: any = [];
  isAddDetails: boolean = false;
  isAddCompanyDetails: boolean = false;
  isaffilitionDetail: boolean = false;
  /* / Agent Enrollment variables End */

  /* Vender Enrollment variables Start */
  ownerOrCorrespondentForm: FormGroup;
  billingInfoForm: FormGroup;
  /* Vender Enrollment variables End */

  documents: any = [];

  /* using agent in as peronal doc and vender in as business doc */
  @ViewChild('uploadFile') uploadFile: ElementRef;
  fileDocuments = 'Select File';

  @ViewChildren('uploadCompanyDocs') uploadCompanyDocs: QueryList<ElementRef>;
  isEditable = false;

  AssetsURL: string = environment.assetsURL;
  mopattern = /^[0-9]*$/;
  statepattern = /^[a-zA-z ]*$/;

  constructor(private fb: FormBuilder, private snackBar: MatSnackBar, private router: Router,  private userDataService: UserDataService,
     private activatedRoute: ActivatedRoute) { }



  ngOnInit() {
    this.isLoading = false;


    this.typeOfEnrollment=this.userDataService.getType();
    // this.typeOfEnrollment = window.history.state.type;

    if (this.typeOfEnrollment == null) {
      this.userDataService.removeType();
      this.router.navigate(['/login']);
    }

    if (this.typeOfEnrollment == "Agent") {
    this.personalDetailForm = this.fb.group({
      name: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
      residentialAddress: ['']
    });

    this.businessDetailForm = this.fb.group({
      name: ['', [Validators.required]],
      registerNumber: ['', [Validators.maxLength(14), Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
      email: ['', [Validators.required, Validators.email]],
      telephoneNumber: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
      address: ['', [Validators.required]],
      websiteLink: [''],
      insuranceType: [''],
      licensedState: ['', Validators.pattern(this.statepattern)],
      licenseNumber: [''],
    });

    }
    else if (this.typeOfEnrollment == "Vender") {
    this.ownerOrCorrespondentForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      mobileNumber: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
    });
    this.businessDetailForm = this.fb.group({
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
      telephoneNumber: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
      email: ['', [Validators.required, Validators.email]],
      websiteLink: [''],
      insuranceType: [''],
      registrationNumber: [''],
    });
    this.billingInfoForm = this.fb.group({
      paymentType: ['', [Validators.required]],
      address: ['', [Validators.required]]
    });
    }
  }

  public errorHandlingPersonal = (control: string, error: string) => {
    return this.personalDetailForm.controls[control].hasError(error);
  }

  public errorHandlingBusiness = (control: string, error: string) => {
    return this.businessDetailForm.controls[control].hasError(error);
  }

  public errorHandlingOwner = (control: string, error: string) => {
    return this.ownerOrCorrespondentForm.controls[control].hasError(error);
  }

  public errorHandlingBilling = (control: string, error: string) => {
    return this.billingInfoForm.controls[control].hasError(error);
  }

  uploadCompanyDocuments(fileInput: any, index: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.affiliationDetailForm[index].companyfileDocuments = '';
      this.affiliationDetailForm[index].uploadDocument = fileInput.target.files
      Array.from(fileInput.target.files).forEach((file: any) => {
        if (fileInput.target.files.length > 1)
          this.affiliationDetailForm[index].companyfileDocuments += file.name + ',';
        else
          this.affiliationDetailForm[index].companyfileDocuments = file.name;
      });
    } else {
      this.affiliationDetailForm[index].companyfileDocuments = 'Select File';
    }
  }


  uploadFileChange(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      this.fileDocuments = '';
      this.documents = fileInput.target.files
      Array.from(fileInput.target.files).forEach((file: any) => {
        if (fileInput.target.files.length > 1)
          this.fileDocuments += file.name + ',';
        else
          this.fileDocuments = file.name;
      });
    } else {
      this.fileDocuments = 'Select File';
    }
  }

  handleClick() {
    this.uploadFile.nativeElement.value = '';
    this.uploadFile.nativeElement.click();
  }
  onDocumentsSelect(i: any) {
    this.uploadCompanyDocs['_results'][i].nativeElement.value = '';
    this.uploadCompanyDocs['_results'][i].nativeElement.click();
  }


  submitPersonal(stepper:MatStepper) {
    this.isPersonalDetail = true;
    if (this.personalDetailForm.invalid || this.documents.length <= 0) {
      return;
    }
    if (this.personalDetailForm.valid) {
      stepper.next();
      // this.snackBar.open("Personal Detail added Successfully.", 'close', {
      //   duration: 3000,
      //   verticalPosition: "top",
      //   horizontalPosition: "right",
      //   panelClass: ['success-snackbar']
      // });
    }
  }
  submitBusiness(stepper:MatStepper) {
    this.isbusinessDetail = true;
    if (this.typeOfEnrollment == 'Agent') {

      if (this.businessDetailForm.invalid) {
        return;
      }
      if (this.businessDetailForm.valid) {
        stepper.next();
        // this.snackBar.open("Business Detail added Successfully.", 'close', {
        //   duration: 3000,
        //   verticalPosition: "top",
        //   horizontalPosition: "right",
        //   panelClass: ['success-snackbar']
        // });
      }
    }
    else {
      if (this.businessDetailForm.invalid || this.documents.length <= 0) {
        return;
      }
      if (this.businessDetailForm.valid) {
        stepper.next();
        // this.snackBar.open("Business Detail added Successfully.", 'close', {
        //   duration: 3000,
        //   verticalPosition: "top",
        //   horizontalPosition: "right",
        //   panelClass: ['success-snackbar']
        // });
      }
    }
  }
  submitAgentData() {
    this.isAddCompanyDetails = true;
    if (this.affiliationDetailForm.length <= 0) {
      return;
    }
    else {
      if (this.affiliationDetailForm.length > 0) {
        let flag = 0;
        for (let i = 0; i < this.affiliationDetailForm.length; i++) {
          if (this.affiliationDetailForm[i].companyName == '' || this.affiliationDetailForm[i].uploadDocument) {
            flag++;
          }
        }
        if (!flag) {
          this.snackBar.open("Agent added Successfully.", 'close', {
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ['success-snackbar']
          });
          this.router.navigate(['/confirmation']);
        }
      }
    }
  }


  submitOwnerOrCorrespondent(stepper:MatStepper) {
    if (this.ownerOrCorrespondentForm.invalid) {
      return;
    }
    if (this.ownerOrCorrespondentForm.valid) {
      stepper.next();
      // this.snackBar.open("OwnerOrCorrespondent Details added Successfully.", 'close', {
      //   duration: 3000,
      //   verticalPosition: "top",
      //   horizontalPosition: "right",
      //   panelClass: ['success-snackbar']
      // });
    }
  }
  submitBillingInfo(stepper:MatStepper) {
    if (this.billingInfoForm.invalid) {
      return;
    }
    if (this.billingInfoForm.valid) {
      stepper.next();
      this.snackBar.open("Vender added Successfully.", 'close', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ['success-snackbar']
      });
      this.router.navigate(['/confirmation']);
    }
  }
  addCompanyDetails() {
    if (this.affiliationDetailForm.length >= 3) {
      return;
    }
    this.isAddCompanyDetails = false;
    this.isAddDetails = true;
    this.affiliationDetailForm.push({ companyName: '', noOfYear: '', uploadDocument: '', companyfileDocuments: 'Select File' });
  }

  deleteCompanyDetailsRow(index: any) {
    this.affiliationDetailForm.splice(index, 1);
  }
}
