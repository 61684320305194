<div class="example-container" fxLayout="column" fxFlex="100%">
    <mat-dialog-title fxLayout fxLayout="row" fxLayoutAlign="space-between">
        <div class="header-text font-family-NB v-m" fxLayout fxLayoutAlign="end">{{dialogData.header}}</div>
        <button mat-icon-button type="button" class="btn btn-sm" [disableRipple]="true"
        (click)="!isLoading && close()">
        <mat-icon class="delete_icon">close</mat-icon>
      </button>
    </mat-dialog-title>
    <form [formGroup]="addInsurance" fxLayout fxLayout="column" >
        <mat-dialog-content fxLayout fxLayout="column" fxFlex="100%" class="font-family-NSB p-4">

            <!-- <mat-label  class="font-family-NB">Insurance Type</mat-label> -->
            <mat-form-field appearance="outline" *ngIf="this.dialogData.type == 'add'">
                <mat-label class="font-family-NB">Insurance Type</mat-label>
                <mat-select class="mb-2" matInput formControlName="insuranceType" required>
                    <mat-option *ngFor="let insurance of insuranceTypes" [value]="insurance.id">{{insurance.title}}</mat-option>
                </mat-select>
                <mat-error class="font-family-NSB" *ngIf="isAddInsurance && addInsurance.controls['insuranceType'].hasError('required')">
                    Please select Insurance Type.
                </mat-error>
            </mat-form-field>
            <div class="myfilebrowser" fxLayout="column" fxFlex="100%">
                <mat-label class="font-family-NB mt-1 mb-3" >Upload Document</mat-label>
                <input type="file" #UploadFile id="fileUpload" (change)="fileChangeEvent($event)" name="fileUpload"
                    multiple="multiple" accept="image/*, .pdf" />

                <span fxLayout="row" fxFlex="100%" fxLayoutAlign="space-between">

                    <img [src]="AssetsURL+'/img/upload_file.png'" fxLayout="column" fxFlex="20" fxLayoutGap="20px"
                        width="100px" height="100px" />

                    <span fxLayout="column" fxFlex="70" fxLayoutGap="20px">
                        <div class="filename font-family-NSB word-wrap" fxLayout="row" fxLayoutGap="10px">
                            {{myfilename}}</div>
                        <button type="button" (click)="handleClick()" mat-button
                            class="btn btn-sm btn-yolow text-center">Upload
                            Document(s)</button>
                    </span>
                </span>

                <mat-error class="font-family-NSB" *ngIf="isAddInsurance && insuranceDocuments.length <= 0">
                    Please upload atleast one Document.
                </mat-error>
                <mat-error class="font-family-NSB" *ngIf="isFile">
                    Select only one document of 3 MB max.
                </mat-error>
                <mat-error class="font-family-NSB" *ngIf="isImage">
                    Select add upto 4 images.
                </mat-error>
            </div>
        </mat-dialog-content>
        <mat-dialog-actions fxLayout fxLayout="row" fxLayoutAlign="end">
            <button mat-button type="submit" class="btn btn-yolow btn-md font-family-NSB" [disableRipple]="true" (click)="!isLoading && addNewInsurance()">

                <span [ngStyle]="{'display':!isLoading ? 'block': 'none'}">SUBMIT</span>
                        <span [ngStyle]="{'display': isLoading ? 'block': 'none'}">
                            <mat-spinner style="margin:5px auto;" [diameter]="30">
                            </mat-spinner>
                        </span>
            </button>
            <!-- <button mat-button type="button" class="btn btn-danger btn-md font-family-NSB radius-50" [disableRipple]="true" (click)="close()"
            >CANCEL</button> -->
        </mat-dialog-actions>
    </form>

</div>
