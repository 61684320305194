import { Injectable } from '@angular/core';
import { APIWrapperService } from './api-wrapper.service';
import { AWSHelperService } from './aws4-helper.service';
import { allURLS } from './allURL';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class InsuranceService {

    apiUrl = environment.api_endpoint;
    host: string = this.awsHelper.getHost('/insurance');
    allURLS:{[key:string]: string} = {};

    constructor(private APIWrapperService: APIWrapperService, private awsHelper: AWSHelperService, private http: HttpClient) {
    }

    // get insurances
    async getInsurances(payload: any) {
      try {
        const response = await this.http.post(this.apiUrl, payload).toPromise();
        console.log("response", response,payload );
        
        return response;
      } catch (error:any) {
        throw new Error('Failed to get insurances: ' + error?.message);
      }
    }
    
}
