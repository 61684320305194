<div class="ml-5 mr-5" fxLayout="column">
  <div fxLayout style="align-items: center !important;">
    <h5 class="card-title ml-1 font-family-NB mt-4" fxLayout fxFlex="97">Verification of Insurance</h5>
    <!-- <button mat-icon-button (click)="clearFilter()" *ngIf="!isLoading && isFilter"
      class="btn btn-yolow btn-clear-filter text-center mr-2" #tooltip="matTooltip" matTooltip="Clear Filter"
      matTooltipPosition="below" fxLayout fxFlex="8" fxLayoutGap="20">
      <mat-icon class="ml-2">clear</mat-icon>Clear Filter
    </button>
    <button mat-icon-button (click)="filterDialog()" *ngIf="!isLoading" class="btn btn-yolow btn-filter text-center"
      #tooltip="matTooltip" matTooltip="Filter list" matTooltipPosition="below" fxLayout fxLayoutGap="20">
      <mat-icon class="ml-2">filter_list</mat-icon>
    </button> -->
  </div>

  <div fxLayout fxLayoutAlign="center" fxLayoutGap="30px" *ngIf="isLoading">
    <mat-spinner style="margin:0 auto;" *ngIf="isLoading" [diameter]="30"></mat-spinner>
  </div>
  <div class="customer-container font-family-NSB mb-3" fxLayout fxLayout="column" fxLayoutAlign="start"
    fxLayoutGap="5px" fxLayoutGap.xs="0">
    <mat-card class="example-container mb-3" [ngClass]="!isLoading ? 'd-block':'d-none'" fxLayout fxLayout="column">
      <!-- mat-elevation-z8 -->
      <mat-card-content class="font-family-NSB" fxLayout fxLayout="column" *ngIf="dataSource">
        <div fxLayout fxLayout="column" *ngIf="!isLoading">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
            [matSortActive]="sortD.sortField" [matSortDirection]="sortD.sortOrder">

            <!-- profilePicture Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef="let row" mat-sort-header="user_name">Name</th>
              <td mat-cell *matCellDef="let row">{{row.user_name}}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="voiid">
              <th mat-header-cell *matHeaderCellDef="let row">Verification ID</th>
              <td mat-cell *matCellDef="let row"> {{row.user_insurance_sequence_id}}</td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="leadid">
              <th mat-header-cell *matHeaderCellDef="let row">Lead ID</th>
              <td mat-cell *matCellDef="let row">{{row.lead_sequence_id}}</td>
            </ng-container>

            <!-- Phone Number Column -->
            <ng-container matColumnDef="insurancetype">
              <th mat-header-cell *matHeaderCellDef="let row">Insurance Type</th>
              <td mat-cell *matCellDef="let row">{{capitalizeFirstLetter(row.insurance_type) }}</td>
            </ng-container>
            <ng-container matColumnDef="createddate">
              <th mat-header-cell *matHeaderCellDef="let row" mat-sort-header="created_date">Create Date</th>
              <td mat-cell *matCellDef="let row">{{getCreate(row.created_date)}}</td>
            </ng-container>

            <!-- Active Policies Column -->
            <ng-container matColumnDef="voistartus">
              <th mat-header-cell *matHeaderCellDef>IVS Status</th>
              <td mat-cell *matCellDef="let row">{{capitalizeFirstLetter(row.verification_status)}}</td>
            </ng-container>

            <!-- Upcoming Renewals Column -->
            <ng-container matColumnDef="emailstatus">
              <th mat-header-cell *matHeaderCellDef>Email Status</th>
              <td mat-cell *matCellDef="let row"  [ngClass]="row.email_status == 'SENT' ? 'text-warning' :
              row.email_status == 'PENDING' ? 'text-danger' : 'text-success'">{{row.email_status}}</td>
            </ng-container>
            <!-- 0000cd -->


            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="text-center">Action</th>
              <td mat-cell *matCellDef="let row">
                <span class="d-flex" style="justify-content: center;">
             
                  <button (click)="showCustomerDetails(row)" title="Details" class="btn btn-outline-dark btn-sm">
                    <mat-icon class="btn-edit material-icons-outlined">edit</mat-icon>
                  </button>
                </span>
              </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td [colSpan]="displayedColumns.length" class="mat-cell text-center">No data found</td>
            </tr>

            <tr class="font-family-NB" mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr class="font-family-NSB" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <!-- <mat-paginator [ngClass]="!isLoading && customers.length > 0 ? 'd-block':'d-none'"
          class=" font-family-NB ml-max" fxLayout fxLayoutAlign="end" fxFlex="100%" fxLayoutGap.xs="0px"
          fxLayoutGap="10px" (page)="pageChanged($event)" [length]="totalRecords" [pageSize]="page.limit"
          [pageSizeOptions]="[ 10, 25, 50, 100]">
        </mat-paginator> -->

        <mat-paginator [ngClass]="!isLoading && customers.length > 0 ? 'd-block':'d-none'" class=" font-family-NB ml-max"
          fxLayout fxLayoutAlign="end" fxFlex="100%" fxLayoutGap.xs="0px" fxLayoutGap="10px" (page)="pageChanged($event)"
          [length]="totalRecords" [pageSize]="page.limit" [pageSizeOptions]="[ 10, 25, 50, 100]">
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>