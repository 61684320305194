<div class="font-family-NSB" fxLayout fxLayout="column">
    <div fxLayout fxLayout="row" fxLayoutAlign="start" fxFlexOffset="20px" fxFlexOffset.xs="0px" fxLayoutGap="30px"
        fxLayoutGap.xs="10px">
        <div class="header-text font-family-NB v-m ml-5 mb-3" fxLayout fxLayoutAlign="start">
            View Article
        </div>
    </div>
    <mat-card class="example-container  ml-5 mr-5 p-5 mb-5" fxLayout fxLayout="column" fxLayoutAlign="center">
        <div fxLayout fxLayout="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
            fxFlex="100%" fxLayoutGap="30px" fxLayoutGap.xs="0px">
            <mat-card-content fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start" fxLayout.sm="row"
                fxFlex="60%" fxFlex.xs="100%" class="font-family-NSB" fxLayoutGap="30px" fxLayoutGap.xs="0px">
                <div class="myfilebrowser" fxFlex.xs="100%" fxLayout="column">

                    <span fxLayout="column" *ngIf="article" fxLayoutGap="10px" fxLayoutAlign="start" >
                        <img [src]="article.learn_image == '' ? AssetsURL+'/img/upload_file.png' :
                        article.learn_image" fxLayout="row"
                            fxLayoutGap="20px" class="rounded-15px" width="180px" height="180px" />
                    </span>
                </div>
                <!-- fxFlexOffset.xs="5px" fxFlexOffset.sm="5px" fxFlexOffset="40px" -->
                <div fxLayout fxLayout="column" fxFlex="70%" *ngIf="article" fxFlex.xs="100%" fxLayoutAlign.xs="start"
                    fxFlexOffset.xs="10px" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">
                    <table fxLayout fxLayout="column" fxFlex="50%" >
                        <tbody fxLayout fxLayout="column" fxFlex="50%" fxLayoutGap="20px">
                            <div fxLayout="row" fxLayoutAlign="space-around">
                                <tr fxLayout="column" fxFlex="50%">
                                    <th class="font-family-NB fw-9">Article Source</th>
                                    <td class="text-gray">{{article.learn_source}}</td>
                                </tr>

                                <tr fxLayout="column" fxFlex="50%">
                                    <th class="font-family-NB fw-9">Title of Article</th>
                                    <td class="text-gray">{{article.learn_title}}</td>
                                </tr>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around">
                                <tr fxLayout="column" fxFlex="50%">
                                    <th class="font-family-NB fw-9">Article Link</th>
                                    <td class="text-gray">{{article.learn_link}}</td>
                                </tr>

                                <tr fxLayout="column" fxFlex="50%">
                                    <th class="font-family-NB fw-9">Date Of Article</th>
                                    <td class="text-gray">{{article.learn_date | date:'MM-dd-YYYY'}}</td>
                                </tr>
                            </div>
                            <div fxLayout="row" fxLayoutAlign="space-around">
                                <tr fxLayout="column" fxFlex="100%">
                                    <th class="font-family-NB fw-9">Article Description</th>
                                    <td class="text-gray">{{article.learn_description}}</td>
                                </tr>
                                <tr fxLayout="column" fxFlex="100%">
                                    <th class="font-family-NB fw-9">Article Status</th>
                                    <td class="text-gray">{{article.learn_status}}</td>
                                </tr>
                            </div>
                        </tbody>
                    </table>
                </div>
            </mat-card-content>
            <mat-card-actions class="font-family-NSB " fxLayout fxLayoutAlign="start" fxFlex="50%" fxFlex.xs="100%">
                <!-- fxLayoutAlign="start" fxFlexOffset="168px"
                 fxLayoutGap.xs="5px" fxFlexOffset.xs="0px" -->
                <button mat-button type="submit" class="btn btn-yolow btn-md radius-50 font-family-NSB"
                    (click)="back()">
                    back
                </button>
            </mat-card-actions>
        </div>
    </mat-card>
</div>
