import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
// import { CryptoService } from "../../../services/crypto.service";
// import { InsuranceService } from '../../../services/insurance.service';
import { AppState } from '../../../services/app.state';
import { UserInterface } from '../../../interfaces/user.interface';
import { UserDataService } from '../../../services/user-data.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { AddInsuranceComponent } from '../add-insurance/add-insurance.component';
import { CommonService } from '../../../services/common.service';
import { environment } from '../../../environments/environment';
import { InsuranceService } from '../../../services/insurance.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-dd',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-update-insurance',
  templateUrl: './update-insurance.component.html',
  styleUrls: ['./update-insurance.component.css'],
})
export class UpdateInsuranceComponent implements OnInit {
  insurancePDFselector = new FormControl(0);
  isLoading: boolean = true;
  isLoadingUser: boolean = true;
  actionControl = new FormControl();
  deleteCoverages: any = [];
  basicInsuranceState: boolean = true;
  insuranceProviderState: boolean = true;
  insuranceEntitiesConverageState: boolean = true;
  insuranceEntitiesState: boolean = true;
  insuranceConverageState: boolean = true;
  insuranceStatus: string = 'pending';

  InitalCharacterURL: string = environment.initialCharacterURL;

  PDFList: any = [];
  //  = ['Auto Insurance', 'Umbrella Insurance', 'Condo Insurance', 'Home Warranty Insurance', 'Health Insurance', 'Dental Insurance'];

  userData: UserInterface | any;
  formGroup: FormGroup = this.fb.group({
    id: [0, Validators.compose([])],
    policynumber: ['', Validators.compose([Validators.required])],
    totalpremium: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(/^-?\d*[.,]?\d{0,2}$/),
      ]),
    ],
    expirationdate: ['', Validators.compose([Validators.required])],
    effectivedate: ['', Validators.compose([Validators.required])],
    policyprovider: ['', Validators.compose([Validators.required])],
    partyinsured: ['', Validators.compose([])],

    policyprovidercontactnumber: ['', Validators.compose([])],
    policyprovideremail: [
      '',
      Validators.compose([
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+.[a-zA-Z]{2,3}$'),
      ]),
    ],
    agentname: ['', Validators.compose([])],
    agentcontactnumber: ['', Validators.compose([])],
    agentemail: [
      '',
      Validators.compose([
        ,
        Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+.[a-zA-Z]{2,3}$'),
      ]),
    ],

    coverages: this.fb.array([]),
  });
  totalPages: number = 0;
  isLoaded: boolean = false;
  entity: any = {};
  insurance: any = {};
  insuranceSummary: any = {};
  insuranceCoverages: any[] = [];
  userInsuranceAbstract: any = {};
  userInsuranceContact: any = {};
  insuranceTypeDetails: any[] = [];

  // @ViewChild('scrollMe') scrollEntities: ElementRef | any;
  pdfSrc = '';
  zoom = 1.0;

  images: any = [];
  getInsuranceConveragesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'user_insurance_section_coverage-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      agent_cognito_id: '',
      user_id: '',
      sequence_id: 0,
    },
  };
  updateInsuranceConveragesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'user_insurance_section_coverage-U',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      agent_cognito_id: '',
      user_id: '',
      status: '',
      sequence_id: 0,
      coverages: '',
      userInsuranceAbstract: {},
      userInsuranceContact: {},
      deleteCoverages: [],
    },
  };
  getInsuranceTypeDetailsPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'insurance_type_details-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      agent_cognito_id: '',
      start: 0,
      limit: 5,
    },
  };
  getDeleteInsuranceDocumentPayLoad = {
    header: {
      message_uuid: '',
      message_type: 'Q',
      version: '1.0',
      message_name: 'admin_user_insurance_document-D',
      correlation_uuid: '',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      user_id: '',
      insurance_id: '',
      insurance_document_id: '',
    },
  };

  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  handleEvent(event: any) {

    switch (event.name) {
      case 'print':
        break;
    }
  }

  actionChanges(type: any) {
    if (type == 'delete') {
      const dialogRef = this.dialog.open(DeleteDialogComponent, {
        width: '500px',
        data: {
          header: 'Delete',
          description: `Are you sure want to delete this ${
            this.PDFList[this.insurancePDFselector.value].type
          } ?` /* this.PDFList[this.insurancePDFselector.value].id */,
          id: this.insuranceSummary.id,
          action: 'DELETE',
          okbutton: 'DELETE',
          cancelbutton: 'CANCEL',
        },
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result.event == 'DELETE') {
          this.getDeleteInsuranceDocumentPayLoad.header.created_by =
            this.userData != undefined ? this.userData.cognitoId : '';
          this.getDeleteInsuranceDocumentPayLoad.body.user_id =
            this.insuranceSummary.userId != undefined
              ? this.insuranceSummary.userId
              : '';
          this.getDeleteInsuranceDocumentPayLoad.body.insurance_id =
            this.insuranceSummary.id;
          this.getDeleteInsuranceDocumentPayLoad.body.insurance_document_id =
            this.PDFList[this.insurancePDFselector.value].id;
          this.deleteInsuranceDocument();
          this.loadInsuranceCoverage();
          this.insurancePDFselector.setValue(0);
          this.insurancePDFChange();
        }
      });
    } else if (type == 'add') {
      const dialogRef = this.dialog.open(AddInsuranceComponent, {
        width: '500px',
        data: {
          header: 'Add New Documents',
          userId: this.insuranceSummary.userId,
          insuranceId: this.insuranceSummary.id,
          type: 'update',
        },
        // data: {insuranceType: this.insuranceType, upload_documents: this.upload_documents},
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result.action == 'add') {
          this.loadInsuranceCoverage();
          this.insurancePDFselector.setValue(0);
          this.insurancePDFChange();
        }
      });
    }
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  constructor(
    private fb: FormBuilder,
    private appState: AppState,
    private router: Router,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private snackBar: MatSnackBar,
    private commanService: CommonService,
    private insuranceService: InsuranceService,
    private userDataService: UserDataService
  ) {}

  ngOnInit() {
    let insurance = this.appState.get('INSURANCE');
    if (insurance == null) {
      this.router.navigate(['/insurances']);
    }

    this.insuranceSummary = insurance;
    this.insuranceStatus = this.insuranceSummary.status;
    let route = this.appState.get('ROUTE');
    
    if (route == '/details-customer') {
      let customer = this.appState.get('CUSTOMER');
      this.insuranceSummary.name = customer.name;
      // this.insuranceSummary.lastName = "";
      this.insuranceSummary.email = customer.email;
      this.insuranceSummary.phoneNumber = customer.phoneNumber;
      this.insuranceSummary.id = insurance.insuranceSequenceId;
    }
    this.initForm();

    this.userData = this.userDataService.getUserData();
    this.getInsuranceConveragesPayLoad.header.created_by =
      this.userData.cognitoId;
    this.getInsuranceConveragesPayLoad.body.agent_cognito_id =
      this.userData.cognitoId;

    this.updateInsuranceConveragesPayLoad.header.created_by =
      this.userData.cognitoId;
    this.updateInsuranceConveragesPayLoad.body.agent_cognito_id =
      this.userData.cognitoId;

    this.getInsuranceTypeDetailsPayLoad.header.created_by =
      this.userData.cognitoId;
    this.getInsuranceTypeDetailsPayLoad.body.agent_cognito_id =
      this.userData.cognitoId;

    this.loadInsuranceTypeDetails();

    let items = this.formGroup.get('entity') as FormArray;

    if (this.insurance.fields) {
      for (let i = 0; i < this.insurance.fields.length; i++) {
        items.push(this.addNewEntityFormGroup(this.insurance.fields[i]));
      }
    }
  }

  async deleteInsuranceDocument() {
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getDeleteInsuranceDocumentPayLoad
    );
    if (parseResponse) {
      this.snackBar.open(parseResponse.message, 'close', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
      this.loadInsuranceCoverage();
      this.router.navigate(['/insurances']);
    }
  }

  getCreate(created: any) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'yyyy-MM-dd h:mm:ss a');
  }

  getInsuranceType(key: any) {
    let type = this.insuranceSummary.insuranceType.indexOf('insurance')
      ? this.insuranceSummary.insuranceType.replace('insurance', '')
      : this.insuranceSummary.insuranceType;
    // let type=this.insuranceSummary.find(i => i.id === key);
    type = type.charAt(0).toUpperCase() + type.slice(1);
    if (type) {
      return type;
    } else {
      return '';
    }
  }

  initForm() {
    this.formGroup = this.fb.group({
      id: [this.insuranceSummary.id, Validators.compose([])],
      policynumber: ['', Validators.compose([Validators.required])],
      totalpremium: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^-?\d*[.,]?\d{0,2}$/),
        ]),
      ],
      expirationdate: ['', Validators.compose([Validators.required])],
      effectivedate: ['', Validators.compose([Validators.required])],
      policyprovider: ['', Validators.compose([Validators.required])],
      partyinsured: ['', Validators.compose([])],

      policyprovidercontactnumber: ['', Validators.compose([])],
      policyprovideremail: [
        '',
        Validators.compose([
          ,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+.[a-zA-Z]{2,3}$'),
        ]),
      ],
      agentname: ['', Validators.compose([])],
      agentcontactnumber: ['', Validators.compose([])],
      agentemail: [
        '',
        Validators.compose([
          ,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+.[a-zA-Z]{2,3}$'),
        ]),
      ],

      coverages: this.fb.array([]),
    });
  }

  async loadInsuranceTypeDetails() {
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsuranceTypeDetailsPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.insuranceTypeDetails = parseResponse.insuranceTypeDetails;
      await this.loadInsuranceCoverage();
    }
  }

  addCoverage(c: any) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    coverages.push(this.createCoverage(c));
  }

  createCoverage(c: any) {
    return this.fb.group({
      sequence_id: [c.sequence_id || '', Validators.compose([])],
      user_insurance_sequence_id: [
        c.user_insurance_sequence_id || '',
        Validators.compose([]),
      ],
      item_name: [c.item_name || '', Validators.compose([])],
      entity: this.fb.array([]),
      coveragedetails: this.fb.array([]),
    });
  }

  getCoverages() {
    let coverages = this.formGroup.get('coverages') as FormArray;
    return coverages.controls;
  }

  getEntityControls(c: number) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let entities = coverageFormGroup.get('entity') as FormArray;
    return entities.controls;
  }

  addEntity(c: any, e: any): void {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let entities = coverageFormGroup.get('entity') as FormArray;

    entities.push(this.addNewEntityFormGroup(e));
    // this.scrollInsuredItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    // this.scrollInsuredItem.nativeElement.scrollTop = this.scrollInsuredItem.nativeElement.scrollHeight;
  }

  getCoverageDetailsControls(c: any) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let coveragedetails = coverageFormGroup.get('coveragedetails') as FormArray;
    return coveragedetails.controls;
  }

  addNewEntityFormGroup(entity: any): FormGroup {
    return this.fb.group({
      item_title: [entity.item_title || ''],
      item_value: [entity.item_value || ''],
    });
  }

  addCoverageDetails(c: any, cd: any): void {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let coveragedetails = coverageFormGroup.get('coveragedetails') as FormArray;

    coveragedetails.push(this.addNewCoverageDetailsFormGroup(cd));
  }

  addNewCoverageDetailsFormGroup(cd: any): FormGroup {
    return this.fb.group({
      coverage_icon: [cd.coverage_icon || ''],
      coverage_icon_disabled: [cd.coverage_icon_disabled || ''],
      coverage_name: [cd.coverage_name || ''],
      coverage_details: [cd.coverage_details || ''],
      coverage_deductible: [
        cd.coverage_deductible || '',
        [Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)],
      ],
      coverage_enabled: [cd.coverage_enabled],
    });
  }

  removeCoverage(c: number) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c];
    if (coverageFormGroup.value && coverageFormGroup.value.sequence_id) {
      this.deleteCoverages.push(coverageFormGroup.value.sequence_id);
      this.updateInsuranceConveragesPayLoad.body.deleteCoverages =
        this.deleteCoverages;
    }

    coverages.removeAt(c);
  }

  removeCoverageDetails(c: number, i: number) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let coveragedetails = coverageFormGroup.get('coveragedetails') as FormArray;
    coveragedetails.removeAt(i);
  }

  removeInsuredItem(c: number, i: number) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let entities = coverageFormGroup.get('entity') as FormArray;
    entities.removeAt(i);
  }

  submit(): any {
    var configError: MatSnackBarConfig = {
      duration: 1000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['error-snackbar'],
    };
    if (this.formGroup.invalid) {
      this.snackBar.open(
        'Please fill the all required fields details',
        'close',
        configError
      );
      return null;
    }
    let data = this.formGroup.value;

    let coverages: any[] = [];

    for (let c in data.coverages) {
      let coverage: any = data.coverages[c];

      let cover = coverage.coveragedetails.map((cover: any, index: any) => {
        cover.coverage_icon = cover.coverage_icon
          ? cover.coverage_icon
          : this.InitalCharacterURL +
            '/coverages-icons/' +
            cover.coverage_name.charAt(0).toLowerCase() +
            '-enabled.png';

        cover.coverage_icon_disabled = cover.coverage_icon_disabled
          ? cover.coverage_icon_disabled
          : this.InitalCharacterURL +
            '/coverages-icons-disabled/' +
            cover.coverage_name.charAt(0).toLowerCase() +
            '-disabled.png';
      });

      let item: any = {};
      item.item_name = coverage.item_name;
      item.item_details = coverage.entity;

      let coverageJson = {
        sequence_id: coverage.sequence_id,
        user_insurance_sequence_id: this.insuranceSummary.id,
        insured_item: JSON.stringify(item),
        insurance_coverage: JSON.stringify(coverage.coveragedetails),
      };
      coverages.push(coverageJson);
    }

    let policyEffectiveDate = this.datepipe.transform(
      data.effectivedate,
      'yyyy-MM-dd'
    );
    let expirationDate = this.datepipe.transform(
      data.expirationdate,
      'yyyy-MM-dd'
    );

    let userInsuranceAbstract = {
      user_insurance_sequence_id: this.insuranceSummary.id,
      policy_number: data.policynumber,
      policy_effective_date: policyEffectiveDate,
      policy_expiration_date: expirationDate,
      policy_provider: data.policyprovider,
      party_insured: data.partyinsured,
      total_premium: data.totalpremium,
    };

    let userInsuranceContact = {
      user_insurance_sequence_id: this.insuranceSummary.id,
      policy_provider_contact_number: data.policyprovidercontactnumber,
      policy_provider_email: data.policyprovideremail,
      agent_name: data.agentname,
      agent_contact_number: data.agentcontactnumber,
      agent_email: data.agentemail,
      policy_provider: data.policyprovider,
    };

    this.updateInsuranceConveragesPayLoad.body.status = this.insuranceStatus;
    this.updateInsuranceConveragesPayLoad.body.coverages =
      JSON.stringify(coverages);
    this.updateInsuranceConveragesPayLoad.body.userInsuranceAbstract =
      userInsuranceAbstract;
    this.updateInsuranceConveragesPayLoad.body.userInsuranceContact =
      userInsuranceContact;

    this.updateInsuranceCoverage();
  }

  insurancePDFChange() {
    let index = this.insurancePDFselector.value;
    this.images = [];
    this.pdfSrc = '';
    if (this.PDFList[index].type == 'Image') {
      this.images.push(this.PDFList[index].url);
    } else if (this.PDFList[index].type == 'Document') {
      this.pdfSrc = this.PDFList[index].url;
    }
  }

  async loadInsuranceCoverage() {
    this.isLoadingUser = true;
    this.getInsuranceConveragesPayLoad.body.sequence_id =
      this.insuranceSummary.id;
    this.getInsuranceConveragesPayLoad.body.user_id =
      this.insuranceSummary.userId;
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsuranceConveragesPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.images = [];

      this.PDFList = parseResponse.userInsurance
        ? parseResponse.userInsurance.insurance_documents
        : [];
      this.PDFList.filter((x: any) => {
        if (x.url.includes('.pdf')) {
          x.type = 'Document';
        } else {
          x.type = 'Image';
        }
      });
      if (this.PDFList.length > 0) {
        if (
          this.PDFList[0].url.includes('.jpeg') ||
          this.PDFList[0].url.includes('.jpg') ||
          this.PDFList[0].url.includes('.png')
        ) {
          this.images.push(this.PDFList[0].url);
        } else if (this.PDFList[0].url.includes('.pdf')) {
          this.pdfSrc = this.PDFList[0].url;
        }
      }

      if (parseResponse.userInsuranceAbstract) {
        this.userInsuranceAbstract = parseResponse.userInsuranceAbstract;
        this.loadInsuranceAbstractForm();
        this.isLoadingUser = false;
      }
      if (parseResponse.userInsuranceContact) {
        this.userInsuranceContact = parseResponse.userInsuranceContact;
        this.loadInsuranceContactForm();
        this.isLoadingUser = false;
      }
      if (
        parseResponse.userInsuranceCoverages &&
        parseResponse.userInsuranceCoverages.length > 0
      ) {
        this.insuranceCoverages = parseResponse.userInsuranceCoverages;
        this.loadInsuranceCoverageForm();
        this.isLoadingUser = false;
      } else {
        this.populateCoverageForm();
        this.isLoadingUser = false;
      }
    }
  }

  loadInsuranceContactForm() {
    if (
      this.userInsuranceContact &&
      this.userInsuranceContact.policy_provider_contact_number
    ) {
      this.formGroup.controls.policyprovidercontactnumber.patchValue(
        this.userInsuranceContact.policy_provider_contact_number
      );
    }
    if (
      this.userInsuranceContact &&
      this.userInsuranceContact.policy_provider_email
    ) {
      this.formGroup.controls.policyprovideremail.patchValue(
        this.userInsuranceContact.policy_provider_email
      );
    }
    if (this.userInsuranceContact && this.userInsuranceContact.agent_name) {
      this.formGroup.controls.agentname.patchValue(
        this.userInsuranceContact.agent_name
      );
    }
    if (
      this.userInsuranceContact &&
      this.userInsuranceContact.agent_contact_number
    ) {
      this.formGroup.controls.agentcontactnumber.patchValue(
        this.userInsuranceContact.agent_contact_number
      );
    }
    if (this.userInsuranceContact && this.userInsuranceContact.agent_email) {
      this.formGroup.controls.agentemail.patchValue(
        this.userInsuranceContact.agent_email
      );
    }
  }

  loadInsuranceAbstractForm() {
    if (this.userInsuranceAbstract && this.userInsuranceAbstract.sequence_id) {
      this.formGroup.controls.id.patchValue(
        this.userInsuranceAbstract.sequence_id
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.policy_number
    ) {
      this.formGroup.controls.policynumber.patchValue(
        this.userInsuranceAbstract.policy_number
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.policy_effective_date
    ) {
      this.formGroup.controls.effectivedate.patchValue(
        this.userInsuranceAbstract.policy_effective_date
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.policy_expiration_date
    ) {
      this.formGroup.controls.expirationdate.patchValue(
        this.userInsuranceAbstract.policy_expiration_date
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.policy_provider
    ) {
      this.formGroup.controls.policyprovider.patchValue(
        this.userInsuranceAbstract.policy_provider
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.party_insured
    ) {
      this.formGroup.controls.partyinsured.patchValue(
        this.userInsuranceAbstract.party_insured
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.total_premium
    ) {
      this.formGroup.controls.totalpremium.patchValue(
        this.userInsuranceAbstract.total_premium
      );
    }
  }

  loadInsuranceCoverageForm() {
    if (this.insuranceCoverages) {
      for (let i in this.insuranceCoverages) {
        let insuranceCoverage = this.insuranceCoverages[i];

        if (
          insuranceCoverage.insured_item &&
          insuranceCoverage.insured_item.length > 0
        ) {
          let insuredItems = JSON.parse(insuranceCoverage.insured_item);
          insuranceCoverage.item_name = insuredItems.item_name;
        }
        this.addCoverage(insuranceCoverage);

        if (
          insuranceCoverage.insured_item &&
          insuranceCoverage.insured_item.length > 0
        ) {
          let insuredItems = JSON.parse(insuranceCoverage.insured_item);
          for (let n in insuredItems.item_details) {
            let item = insuredItems.item_details[n];
            this.addEntity(i, item);
          }
        }

        if (
          insuranceCoverage.insurance_coverage &&
          insuranceCoverage.insurance_coverage.length > 0
        ) {
          let insuranceCoverageDetails = JSON.parse(
            insuranceCoverage.insurance_coverage
          );

          for (let entity in insuranceCoverageDetails) {
            let item = insuranceCoverageDetails[entity];
            this.addCoverageDetails(i, item);
          }
        }
      }
    }
  }

  populateCoverageForm() {
    let insuranceTypeDetails = this.insuranceTypeDetails.find(
      (i) => i.insuranceType === this.insuranceSummary.insuranceType
    );
    if (insuranceTypeDetails) {
      for (let e in insuranceTypeDetails.entities) {
        let entity = insuranceTypeDetails.entities[e];
        let coverage = { item_name: entity.title };
        this.addCoverage(coverage);

        for (let d in entity.insuredEntities) {
          let item = entity.insuredEntities[d];
          let insuredItem = { item_title: item, item_value: '' };
          this.addEntity(e, insuredItem);
        }

        for (let c in entity.coverages) {
          let coverage = entity.coverages[c];
          let coverageDetails = {
            coverage_icon_disabled: coverage.icon_disabled,
            coverage_icon: coverage.icon_enabled,
            coverage_name: coverage.name,
            coverage_details: coverage.details,
            coverage_deductible: coverage.deductibles,
            coverage_enabled: false,
          };
          this.addCoverageDetails(e, coverageDetails);
        }
      }
    }
  }

  async updateInsuranceCoverage() {
    this.updateInsuranceConveragesPayLoad.body.sequence_id =
      this.insuranceSummary.id;
    this.updateInsuranceConveragesPayLoad.body.user_id =
      this.insuranceSummary.userId;
    this.isLoading = true;
   
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.updateInsuranceConveragesPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.snackBar.open(parseResponse.message, '', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
      this.back();
    }
  }

  back() {
    this.router.navigate(['/insurances']);
  }
}
