import {Injectable} from '@angular/core';
import {APIWrapperService} from './api-wrapper.service';
import {AWSHelperService} from './aws4-helper.service';
import {allURLS} from './allURL';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {

  apiUrl = environment.api_endpoint;
  host: string = this.awsHelper.getHost('/feedback');
  allURLS: { [key: string]: string } = {};

  constructor(private APIWrapperService: APIWrapperService, private awsHelper: AWSHelperService, private http: HttpClient) {
  }

  getFeedbacks(payload: any) {
    return this.http.post(this.apiUrl, payload).toPromise()
  }
}
