import { Injectable } from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { InsuranceService } from './insurance.service';
import { ProfileService } from './profile.service';
import { CryptoService } from './crypto.service';
import { ArticleService } from './article.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxImageCompressService, DataUrl } from 'ngx-image-compress';

import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { FeedbackService } from './feedback.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(private cryptoService: CryptoService, private snackBar: MatSnackBar,
        private imageCompressor: NgxImageCompressService,
        private authenticationService: AuthenticationService,
        private profileService: ProfileService, private insuranceService: InsuranceService,
        private articleService: ArticleService, private http: HttpClient,
        private feedbackService: FeedbackService,
    ) {

    }

    // genaric api call
    apiCall(service: any, method: any, payload: any) {
        return new Promise((resolve, reject) => {
            var cognitoIdEnc = this.cryptoService.getBase64String(payload.header.created_by);

            //stringify the payload
            var payload_stringfy = JSON.stringify(payload).toString();
            var encrypted_Payload = this.cryptoService.getEncryptedBody(payload.header.created_by, payload_stringfy);
            let responseObs: any;
            let innerObj = {
                "headers": { "x-shyld-app-id": cognitoIdEnc },
                "body": encrypted_Payload.toString()
            }
            if (service == 'authenticationService') {
                responseObs = this.authenticationService.login(innerObj);
            }
            else if (service == 'profileService') {
                if (method == "getUsersDetails") {
                    responseObs = this.profileService.getUsersDetails(innerObj);
                }
            }
            else if (service == 'insuranceService') {
                if (method == "getInsurances") {
                    responseObs = this.insuranceService.getInsurances(innerObj);
                }
            }
            else if (service == 'articleService') {
                if (method == "getArticle") {
                    responseObs = this.articleService.getArticle(innerObj);
                }
            }
            else if (service == 'feedbackService') {
                if (method == "getFeedbacks") {
                    responseObs = this.feedbackService.getFeedbacks(innerObj);
                }
            }
            responseObs.subscribe(
                (data: any) => {
                    let responseData: any = data;
                    if (responseData.hasOwnProperty('errorMessage')) {
                        this.snackBar.open(responseData.errorMessage, 'close', {
                            duration: 3000,
                            verticalPosition: "top",
                            horizontalPosition: "right",
                            panelClass: ['error-snackbar']
                        });
                        reject(responseData);
                    }
                    else {
                        let response: any = this.cryptoService.getDecryptedBody(payload.header.created_by, responseData.body);
                        let parsedResponse = JSON.parse(response);

                        if (method == "login") {
                            let response = { 'response': responseData, 'parseResponse': parsedResponse }
                            resolve(response);
                        } else {
                            resolve(parsedResponse);
                        }

                    }
                },
                (error: any) => {
                    reject(error);
                }
            );
        })
    }

    //image compression
    compressFile(image: any) {
        return new Promise(async (resolve, reject) => {
            let sizeOfOriginalImage = this.imageCompressor.byteCount(image) / (1024 * 1024);
            const config: any = { orientation: -1, quality: 100, maxwidth: 200, maxheight: 100 };

            this.imageCompressor.compressFile(image, config).then(
                (result: DataUrl) => {
                    let sizeOFCompressedImage = this.imageCompressor.byteCount(result) / (1024 * 1024);

                    // this.compressImageSubject.next(result);
                    resolve(result);
                },
                error => {
                    reject(error);
                });

        });
    }

    //chat download image
    download(url: string): Observable<Blob> {
        return this.http.get(url, {
            responseType: 'blob'
        })
    }

    //chat download pdf
    downloadPdf(url: string): Observable<ArrayBuffer> {
        return this.http.get(url, {
            responseType: 'arraybuffer'
        })
    }
}
