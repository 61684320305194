import { Buffer } from "buffer";
import * as CryptoJS from "crypto-js";
import {
  addDoc,
  collection,
  doc,
  endBefore,
  getDocs,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import moment from "moment/moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/Col";
import { ChatContext } from "../Context/ChatContext";
import { styles } from "../Custom";
import { db, storage } from "../firebase";

const Loader = "./assets/img/loading.gif";
const markRead = "./assets/img/bluetick.svg";
const downIcon = "./assets/img/down.png";
const attachmentIcon = "./assets/img/attachment.png";
const sendBtn = "./assets/img/sendBtn.png";

const Messages = () => {
  const [msgs, setMsgs] = useState([]);

  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "");
  let { data } = useContext(ChatContext);

  const adminId = currentUser[0].id;
  const supportID = currentUser[0].current_support_id;
  const supportName = currentUser[0].user_name;
  const isEmpty = Object.keys(data).length === 0;

  if (isEmpty == true) {
    let customData = JSON.parse(localStorage.getItem("selectedUser") || "");
    data = customData;
  }

  // 4R2w3e3SEOyfKK8aKt7h4Ap9TVs4FevVr045H9i+xJdMwolU3X9LVWeLHpz/J/Mu/XVyOK8tdAZlx3XbX3zeiPQ30m
  // O4UGh0qYkNc0rd1zUQxiczxf3plUzJqImeGDCCBPvqdFlp0YVRBndSSMcqJKzD7NUxjhowNHzTOi3Lc5uEZKOFkWyi1KKCfZjfJR0qTlgoXq05tfWnCwE727CKog==

  const crypto = require("crypto-browserify");
  const encryptChat = (text: any) => {
    const chatEncryptionSecretKey =
      "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
    const mdString = crypto
      .createHash("md5")
      .update(
        Buffer.from(chatEncryptionSecretKey).toString("ASCII"),
        0,
        chatEncryptionSecretKey.length
      )
      .digest("hex");
    const substr = mdString.substring(0, 16);

    const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
    const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
    var encrypted = CryptoJS.AES.encrypt(text, CHATKEY, {
      keySize: 128 / 8,
      iv: CHATIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  };
  /* Decrypt the Message content */
  const decryptMessage = (stringds: any) => {
    if (stringds === "") {
      return "";
    }

    const chatEncryptionSecretKey =
      "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
    const mdString = CryptoJS.MD5(chatEncryptionSecretKey).toString();
    const substr = mdString.substring(0, 16);
    const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
    const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));

    try {
      const decrypted = CryptoJS.AES.decrypt(stringds, CHATKEY, {
        iv: CHATIV,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
      });
      return decrypted.toString(CryptoJS.enc.Utf8) || "";
    } catch (error) {
      console.error("Decryption error:", error);
      return "";
    }
  };

  // Usage example
  const encryptedMessage = "your_encrypted_message_here";
  const decryptedMessage = decryptMessage(encryptedMessage);
  console.log(decryptedMessage);

  var listeners = []; // list of listeners
  var start = null; // start position of listener
  var end = null; // end position of listener

  const [isStart, setStart] = useState();

  const [isCount, setCount] = useState();

  //State for pagination message
  const [paginationMessage, setPaginationMessage] = useState([]);
  const [loading, setLoading] = useState(false);

  const MAX_LENGTH = 250;
  //Send Message Logic goes here
  const [text, setText] = useState(""); //for textbox setText and clear after send
  //Function to scroll the chat message at end
  const scrollRef = useRef<any | HTMLElement>(null);
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ top: 0, behavior: "smooth" });
  }, [msgs]);

  const [img, setImg] = useState<any>(null); //send an attachment
  const inputRef = useRef<any>(null);

  const onKeyPress = (e: any) => {
    if (e.which === 13) {
      handleSubmit(e);
    }
  };

  const handleSubmit = async (e: any) => {
    if (img != null && inputRef.current.value != "") {
      setImg(null);
      inputRef.current.value = "";
      return alert("You cannot send file & text message at same time");
    }
    e.preventDefault();

    const user2 = data.id;
    let url;
    if (img) {
      if (img == null) {
        return false;
      }
      const imgRef = ref(
        storage,
        `${user2}/image_` + Math.floor(new Date().getTime()) / 1000
      );

      const snap = await uploadBytes(imgRef, img);
      const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      url = dlUrl;
      let mimeType = img.type;

      await addDoc(collection(db, "dev_users", `${data.id}`, "support_chat"), {
        current_support_id: supportID,
        attachment_url: encryptChat(url),
        from: "support",
        to: "user",
        id: data.id,
        status: "assigned",
        message: "",
        mime_type: mimeType,
        support_id: 0,
        support_name: supportName,
        timestamp: Math.floor(new Date().getTime()),
        user_name: data.user.user_name,
        user_read: false,
      });

      await setDoc(doc(db, "dev_users", `${data.id}`), {
        current_support_id: supportID,
        id: data.id,
        status: "assigned",
        support_id: 0,
        support_name: supportName,
        last_message: "image",
        last_message_timestamp: Math.floor(new Date().getTime()),
        user_name: data.user.user_name,
        user_read: false,
      });
      setImg(null);
    } else {
      if (inputRef.current.value == "") {
        alert("Cannot Send A Blank Message");
        return false;
      }
      inputRef.current.value = "";
      await addDoc(collection(db, "dev_users", `${data.id}`, "support_chat"), {
        attachment_url: "",
        current_support_id: supportID,
        from: "support",
        to: "user",
        id: data.id,
        status: "assigned",
        message: encryptChat(text),
        mime_type: "",
        support_id: 0,
        support_name: supportName,
        timestamp: Math.floor(new Date().getTime()),
        user_name: data.user.user_name,
        user_read: false,
      });

      await setDoc(doc(db, "dev_users", `${data.id}`), {
        current_support_id: supportID,
        id: data.id,
        status: "assigned",
        support_id: 0,
        support_name: supportName,
        last_message: encryptChat(text),
        last_message_timestamp: Math.floor(new Date().getTime()),
        user_name: data.user.user_name,
        user_read: false,
      });
      inputRef.current.value = "";
    }

    const msgRef = collection(db, "dev_users", `${data.id}`, "support_chat");
    //Query for first 10 records
    const q = query(msgRef, orderBy("timestamp", "asc"), limitToLast(10));
    //Last Visible document ID
    const documentSnapshots = await getDocs(q);
    const lastVisible: any =
      documentSnapshots.docs[documentSnapshots.docs.length - 10];
    setStart(lastVisible);
  };

  //Event for user click on down arrow
  const [clickDown, setClickDown] = useState(false);

  const scrollToEnd = (e: any) => {
    const scrollElement: any = document.querySelector("#chat-container");
    scrollElement.scroll({
      top: scrollElement.scrollHeight,
      left: 0,
      behavior: "smooth",
    });
    showDownArrow(false);
    setClickDown(true);
    clickScrollMessage();
  };

  //Handle the Events when user scroll up and downword direction
  const [userHasScrolled, setUserHasScrolled] = useState(false);
  const [downArrow, showDownArrow] = useState(false);
  const handleScroll = (event: any) => {
    var lastScroll = 0;
    const scrollElement = document.querySelector("#chat-container");

    var currentPosition = event.currentTarget.scrollTop;
    const userId = data.id;

    if (lastScroll === currentPosition) {
      localStorage.setItem("scroll", "true");
      showDownArrow(true);
      if (
        (isStart && Object.keys(isStart).length !== 0) ||
        isStart !== undefined
      ) {
        setLoading(true);
        showDownArrow(true);
        const msgRef = collection(db, "dev_users", `${userId}`, "support_chat");
        const q = query(
          msgRef,
          orderBy("timestamp", "asc"),
          endBefore(isStart),
          limitToLast(10)
        );
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          let newMessagesArray: any = [];

          querySnapshot.forEach((doc) => {
            newMessagesArray.push(doc.data());
          });
          //setPaginationMessage(Array.from(newMessagesArray));
          setMsgs(newMessagesArray.concat(msgs));
          setNewState(q);
          setLoading(false);
        });

        const setNewState = async (q: any) => {
          const documentSnapshots = await getDocs(q);
          if (documentSnapshots.size > 0) {
            const lastVisible: any =
              documentSnapshots.docs[documentSnapshots.docs.length - 10];
            setStart(lastVisible);
          }
        };
      }
    }

    const bottom =
      Math.round(event.target.scrollHeight) -
        Math.round(event.target.scrollTop) ===
      Math.round(event.target.clientHeight);

    if (bottom) {
      showDownArrow(false);
      localStorage.setItem("scroll", "false");
      if (clickDown === false || localStorage.getItem("scroll") === "false") {
        //updateNewScrollMessage();
      }
    }
  };

  //Get the message of selected user only
  useEffect(() => {
    (async () => {
      //check the scrollbar
      //if user has scrolled store in temp array else push in exisiting array
      //setLoading(true);
      const msgRef = collection(db, "dev_users", `${data.id}`, "support_chat");
      //Query for first 10 records
      const q = query(msgRef, orderBy("timestamp", "asc"), limitToLast(10));
      // Get the last visible document
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let msgs: any = [];

        if (localStorage.getItem("scroll") === "true") {
          const scrollElement = document.querySelector("#chat-container");
          //scrollElement.scrollTo(0, 0);
          showDownArrow(true);
        } else {
          querySnapshot.forEach((doc) => {
            updateLastVisible(q);
            msgs.push(doc.data());
          });
          setMsgs(msgs);
          setLoading(false);
          showDownArrow(false);
        }
      });
      const updateLastVisible = async (q: any) => {
        const documentSnapshots = await getDocs(q);
        const lastVisible: any =
          documentSnapshots.docs[documentSnapshots.docs.length - 10];
        setStart(lastVisible);
      };
      //Last Visible document ID
      const documentSnapshots = await getDocs(q);
      const lastVisible: any =
        documentSnapshots.docs[documentSnapshots.docs.length - 10];

      setStart(lastVisible);
      //unsubscribe();
      return () => {
        unsubscribe();
      };
    })();
  }, [data.id]);

  const clickScrollMessage = async () => {};
  const TickSvg = () => (
    <svg
      style={{ width: "auto", height: "12px", fill: "#007bff" }}
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="512"
      height="512"
    >
      <path d="m23.562,9.059l-12.588,12.622c-.852.852-1.981,1.319-3.183,1.319h-.009c-1.206-.003-2.337-.476-3.187-1.331L.445,17.566c-.589-.582-.595-1.532-.012-2.121.583-.59,1.532-.595,2.122-.012l4.16,4.112c.293.295.67.453,1.073.454h.003c.4,0,.777-.156,1.061-.439l12.587-12.62c.584-.588,1.535-.588,2.121-.003.587.585.588,1.535.003,2.121Zm-18.538,2.708c.786.793,1.834,1.23,2.95,1.233h.009c1.113,0,2.159-.434,2.952-1.226L19.066,3.555c.583-.589.578-1.539-.011-2.122-.589-.582-1.538-.576-2.122.011l-8.126,8.213c-.22.221-.513.342-.825.342h-.002c-.313,0-.606-.123-.813-.332l-3.589-3.711c-.576-.596-1.525-.611-2.121-.035-.595.576-.611,1.525-.035,2.121l3.603,3.724Z" />
    </svg>
  );
  return (
    <>
      <div
        style={
          data.user.status == "assigned"
            ? {
                overflowY: "scroll",
                overflowX: "hidden",
                height: "calc(100vh - 250px)",
                border: "#000",
              }
            : {
                overflowY: "scroll",
                overflowX: "hidden",
                height: "calc(100vh - 160px)",
                border: "#000",
              }
        }
        className="col-lg-12 chat-container mt-1"
        onScroll={handleScroll}
        id="chat-container"
      >
        {downArrow ? (
          <img
            // style={styles.bottomicon}
            src={downIcon}
            alt=""
            height="60"
            onClick={scrollToEnd}
            style={{
              position: "absolute",
              bottom: "20px",
              right: "50%",
              borderRadius: "30px",
              zIndex: "10",
              cursor: "pointer",
            }}
            className="bottom-icon"
          />
        ) : (
          ""
        )}

        {loading ? (
          <img
            src={Loader}
            style={{
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50px,-50px)",
            }}
          />
        ) : (
          msgs.map((m: any, index) => {
            return (
              <Col
                key={index}
                xs={12}
                md={12}
                style={
                  m.from != "user"
                    ? { float: "right" }
                    : { float: "left", display: "flex" }
                }
              >
                {m.from == "user" ? (
                  <div style={{ display: "block" }}>
                    <span
                      style={
                        m.from != "user"
                          ? {
                              fontFamily: "Arial, Helvetica, sans-serif",
                              width: "3rem",
                              float: "right",
                              height: "3rem",
                              borderRadius: "50%",
                              background: "#000",
                              fontSize: "1rem",
                              color: "#ffb600",
                              textAlign: "center",
                              lineHeight: "3rem",
                              display: "inline-table",
                              marginTop: "15px",
                              marginRight: "5px",
                            }
                          : {
                              fontFamily: "Arial, Helvetica, sans-serif",
                              width: "3rem",
                              height: "3rem",
                              borderRadius: "50%",
                              background: "#ffb600",
                              fontSize: "1rem",
                              color: "#000",
                              textAlign: "center",
                              lineHeight: "3rem",
                              display: "inline-table",
                              marginTop: "15px",
                              marginRight: "5px",
                            }
                      }
                    >
                      {decryptMessage(m.user_name)
                        .split(" ")
                        .splice(0, 2)
                        .map((user_name: any) => user_name[0])
                        .join("")
                        .toUpperCase()}
                    </span>
                  </div>
                ) : (
                  <span
                    style={
                      m.from != "user"
                        ? {
                            fontFamily: "Arial, Helvetica, sans-serif",
                            width: "3rem",
                            float: "right",
                            height: "3rem",
                            borderRadius: "50%",
                            background: "#000",
                            fontSize: "1rem",
                            color: "#ffb600",
                            textAlign: "center",
                            lineHeight: "3rem",
                            display: "inline-table",
                            marginTop: "15px",
                            marginRight: "5px",
                          }
                        : {
                            fontFamily: "Arial, Helvetica, sans-serif",
                            width: "3rem",
                            height: "3rem",
                            borderRadius: "50%",
                            background: "#ffb600",
                            fontSize: "1rem",
                            color: "#000",
                            textAlign: "center",
                            lineHeight: "3rem",
                            display: "inline-table",
                            marginTop: "15px",
                            marginRight: "5px",
                          }
                    }
                  >
                    {currentUser[0].user_name
                      .split(" ")
                      .splice(0, 2)
                      .map((user_name: any) => user_name[0])
                      .join("")
                      .toUpperCase()}
                  </span>
                )}
                <p
                  key={m.id}
                  style={
                    m.from === "user"
                      ? {
                          position: "relative",
                          float: "left",
                          display: "block",
                          margin: "10px 10px",
                          textAlign: "justify",
                          fontWeight: "300",
                          maxWidth: "max-content",
                          backgroundColor: "rgb(230, 230, 230)",
                          color: "rgb(0, 0, 0)",
                          padding: "15px",
                          borderRadius: "15px 15px 15px 0px",
                          lineHeight: "1",
                        }
                      : {
                          position: "relative",
                          float: "right",
                          display: "block",
                          margin: "10px 10px",
                          textAlign: "justify",
                          fontWeight: "300",
                          maxWidth: "max-content",
                          backgroundColor: "rgb(230, 230, 230)",
                          color: "rgb(0, 0, 0)",
                          padding: "15px",
                          borderRadius: "15px 15px 0px 15px",
                          lineHeight: "1",
                        }
                  }
                >
                  {m?.message !== "" && m?.message?.length > 0 ? (
                    decryptMessage(m?.message)
                  ) : m?.attachment_url !== "" &&
                    (m?.mime_type === "image/jpeg" ||
                      m?.mime_type === "image/jpg" ||
                      m?.mime_type === "image/png") &&
                    m?.attachment_url?.length > 0 ? (
                    <>
                      {m?.attachment_url?.length > 0 &&
                      decryptMessage(m?.attachment_url) ? (
                        <a
                          target="_blank"
                          href={decryptMessage(m?.attachment_url)}
                        >
                          <img
                            src={decryptMessage(m?.attachment_url)}
                            style={{ height: "auto", width: "150px" }}
                          />
                        </a>
                      ) : null}
                    </>
                  ) : m?.mime_type === "application/pdf" &&
                    m?.attachment_url !== "" &&
                    m?.attachment_url ? (
                    <>
                      {m?.attachment_url?.length > 0 ? (
                        <a
                          target="_blank"
                          href={decryptMessage(m?.attachment_url)}
                        >
                          <i
                            className="fa fa-file-pdf-o"
                            aria-hidden="true"
                            style={{ height: "18px", width: "15px" }}
                          ></i>
                        </a>
                      ) : null}
                    </>
                  ) : (
                    ""
                  )}

                  <span
                    className="mr-2"
                    style={
                      {
                        display: "flex",
                        color: "#007bff",
                        fontSize: "12px",
                        justifyContent: "end",
                        position: "relative",
                        fontWeight: "300",
                        // margin: "3px 1px",
                        marginTop: "5px",
                        alignItems: "center",
                        gap: "5px",
                      }
                      // m.from == "user" ? { color: "#000 !important" } : { display: "flex", color: "blue" }
                    }
                  >
                    {moment(m.timestamp).fromNow()}
                    {m.from !== "user" && (
                      // <img
                      //   className="mark-read ml-2"
                      //   style={{
                      //     position: "relative",
                      //     display: "inline-block",
                      //     width: "auto",
                      //     height: "10px",
                      //   }}
                      //   src={markRead}
                      // />
                      <TickSvg />
                    )}
                  </span>
                </p>
              </Col>
            );
          })
        )}
      </div>

      {
        /* If assigned user then send message to Assigned user */
        data.user.status == "assigned" ? (
          //Send Message Form Here
          <>
            <hr />
            <div
              className="message-div col-xs-12 col-md-12"
              style={styles.messagediv}
              id="send-msg-txt"
            >
              <input
                type="text"
                onKeyPress={onKeyPress}
                ref={inputRef}
                onChange={(e) => setText(e.target.value)}
                className="message-input"
                style={styles.messageinput}
                placeholder="Type Your Messasasage Here...."
              />
              <label htmlFor="img" style={styles.fileattAchmentParent}>
                <img
                  alt=""
                  style={styles.fileattachment}
                  className="file-attachment cursor-pointer ml-2"
                  src={attachmentIcon}
                />
                {/* <i className="fa fa-upload file-attachment" ></i> */}
              </label>
              <input
                type="file"
                id="img"
                accept="image/*"
                onChange={(e: any) => setImg(e.target.files[0])}
                style={{ display: "none" }}
              />
              <button
                onClick={handleSubmit}
                style={styles.sendbtn}
                className="send-btn ml-2 cursor-pointer"
              >
                Send <img src={sendBtn} width="25px" />
              </button>
            </div>
            <div className="col-xs-12 col-md-12">
              <span>{img ? img.name : ""}</span>
            </div>
          </>
        ) : (
          ""
        )
      }
    </>
  );
};

export default Messages;
