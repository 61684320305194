<div fxLayout fxLayout="column">
    <div fxLayout fxLayout="row" fxLayoutAlign="start" fxFlexOffset="20px" fxFlexOffset.xs="0px" fxLayoutGap="30px"
        fxLayoutGap.xs="10px">
        <div class="header-text font-family-NB v-m ml-5 mb-3" fxLayout fxLayoutAlign="start">
            {{ article ? 'Update Article' : 'Add Article'}}
        </div>
    </div>
    <mat-card class="example-container  ml-5 mr-5 p-5 mb-5" fxLayout fxLayout="column" fxLayoutAlign="center">
        <form [formGroup]="articleForm" fxLayout fxLayout="column" fxLayoutAlign="center" fxLayoutAlign.xs="start"
            fxFlex="100%" fxLayoutGap="30px" fxLayoutGap.xs="0px">
            <mat-card-content fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start" fxLayout.sm="row"
                fxFlex="60%" fxFlex.xs="100%" class="font-family-NSB" fxLayoutGap="30px" fxLayoutGap.xs="0px">
                <div class="myfilebrowser" fxFlex.xs="100%" fxLayout="column">
                    <mat-label class="font-family-NB mb-3">Upload Photo</mat-label>
                    <input type="file" #uploadFile id="fileUpload" (change)="uploadFileChange($event)" name="fileUpload"
                        accept="image/*" />

                    <span fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="start">
                        <img [src]="documents?.trim() == '' ? AssetsURL+'/img/upload_file.png' : documents"
                             fxLayout="row"
                            fxLayoutGap="20px" class="rounded-15px" width="100px" height="100px" />
                        <span fxLayout="column" fxLayout.xs="column" fxLayout.sm="column" fxFlex="30"
                            fxLayoutGap.xs="10px">
                            <div class="filename font-family-NSB word-wrap ml-2" fxLayout="row" fxLayoutGap="10px">
                                {{myfilename}}</div>
                            <button type="button" (click)="handleClick()" mat-button
                                class="btn btn-sm radius-50 btn-yolow h-40 text-center mt-2">Upload Photo</button>
                        </span>
                    </span>
                    <mat-error class="font-family-NSB font-75" *ngIf="isArticle && documents?.trim() == ''">
                        Please upload atleast one Image.
                    </mat-error>
                </div>
                <!-- fxFlexOffset.xs="5px" fxFlexOffset.sm="5px" fxFlexOffset="40px" -->
                <div fxLayout fxLayout="column" fxFlex="50%" fxFlex.xs="100%" fxLayoutAlign.xs="start"
                    fxFlexOffset.xs="10px" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">
                    <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="35px"
                        fxLayoutAlign.xs="start" fxLayoutGap.xs="0px">
                        <mat-form-field appearance="outline">
                            <mat-label class="font-family-NB">Article Source</mat-label>
                            <input matInput formControlName="source" pattern="^[a-zA-Z0-9 ]*$" class=" form-control"
                                placeholder="Article Source" type="text">
                            <mat-error class="font-family-NSB" *ngIf="errorHandling('source', 'required')">
                                Article Source is Required.
                            </mat-error>
                            <mat-error class="font-family-NSB" *ngIf="errorHandling('source', 'pattern')">
                                Only allowed alphaNumeric.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="font-family-NB">Title of Article</mat-label>
                            <input matInput formControlName="title" pattern="^[a-zA-Z0-9 ]*$" class=" form-control"
                                placeholder="Title" type="text">
                            <mat-error class="font-family-NSB" *ngIf="errorHandling('title', 'required')">
                                Article Title is Required.
                            </mat-error>
                            <mat-error class="font-family-NSB" *ngIf="errorHandling('title', 'pattern')">
                                Only allowed alphaNumeric.
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="35px"
                        fxLayoutAlign.xs="start" fxLayoutGap.xs="0px">
                        <mat-form-field appearance="outline">
                            <mat-label class="font-family-NB">Article Link</mat-label>
                            <input matInput formControlName="link" class=" form-control" placeholder="Article Link"
                                type="url">
                            <mat-error class="font-family-NSB" *ngIf="errorHandling('link', 'required')">
                                Article Link is Required.
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                            <mat-label class="font-family-NB">Date of Article</mat-label>
                            <input matInput [matDatepicker]="dateOfArticle" formControlName="dateOfArticle"
                                placeholder="Date Of Article">
                            <mat-datepicker-toggle matSuffix [for]="dateOfArticle"></mat-datepicker-toggle>
                            <mat-datepicker #dateOfArticle></mat-datepicker>
                            <mat-error class="font-family-NSB" *ngIf="errorHandling('dateOfArticle', 'required')">
                                Date of Article is Required.
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="10px"
                        fxLayoutAlign.xs="start" fxLayoutGap.xs="0px">
                        <mat-form-field appearance="outline">
                            <mat-label class="font-family-NB">Article Description</mat-label>
                            <textarea fxLayout="row" fxFlex="100" fxFlexFill matInput formControlName="description"
                                class=" form-control" placeholder="description"></textarea>
                            <mat-error class="font-family-NSB" *ngIf="errorHandling('description', 'required')">
                                Article Description is Required.
                            </mat-error>
                        </mat-form-field>

                    </div>
                    <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutGap="10px"
                        fxLayoutAlign.xs="start" fxLayoutGap.xs="0px">
                        <mat-radio-group formControlName="status">
                            <mat-radio-button value="pending">Pending</mat-radio-button>
                            <mat-radio-button class="ml-2" value="active">Active</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions class="font-family-NSB " fxLayout fxLayoutAlign="start" fxFlex="50%" fxFlex.xs="100%">
                <!-- fxLayoutAlign="start" fxFlexOffset="168px"
                 fxLayoutGap.xs="5px" fxFlexOffset.xs="0px" -->
                <button mat-button type="submit" class="btn btn-yolow btn-md radius-50 font-family-NSB"
                    (click)="submit()">
                    <span [ngStyle]="{'display':!isLoading ? 'block': 'none'}">{{ article ? 'Update' : 'Add'}}</span>
                    <span [ngStyle]="{'display': isLoading ? 'block': 'none'}">
                        <mat-spinner style="margin:5px auto;" [diameter]="30">
                        </mat-spinner>
                    </span>
                </button>
                <button mat-button type="button" class="btn btn-outline-yellow btn-md radius-50 font-family-NSB"
                    (click)="!isLoading && back()">Cancel</button>
            </mat-card-actions>
        </form>
    </mat-card>
</div>
