
import { MaterialModule } from './../../material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { PageLoaderComponent } from './page-loader/page-loader.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FlexLayoutModule } from "@angular/flex-layout";
import { SidebarComponent } from './sidebar/sidebar.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import {LoadingScreenModule} from "./loading-screen/loading-screen.module";
import { SendEmailDialogComponent } from './send-email-dialog/send-email.component';
import { SendStatusDialogComponent } from './send-status-dialog/send-status.component';
import { SaveChangesComponent } from './changes-save-dialog/save-dialog.component';
import { TimeAgoPipe } from './header/time-ago.pipe';

// import { AlertComponent } from './alert/alert.component';
@NgModule({
  declarations: [
    // PageLoaderComponent,
    HeaderComponent,
    FooterComponent,
    TimeAgoPipe,
    SidebarComponent,
    DeleteDialogComponent,
    FilterDialogComponent,
    SendEmailDialogComponent,
    SendStatusDialogComponent,
    SaveChangesComponent
    // AlertComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    LoadingScreenModule,

  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DeleteDialogComponent,
    FilterDialogComponent,
    LoadingScreenModule,
    SendEmailDialogComponent,
    SendStatusDialogComponent,
    SaveChangesComponent
    // AlertComponent
  ]
})
export class SharedModule { }
