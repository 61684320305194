<div class="h-100 d-flex justify-content-center align-items-center confirmation-container" fxLayout fxLayout.lg="row"
    fxLayout.md="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px" fxLayoutGap.xs="20px">
    <div class="text-center justify-container " fxLayout fxLayout="column" fxLayoutGap="30px" fxLayoutGap.xs="20px"
        fxLayout.xs="column" fxFlex="100%" fxFlex.sm="100%" fxFlex.xs="100%">
        <div class="font-family-NB font-1x">Welcome</div>
        <!-- fxLayout="column" fxLayoutAlign="center" fxFlex.xs="60%" fxLayoutGap.xs="10px"
            fxFlex.sm="60%" fxLayoutGap.sm="10px" -->
        <div><img [src]="AssetsURL+'/img/confirmation_logo.png'" width="250px" height="80px" alt="yolow logo image">
        </div>
        <div><img [src]="AssetsURL+'/img/confirmation_enrollment.png'" width="350px" height="250px"
                alt="confirmation of enrollment image"></div>
        <!-- <div fxLayout="column" fxLayoutAlign="center" class="confirmation_enrollment">

        </div>-->

        <p class="font-family-NB fw-900 mr-auto">Thank you for choosing us for your business extension.</p>
        <p class="font-family-NB font-2x" *ngIf="typeOfEnrollment == 'Agent'">
            Your profile is under review.<br /> You will be able to sell insurances once your profile is approved</p>
        <p class="font-family-NB font-2x mr-auto" *ngIf="typeOfEnrollment == 'Vender'">
            Your profile is under review.<br /> You will be able to vend once your profile is approved</p>
        <button class="btn btn-primary btn-lg rounded bg-black" type="submit" mat-button (click)="back()">
            <span>Back to Login <mat-icon>
                    arrow_right_alt</mat-icon></span>
        </button>
    </div>
</div>
