import { UserInterface } from '../interfaces/user.interface';
import { UserDataService } from './user-data.service';
import { Injectable } from '@angular/core';
import * as aws4 from 'aws4'
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class APIWrapperService {
    user: UserInterface | undefined;
    security: {
        accessKeyId: string;
        secretAccessKey: string;
        sessionToken: string;
    } | undefined;

    service = 'execute-api';
    region = 'us-east-2';

    constructor(private http: HttpClient, private userDataService: UserDataService) {
    }

    getUserTokens = () => {
        this.user = this.userDataService.getUserData();
        this.security = {
            accessKeyId: this.user != undefined ? this.user._accessKey : '',
            secretAccessKey: this.user != undefined ? this.user._secretAccessKey : '',
            sessionToken: this.user != undefined ? this.user._sessionToken : ''
        };
    }

    callGetAPI = (host: any, path: any) => {
        this.getUserTokens();
        var opts = {
            host: host,
            service: this.service,
            region: this.region,
            method: 'GET',
            path: path,
        }

        const signature: any = aws4.sign(opts, this.security);
        delete signature.headers['Host'];
        const headers = new HttpHeaders(signature.headers);
        try {
            return this.http.get(
                'https://' + host + path,
                { 'headers': headers }
            )
                .pipe(
                    map((response: any) => { return response })
                    // ,catchError()
                );
        } catch (err) {
            return null;
        }
    }

    callPostAPI = (host: any, path: any, payLoad: any, header?: any) => {
        this.getUserTokens();
        var opts = {
            host: host,
            service: this.service,
            region: this.region,
            method: 'POST',
            path: path,
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(payLoad)
        }

        const signature: any = aws4.sign(opts, this.security);
        delete signature.headers.host;
        signature.headers = { ...signature.headers, ...header };
        const headers = new HttpHeaders(signature.headers);
        try {
            return this.http.post(
                'https://' + host + path,
                signature.body,
                // { 'headers': headers }
            )
                .pipe(
                    map((response: any) => { return response }),

                );
        } catch (err) {
            return null;
        }
    }

    callFcmAPI = (host: any, payLoad: any, header?: any) => {

        const headers = new HttpHeaders(header);

        try {
            return this.http.post(
                host,
                payLoad,
                { 'headers': headers }
            )
                .pipe(
                    map((response: any) => { return response }),

                );
        } catch (err) {
            return null;
        }
    }
}
