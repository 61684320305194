<div class="row ml-1 mr-1">
  <div class="col-md-6 pdf-viewer">
    <div fxLayout fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px">
      <mat-form-field *ngIf="(pdfSrc != '' || images.length > 0) && !isLoading" appearance="outline">
        <mat-label>Insurance</mat-label>
        <mat-select [formControl]="insurancePDFselector" (selectionChange)="insurancePDFChange()">
          <mat-option *ngFor="let pdf of PDFList;let i=index;" [value]="i">{{pdf.type}} {{i+1}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="h-40" fxLayoutGap="5px">
        <button *ngIf="(pdfSrc != '' || images.length > 0) && !isLoading" mat-icon-button
          (click)="actionChanges('delete')" title="Delete" class="btn btn-outline-dark btn-sm ">
          <mat-icon>delete_outline</mat-icon>
        </button>
        <button mat-icon-button (click)="actionChanges('add')" title="Add" class="btn btn-outline-dark btn-sm">
          <mat-icon>add</mat-icon>
        </button>
      </div>
      <div fxLayoutAlign="end" fxLayoutGap="5px" *ngIf="pdfSrc != '' && !isLoading ">
        <button mat-icon-button (click)="incrementZoom(0.1)" title="Edit" class="btn btn-outline-dark btn-sm ">
          <mat-icon>zoom_in</mat-icon>
        </button>
        <button mat-icon-button (click)="incrementZoom(-0.1)" title="Edit" class="btn btn-outline-dark btn-sm">
          <mat-icon>zoom_out</mat-icon>
        </button>
      </div>
    </div>
    <div fxLayout fxLayout="column">
      <div fxLayout fxLayoutAlign="center" fxLayoutGap="30px" *ngIf="isLoading">
        <mat-spinner style="margin:0 auto;" *ngIf="isLoading" [diameter]="30"></mat-spinner>
      </div>
      <span *ngIf="images.length > 0 && !isLoading">
        <ngx-image-viewer [src]="images" (customEvent)="handleEvent($event)">
        </ngx-image-viewer>
      </span>
      <span *ngIf="pdfSrc != '' && !isLoading">
        <pdf-viewer [src]="pdfSrc" [autoresize]="true" [fit-to-page]="true" [rotation]="0" [stick-to-page]="false"
          [show-all]="true" [zoom]="zoom" [external-link-target]="'blank'" [zoom-scale]="'page-fit'"
          [render-text]="true" style="display: block;"></pdf-viewer>
      </span>
    </div>
  </div>

  <div class="col-md-6 insuranceForm font-family-NSB">
    <span *ngIf="isLoadingUser">
      <mat-spinner style="margin:50px auto;" *ngIf="isLoadingUser" [diameter]="30"></mat-spinner>
    </span>
    <form [formGroup]="formGroup" *ngIf="!isLoadingUser" style="margin-bottom: 20vh;">
      <div class="row">
        <div class="col-md-6 align-items-center d-flex">
          <h5 class="font-family-NB mt-1">User Information</h5>
        </div>
        <div class="col-md-6 text-right">
          <!-- <button type="submit" class="btn btn-black radius-50 mr-2" (click)="!isLoadingUser && submit('basic')">
            As Basic
          </button> -->
          <button type="submit" class="btn btn-yolow radius-50 ml-2 mr-2" (click)="!isLoadingUser && submit()"> Submit
          </button>
          <button type="button" class="btn btn-outline-yellow radius-50 ml-2" (click)="back()">
            Cancel
          </button>
        </div>
      </div>

      <div class="mt-3 card">
        <div class="card-body ">
          <div class="col-md-12 m-2">
            <mat-label><b class="font-family-NB fn-medium">Name:</b></mat-label>&nbsp;
            <span class="font-family-NSB">{{insuranceSummary.name}}</span>
          </div>
          <div class="col-md-12 m-2">
            <mat-label><b class="font-family-NB fn-medium">Email:</b></mat-label>&nbsp;
            <span class="font-family-NSB">{{insuranceSummary.email}}</span>
          </div>
          <div class="col-md-12 m-2">
            <mat-label><b class="font-family-NB fn-medium">Phone:</b></mat-label>&nbsp;
            <span class="font-family-NSB">{{insuranceSummary.phoneNumber}}</span>
          </div>
        </div>
      </div>
      <div class="mt-3 mb-3 card">
        <div class="card-body">
          <div class="col-md-12 m-2">
            <mat-label><b class="font-family-NB fn-medium">Insurance Type:</b></mat-label>&nbsp;
            <span class="font-family-NSB">{{getInsuranceType(insuranceSummary.insuranceType)}}</span>
          </div>
          <div class="col-md-12 m-2 d-none">
            <mat-label><b class="font-family-NB fn-medium">Insurance Status:</b></mat-label>&nbsp;
            <span [ngClass]="insuranceSummary.status == 'basic' ? 'text-warning' : 
          insuranceSummary.status == 'complete' ? 'text-success' : 'text-danger'">
              {{insuranceSummary.status == 'basic' ? 'Basic' : insuranceSummary.status == 'complete' ? 'Complete' :
              'Pending'}}
            </span>
          </div>
          <div class="col-md-12 m-2">
            <mat-form-field appearance="fill">
              <mat-label>Insurance Status</mat-label>
              <mat-select [(value)]="insuranceStatus">
                <mat-option value="pending">Pending</mat-option>
                <mat-option value="basic">Basic</mat-option>
                <mat-option value="complete">Complete</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-md-12 m-2">
            <mat-label><b class="font-family-NB fn-medium">Insurance Created:</b></mat-label>&nbsp;
            <span class="font-family-NSB">{{getCreate(insuranceSummary.createdDate)}}</span>
          </div>
        </div>
      </div>
      <mat-accordion class="mb-3 card" fxLayoutGap="10px">
        <mat-expansion-panel (opened)="basicInsuranceState = true" open (closed)="basicInsuranceState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <!-- <div class="row mt-5">
                <div class="col-md-6 align-items-center d-flex"> -->
              <h5 class="font-family-NB">Basic Insurance Details</h5>
              <!-- </div>
              </div> -->
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mt-3 card">
            <div class="card-body">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Policy No.</mat-label>
                <input matInput formControlName="policynumber" placeholder="Enter Policy No.">
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Total Premium</mat-label>
                <input matInput formControlName="totalpremium" placeholder="Enter Total Premium">
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Policy Provider</mat-label>
                <input matInput formControlName="policyprovider" placeholder="Enter Policy Provider">
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Party Insured</mat-label>
                <textarea matInput formControlName="partyinsured" placeholder="Enter Party Insured"></textarea>
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Effective Date</mat-label>
                <input matInput [matDatepicker]="effectivedate" formControlName="effectivedate"
                  placeholder="Enter Effective Date">
                <mat-datepicker-toggle matSuffix [for]="effectivedate"></mat-datepicker-toggle>
                <mat-datepicker #effectivedate></mat-datepicker>
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Expiration Date</mat-label>
                <input matInput [matDatepicker]="expirationdate" formControlName="expirationdate"
                  placeholder="Enter Expiration Date">
                <mat-datepicker-toggle matSuffix [for]="expirationdate"></mat-datepicker-toggle>
                <mat-datepicker #expirationdate></mat-datepicker>
              </mat-form-field>

            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="mb-3 card" fxLayoutGap="10px">
        <mat-expansion-panel (opened)="insuranceProviderState = true" open (closed)="insuranceProviderState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <!-- <div class="row mt-5">
                <div class="col-md-6 align-items-center d-flex"> -->
              <h5 class="font-family-NB">Insurance Provider Details</h5>
              <!-- </div>
              </div> -->
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mt-3 card">
            <div class="card-body">
              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Policy Provider Contact Number</mat-label>
                <input matInput formControlName="policyprovidercontactnumber"
                  placeholder="Enter Policy Provider Contact Number">
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Policy Provider Email</mat-label>
                <input matInput formControlName="policyprovideremail" placeholder="Enter Policy Provider Email">
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Agent Name</mat-label>
                <input matInput formControlName="agentname" placeholder="Enter Agent Name">
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Agent Contact Number</mat-label>
                <input matInput formControlName="agentcontactnumber" placeholder="Enter Agent Contact Number">
              </mat-form-field>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Agent Email</mat-label>
                <input matInput formControlName="agentemail" placeholder="Enter Agent Email">
              </mat-form-field>

            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <mat-accordion class="mb-3 card" fxLayoutGap="10px">
        <mat-expansion-panel (opened)="insuranceEntitiesConverageState = true" open
          (closed)="insuranceEntitiesConverageState = false">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <!-- <div class="row mt-5 mb-2"> -->
              <div class=" align-items-center d-flex">
                <h5 class="font-family-NB">Insured Entities and Coverages</h5>
              </div>
              <!-- <div class="col-md-6 text-right">
                <button type="button" class="btn btn-sm btn-yolow" (click)="addCoverage({})">
                  <mat-icon>add</mat-icon> Add
                </button>
              </div> -->
              <!-- </div> -->
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="col-md-12 mb-2 text-right">
            <button type="button" class="btn btn-sm btn-yolow" (click)="addCoverage({})">
              <mat-icon>add</mat-icon> Add
            </button>
          </div>
          <div formArrayName="coverages" class="card mt-1 mb-2" *ngFor="let other of getCoverages(); let c = index">
            <div [formGroupName]="c" class="card-body ">
              <div class="row">
                <div class="col-md-6">
                  <span class="card-title font-weight-bold">Insured Entity</span>
                </div>
                <div class="col-md-6 text-right">
                  <button mat-icon-button color="warn" [disableRipple]="true" type="button" class="btn btn-sm b-15px"
                    (click)="removeCoverage(c)">
                    <mat-icon class="delete_icon">close</mat-icon>
                  </button>
                </div>
              </div>

              <mat-form-field appearance="outline" class="col-md-6">
                <mat-label>Item Name</mat-label>
                <input matInput formControlName="item_name" placeholder="Item Name">
              </mat-form-field>
              <!-- <div #scrollInsuredItem [scrollTop]="scrollInsuredItem.scrollHeight" > -->
              <div class="col-md-12 mb-3">
                <div class="text-right mb-2">
                  <button type="button" class="btn btn-yolow btn-sm" (click)="addEntity(c, {})">
                    <mat-icon>add</mat-icon> Add Insured Item
                  </button>
                </div>
                <mat-accordion class="mb-3 card">
                  <mat-expansion-panel (opened)="insuranceEntitiesState = true" open
                    (closed)="insuranceEntitiesState = false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <!-- <div class="row mt-5 mb-2"> -->
                        <div class=" align-items-center d-flex">
                          <h5 class="font-family-NB">Insured Entities Details</h5>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row" formArrayName="entity" *ngFor="let other of getEntityControls(c); let e = index">
                      <div class="col-md-12" [formGroupName]="e">
                        <div class="row">
                          <div class="col-md-6">
                            <span class="card-title font-weight-bold">Insured Entity Details {{e + 1}}</span>
                          </div>
                          <div class="col-md-6 text-right">
                            <button mat-icon-button color="warn" type="button" [disableRipple]="true"
                              class="btn btn-sm b-15px" (click)="removeInsuredItem(c, e)">
                              <mat-icon class="delete_icon">close</mat-icon>
                            </button>
                          </div>
                        </div>

                        <mat-form-field appearance="outline" class="col-md-6">
                          <mat-label>Item Title</mat-label>
                          <input formControlName="item_title" matInput>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-md-6">
                          <mat-label>Item Value</mat-label>
                          <input formControlName="item_value" matInput>
                        </mat-form-field>
                      </div>
                    </div>

                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <!-- </div> -->

              <!-- <div #scrollCoverageItem [scrollTop]="scrollCoverageItem.scrollHeight"> -->
              <div class="col-md-12 mb-3">
                <div class="text-right mb-2">
                  <button type="button" class="btn  btn-yolow btn-sm"
                    (click)="addCoverageDetails(c, {coverage_enabled: true})">
                    <mat-icon>add</mat-icon> Add Coverage Details
                  </button>
                </div>
                <mat-accordion class="mb-3 card">
                  <mat-expansion-panel (opened)="insuranceConverageState = true" open
                    (closed)="insuranceConverageState = false">
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <!-- <div class="row mt-5 mb-2"> -->
                        <div class=" align-items-center d-flex">
                          <h5 class="font-family-NB">Coverage Details</h5>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="row card mt-3" formArrayName="coveragedetails"
                      *ngFor="let coveragedetails of getCoverageDetailsControls(c); let i = index">
                      <div class="col-md-12" [formGroupName]="i">
                        <div class="row mt-3">
                          <div class="col-md-6">
                            <span class="card-title font-weight-bold">Coverage Detail {{i + 1}}</span>
                          </div>
                          <div class="col-md-6 text-right">
                            <button mat-icon-button color="warn" type="button" [disableRipple]="true"
                              class="btn btn-sm b-15px" (click)="removeCoverageDetails(c, i)">
                              <mat-icon class="delete_icon">close</mat-icon>
                            </button>
                          </div>
                        </div>

                        <mat-form-field appearance="outline" class="col-md-12">
                          <mat-label>Coverage Icon</mat-label>
                          <input formControlName="coverage_icon" matInput />
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-12">
                          <mat-label>Coverage Disabled Icon</mat-label>
                          <input formControlName="coverage_icon_disabled" matInput />
                        </mat-form-field>

                        <mat-form-field appearance="outline" class="col-md-12">
                          <mat-label>Coverage Name</mat-label>
                          <input formControlName="coverage_name" matInput placeholder="Coverage Name" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-md-12">
                          <mat-label>Coverage Details</mat-label>
                          <textarea rows="5" formControlName="coverage_details" matInput
                            placeholder="Coverage Details"></textarea>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-md-12">
                          <mat-label>Coverage Deductible</mat-label>
                          <input formControlName="coverage_deductible" matInput placeholder="Coverage Deductible" />
                        </mat-form-field>

                        <mat-checkbox class="col-md-12" matInput formControlName="coverage_enabled">Coverage Enabled
                        </mat-checkbox>

                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </form>
  </div>
</div>