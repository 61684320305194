// @font-face {
//   fontFamily: 'NBK',
//   src: url('./public/fonts/Nunito-Black.ttf'),
//   fontWeight: normal,
//   font-style: normal,
// }

// @font-face {
//   fontFamily: 'NI',
//   src: url('./public/fonts/Nunito-Italic.ttf'),
//   fontWeight: normal,
//   font-style: italic,
// }

// @font-face {
//   fontFamily: 'NL',
//   src: url('./public/fonts/Nunito-Light.ttf'),
//   fontWeight: normal,
//   font-style: normal,
// }
// @font-face {
//   fontFamily: 'NBK',
//   src: url('./public/fonts/Nunito-Black.ttf'),
//   fontWeight: normal,
//   font-style: normal,
// }

// @font-face {
//   fontFamily: 'NI',
//   src: url('./public/fonts/Nunito-Italic.ttf'),
//   fontWeight: normal,
//   font-style: italic,
// }

// @font-face {
//   fontFamily: 'NL',
//   src: url('./public/fonts/Nunito-Light.ttf'),
//   fontWeight: normal,
//   font-style: normal,
// }

export const styles = {
  App: {
    marginTop: "20px",
    fontFamily: "NI",
  },

  hidden: {
    display: "none",
  },

  usertype: {
    flexGrow: 1,
    flexBasis: 0,
    padding: "5px",
    color: "#123",
    cursor: "pointer",
    background: 'white'
  },

  usertypeactive: {
    flexGrow: 1,
    flexBasis: 0,
    padding: "5px",
    color: "#000",
    backgroundColor: "#ffb600",
  },

  pendingChat: {
    backgroundColor: '#007bff30 !important',
    border: '2px solid #007bff !important'
  },

  acceptedbutton: {
    backgroundColor: "green",
  },

  resolvedbtntext: {
    textAlign: "center",
  },

  useracceptbutton: {
    padding: "10px 0 0 0",
    margin: "0 0 10px 10px",
  },

  chatheaderblank: {
    backgroundColor: "rgb(250 250 250)",
    color: "rgb(196 196 196)",
    fontFamily: 'NSB',
    display: "flex",
  },

  userheadertitle: {
    padding: "10px 0 0 0",
    margin: "0 0 10px 10px",
    fontWeight: "600",
    fontSize: "20PX",
  },

  chatcontainer: {
    backgroundColor: "#F9F9F9",
    overflowY: "scroll",
    overflowX: "hidden",
    height: "100vh",
    border: "#000",
  },

  message: {
    backgroundColor: "aqua",
  },

  leftside: {
    backgroundColor: "#FFE7AC",
    color: "#000",
    maxWidth: "auto",
    fontWeight: "500",
    maxWidth: "max-content",
    marginTop: "10px",
    padding: "5px 30px",
    borderRadius: "30px 30px 30px 0px",
  },

  // leftside span : {
  //   marginLeft: "20px",
  //   fontSize: "12px",
  //   color: "#fff",
  // }

  rightside: {
    /*display: flex,*/
    float: "right",
    margin: "25px 5PX",
    alignItems: "flex-end",
    flexdirection: "row-reverse",
    textAlign: "justify",
    maxWidth: "max-content",
    backgroundColor: " #E6E6E6",
    color: "#000",
    fontWeight: "500",
    padding: "5px 30px",
    borderRadius: "30px 30px 0px 30px",
  },

  // rightside span {
  //   marginLeft: "20px",
  //   fontSize: "12px",
  //   color: "rgb(97 16 23)",
  // }

  messagediv: {
    display: "block",
    marginBottom: "10px",
    "text-align": "center"
  },

  messageinput: {
    backgroundColor: "#f9f9f9",
    height: "50px",
    minWidth: "65%",
    maxWidth: "80%",
    bordertopStyle: "hidden",
    borderrightStyle: "hidden",
    borderleftStyle: "hidden",
    borderbottomStyle: "hidden",
    borderRadius : "15px"
  },

  // message-input:focus {
  //   outline: none,
  // }

  // message-input::placeholder {
  //   fontWeight: bold,
  // }

  /* .send-attachment{
    height: 56px,
    width: 20px,
  } */
  sendbtn: {
    backgroundColor: " #ffb600",
    outline: "none",
    borderRadius: "25px 25px 25px 25px",
    width: "120px",
    height: "50px",
    border: "none",
  },

  sendbtnimg: {
    height: "25px",
    width: "25px",
  },

  lastmessage: {
    /* backgroundColor: #20232a,
    color: #fff, */
    fontWeight: "500",
    fontFamily:
      "'Franklin Gothic Medium', 'Arial Narrow', 'Arial', 'sans-serif'",
    marginLeft: "10px",
    padding: "5px",
    borderRadius: "25px",
    color: "#848484",
  },

  supportmessage: {
    float: "right",
  },

  usermsgs: {
    float: "left",
    display: "flex",
  },

  lefttimestamp: {
    color: "#000",
  },

  righttimestamp: {
    display: "contents",
  },

  timestamp: {
    /* //float:right, */
    display: "flex",
    flexdirection: "column",
    fontWeight: "300",
    fontFamily:
      "'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif",
    fontSize: "small",
    margin: "0 5px",
    color: "#000",
  },

  listuser: {
    backgroundColor: "#ffb600 !important",
    color: "#000",
  },

  // listgroupitemactive: {

  //   color: "#000 !important",
  // }

  // list-group-item:hover {
  //   cursor: pointer,
  //   backgroundColor: #cfcfcf,
  //   fontWeight: 800,
  //   text-transform: capitalize,

  // }

  profileImage: {
    fontFamily: "Arial, Helvetica, sans-serif",
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    background: " #ffb600",
    fontSize: "1rem",
    color: "#000",
    textalign: "center !important",
    lineHeight: "3rem",
    display: "inline-table",
    margin: "0px",
    marginRight: "5px",
  },

  // listgroupitemactive .profileImage {
  //   fontFamily: Arial, Helvetica, sans-serif,
  //   width: 3rem,
  //   height: 3rem,
  //   borderRadius: 50%,
  //   background: #fff,
  //   fontSize: 1rem,
  //   color: #000,
  //   textAlign: center,
  //   lineHeight: 3rem,
  //   display: inline-table,
  //   margin: 0px,
  //   marginRight: 5px,
  // }

  userprofilepic: {
    fontFamily: "Arial, Helvetica, sans-serif",
    width: "3rem",
    height: "3rem",
    borderRadius: "50%",
    background: "#ffb600",
    fontSize: "1rem",
    color: "#000",
    textAlign: "center",
    lineHeight: "3rem",
    display: "inline-table",
    margin: "0px",
    marginRight: "5px",
  },

  profilepic: {
    fontfamily: "Arial, Helvetica, sans-serif",
    width: "3rem",
    float: "right",
    height: "3rem",
    borderRadius: "50%",
    background: "#000",
    fontSize: "1rem",
    color: "#ffb600",
    textAlign: "center",
    lineHeight: "3rem",
    display: "inline-table",
    marginTop: "15px",
    marginRight: "5px",
  },

  successcheck: {
    fontSize: "10rem !important",
    border: "1px solid #ffb600 !important",
    borderRadius: "50%",
  },

  fileattAchmentParent: {
    margin: "5px 0px 0px 0px"
  },

  fileattachment: {
    height: "40px",
    width: "40px",
  },

  resolvedbutton: {
    background: "#ffb600",
    color: "#123",
    borderRadius: "25px",
    border: "1px solid #fff",
    padding: "5px",
    marginTop: "5px",
  },

  markread: {
    width: "30px",
    height: "15px",
    marginLeft: "5px",
  },

  stack: {
    textAlign: "center",
    border: "1px solid #ffb600",
    padding: "0px",
    fontSize: "large",
    fontWeight: "500",
  },

  /* .user-name{
    fontWeight: bold,
  }*/
  chattitlename: {
    display: "flex",
  },

  myunreadcounter: {
    display: 'inline-block',
    padding: '5px 8px',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '1',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'baseline',
    borderRadius: '50%',
    position: "absolute",
    backgroundColor: '#ffb600',
    bottom: '8px',
    right: '10px'
  },

  listgroupitemactive: {
    backgroundColor: "#848484 !important",
    color: "#fff !important",
  },

  bottomicon: {
    position: "absolute",
    marginTop: "430px",
    alignItems: "end",
    marginLeft: "920px",
    border: "1px solid black",
    borderRadius: "30px",
  },

  scroll: {
    height: "590px",
    overflowY: "scroll",
  },
};

// scroll::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3),
//   backgroundColor: #F5F5F5,
// }

// scroll::-webkit-scrollbar {
//   width: 10px,
//   backgroundColor: #F5F5F5,
// }

// scroll::-webkit-scrollbar-thumb {
//   backgroundColor: #cfcfcf,

// }

// chat-container::-webkit-scrollbar-track {
//   -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.3),

// }

// chat-container::-webkit-scrollbar {
//   width: 10px,
//   /* backgroundColor: #cfcfcf, */
// }

// chat-container::-webkit-scrollbar-thumb {
//   backgroundColor: #848484,

// }
