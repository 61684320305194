<div class="ml-5 mr-5" fxLayout="column">
  <div fxLayout style="align-items: center !important;">
    <h5 class="card-title ml-1 font-family-NB mt-4" fxLayout fxFlex="97">Agent Leads</h5>
    <button mat-icon-button (click)="clearFilter()" *ngIf="!isLoading && isFilter"
      class="btn btn-yolow btn-clear-filter text-center mr-2" #tooltip="matTooltip" matTooltip="Clear Filter"
      matTooltipPosition="below" fxLayout fxFlex="8" fxLayoutGap="20">
      <mat-icon class="ml-2">clear</mat-icon>Clear Filter
    </button>
    <button mat-icon-button (click)="filterDialog()" *ngIf="!isLoading" class="btn btn-yolow btn-filter text-center"
      #tooltip="matTooltip" matTooltip="Filter list" matTooltipPosition="below" fxLayout fxLayoutGap="20">
      <mat-icon class="ml-2">filter_list</mat-icon>
    </button>
  </div>

  <div fxLayout fxLayoutAlign="center" fxLayoutGap="30px" *ngIf="isLoading">
    <mat-spinner style="margin:0 auto;" *ngIf="isLoading" [diameter]="30"></mat-spinner>
  </div>
  <div class="customer-container font-family-NSB mb-3" fxLayout fxLayout="column" fxLayoutAlign="start"
    fxLayoutGap="5px" fxLayoutGap.xs="0" *ngIf="insuranceTypes.length">
    <mat-card class="example-container mb-3" [ngClass]="!isLoading ? 'd-block':'d-none'" fxLayout fxLayout="column">
      <!-- mat-elevation-z8 -->
      <mat-card-content class="  font-family-NSB" fxLayout fxLayout="column">
        <div fxLayout fxLayout="column" *ngIf="!isLoading">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
            [matSortActive]="sortD.sortField" [matSortDirection]="sortD.sortOrder">

            <!-- Name Column -->
            <ng-container matColumnDef="agent_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="agent_name">Agent Name</th>
              <td mat-cell *matCellDef="let row">{{ row.agent_name }}</td>
            </ng-container>


            <!-- Email Column -->
            <ng-container matColumnDef="insurance_type">
              <th mat-header-cell *matHeaderCellDef>Insurance Type</th>
              <td mat-cell *matCellDef="let row">{{ getInsuranceType(row.insurance_type) }}</td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="customer_name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="customer_name">Customer Name</th>
              <td mat-cell *matCellDef="let row">{{ row.customer_name }}</td>
            </ng-container>

            <!-- Phone Number Column -->
            <ng-container matColumnDef="quotes">
              <th mat-header-cell *matHeaderCellDef>Provided Quotes</th>
              <td mat-cell *matCellDef="let row">{{ row.quotes }}</td>
            </ng-container>

            <!-- Active Policies Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let row" [ngClass]="row.status == 'purchased' ? 'text-warning' :
                row.status == 'closed' || row.status == 'purchased' ? 'text-danger' : (row.status == 'active' ?
                'text-success' : 'text-grey')">
                {{ row.status | titlecase}}
              </td>
            </ng-container>

            <!-- Insurance Create Column -->
           <ng-container matColumnDef="created_date">
             <th mat-header-cell *matHeaderCellDef mat-sort-header="created_date">Created</th>
             <td mat-cell *matCellDef="let row">{{ getCreate(row.created_date) }}</td>
           </ng-container>


            <!-- Action Column -->
            <ng-container matColumnDef="action" >
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row" >
                <span class="d-flex">
                <button (click)="showCustomerDetails(row)" title="Details" class="btn btn-outline-dark btn-sm">
                  <mat-icon class="btn-edit material-icons-outlined">description</mat-icon>
                </button>
                </span>
              </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td [colSpan]="displayedColumns.length" class="mat-cell text-center">No data found</td>
            </tr>

            <tr class="font-family-NB" mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr class="font-family-NSB" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator [ngClass]="!isLoading && customers.length > 0 ? 'd-block':'d-none'" class=" font-family-NB ml-max"
          fxLayout fxLayoutAlign="end" fxFlex="100%" fxLayoutGap.xs="0px" fxLayoutGap="10px" (page)="pageChanged($event)"
          [length]="totalRecords" [pageSize]="page.limit" [pageSizeOptions]="[ 10, 25, 50, 100]">
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
