import { SharedModule } from './main/shared/shared.module';
import { MainModule } from './main/main.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { ServiceWorkerModule } from '@angular/service-worker';

import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';

// import { ChatAppModule } from './main/chat-app/chat-app.module';
import { AppRoutingModule } from './app-routing.module';

import { environment } from './environments/environment';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ImageViewerModule } from "ngx-image-viewer";

//angular material import module
import { MaterialModule } from './material.module';

// import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';

import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
// import { FooterTextComponent } from './shared/footer-text/footer-text.component';

import { AuthGuard } from './auth-guard.service';

import {DatePipe} from '@angular/common';

import { AuthInterceptorService } from './interceptors/auth-interceptor.service';

import { AppsyncService } from './services/appsync.service';

import { CryptoService } from './services/crypto.service';

import { ArticleService } from './services/article.service';

import { GlobalErrorHandler } from './global-error-handler';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { InsuranceService } from './services/insurance.service';
import { AppState } from './services/app.state';
import { FlexLayoutModule } from "@angular/flex-layout";

// import { ToastrModule } from "ngx-toastr";

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';

import { EnrollmentComponent } from './enrollment/enrollment.component';
import { ConfirmationEnrollmentComponent } from './confirmation-enrollment/confirmation-enrollment.component';
import { InsuranceDataService } from './services/insurance-data.service';
import { UserDataService } from './services/user-data.service';
import { CommonService } from './services/common.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { TermsComponent } from './terms/terms.component';
import { PolicyComponent } from './policy/policy.component';
import { FeedbackService } from './services/feedback.service';
import {FirebaseOptions} from "firebase/app";
import {EncryptDecryptInterceptor} from "./interceptors/encrypt.decrypt.interceptor";
import {ToastrModule} from "ngx-toastr";
import {LoadingScreenInterceptor} from "./interceptors/loading.interceptor";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EnrollmentComponent,
    ConfirmationEnrollmentComponent,
    // ForgotPasswordComponent,
    PageNotFoundComponent,
    TermsComponent,
    PolicyComponent,
    // FooterTextComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    // MainModule,
    SharedModule,
    NgbModule,
    FlexLayoutModule,
    MaterialModule,
    // ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    PdfViewerModule,
    ToastrModule.forRoot(),
    ImageViewerModule.forRoot({
      btnClass: 'btn btn-md btn-yolow-image mb-3', // The CSS class(es) that will apply to the buttons
      zoomFactor: 0.1, // The amount that the scale will be increased by
      containerBackgroundColor: 'transparent', // The color to use for the background. This can provided in hex, or rgb(a).
      wheelZoom: false, // If true, the mouse wheel can be used to zoom in
      allowFullscreen: false, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
      allowKeyboardNavigation: false, // If true, the left / right arrow keys can be used for navigation
      btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
        zoomIn: 'fa fa-plus',
        zoomOut: 'fa fa-minus'
      },
      btnShow: {
        zoomIn: true,
        zoomOut: true
      }
    }),
    AngularFireModule.initializeApp(environment.firebase as FirebaseOptions),
  ],
  providers: [
    HttpClient,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EncryptDecryptInterceptor,
      multi: true
    },
    DatePipe,
    AuthGuard,
    AppsyncService,
    CryptoService,
    ArticleService,
    InsuranceService,
    InsuranceDataService,
    FeedbackService,
    UserDataService,
    NgxImageCompressService,
    CommonService,
    AppState,
    AngularFirestore,
    AngularFireStorageModule
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
