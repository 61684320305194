
export var serverCodes:{[key:string]: any} = {
  //Login Page
  // The user is confirmed but the registration details are not filled

  USER_UNREGISTERED: 500, //IMPLEMENTED - Sending email and name to registration component via Authentication Service

  // User is not found in the databse
  USER_NOT_FOUND: 102, //IMPLEMENTED - Not showing any text only directing to sign up screen
  USER_NOT_EXIST:254,

  // The User has Entered Incorrect Password
  INCORRECT_PASSWORD: 101, //IMPLEMENTED - Clearing the password field and making it invalid

  PASSWORD_RESET_IS_REQUIRED:105,

  NOTFOUND_REQUESTED_SERVICE_RESOURCES:104,

  USER_POOL_CONFIGURATION_IS_INVALID:107,

  INCORRECT_USERNAME_OR_PASSWORD:253, //108

  // The User has Entered Incorrect Parameter
  INCORRECT_PARAMETER: 106, //IMPLEMENTED - paassing Parameter invalid

  // The User is Not Confirmed
  USER_UNCONFIRMED: 103, 

  // The User is Registered Successfully
  USER_REGISTERED: 200, //IMPLEMENTED - Logic in Authentication Service

  //statusCode
  GetUsersDetailsForAgent_READ: 200,

  GetUsersDetailsForAgent_ERROR: 500,

  IS_AGENT_COGNITO_ID_EMPTY_OR_NULL: 110,

  USER_NOT_FOUND_PROVIDED_AGENT_COGNITO_ID:111,
  
  ANY_ERROR_MESSAGE:500

};