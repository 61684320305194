<app-header [isOpen]="isOpen" (onToggle)="onToggle($event)"></app-header>

<mat-sidenav-container fxFlexFill class="example-container">
    <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over" [opened]="isOpen" fxHide.gt-sm="true">
        <app-sidebar></app-sidebar>
    </mat-sidenav>
    <div class="p-2">
        <router-outlet></router-outlet>
    </div>
</mat-sidenav-container>
<!-- <app-footer></app-footer> -->