import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-send-status',
  templateUrl: './send-status.component.html',
  styleUrls: ['./send-status.component.css'],
})
export class SendStatusDialogComponent implements OnInit {
  action: string;
  dialogData: any;
  document: any;
  emailStatus: string;
  emails = [];

  constructor(
    public dialogRef: MatDialogRef<SendStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogData = { ...data };
    this.action = this.dialogData.action;
    this.emails = this.dialogData.emails;
    this.emailStatus = this.dialogData.status;
  }

  ngOnInit(): void {}

  delete() {
    this.dialogRef.close({ event: this.action });
  }
  cancel() {
    this.dialogRef.close();
  }
}
