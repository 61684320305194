<div class="example-container" fxLayout="column" fxFlex="100%">
    <mat-dialog-title fxLayout fxLayout="row" fxLayoutAlign="space-between">
        <div class="header-text font-family-NB v-m" fxLayout fxLayoutAlign="end">{{dialogData.header}}</div>
        <button mat-icon-button type="button" class="btn btn-sm" [disableRipple]="true" (click)="close()">
            <mat-icon class="delete_icon">close</mat-icon>
        </button>
    </mat-dialog-title>
    <form [formGroup]="(dialogData.type == 'customer_leads'|| dialogData.type == 'agent_leads') ? filterLeads : (dialogData.type == 'customer' ? filterCustomer : (dialogData.type == 'insurance' ? filterInsurance : filterArticle))" fxLayout fxLayout="column">
        <mat-dialog-content fxLayout fxLayout="column" fxFlex="100%" class="font-family-NSB p-4"
            *ngIf="dialogData.type == 'customer'">
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Name</mat-label>
                    <input matInput formControlName="name"  pattern="^[a-zA-Z ]*$" class=" form-control" placeholder="Name" type="text">
                    <mat-error class="font-family-NSB" *ngIf="errorHandling('name', 'pattern')">
                        Only allowed alphabets.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">phone Number</mat-label>
                    <input matInput formControlName="phoneNumber" class=" form-control" pattern="^[0-9]\d*$"
                        placeholder="phone Number" type="text">
                    <mat-error class="font-family-NSB" *ngIf="errorHandling('phoneNumber', 'pattern')">
                        Only allowed Numbers.
                    </mat-error>

                </mat-form-field>
            </div>
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Email</mat-label>
                    <input matInput formControlName="email" class=" form-control" placeholder="Email" type="email">
                    <mat-error class="error"
                        *ngIf="errorHandling('email', 'pattern') || errorHandling('email', 'email')">
                        Please
                        enter a valid e-mail.</mat-error>
                </mat-form-field>
                <!-- <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Active Policies</mat-label>
                    <input matInput formControlName="activePolicies" class=" form-control" placeholder="Active Policies"
                        type="text">
                </mat-form-field> -->
            </div>
            <!-- <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start">
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Coming Renewals</mat-label>
                    <input matInput formControlName="comingRenewals" class=" form-control" placeholder="Coming Renewals"
                        type="text">
                </mat-form-field>
            </div> -->

        </mat-dialog-content>
        <mat-dialog-content fxLayout fxLayout="column" fxFlex="100%" class="font-family-NSB p-4"
            *ngIf="dialogData.type == 'insurance'">
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Name</mat-label>
                    <input matInput formControlName="name" pattern="^[a-zA-Z ]*$" class=" form-control" placeholder="Name" type="text">
                    <mat-error class="font-family-NSB" *ngIf="errorHandling('name', 'pattern')">
                        Only allowed alphabets.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">phone Number</mat-label>
                    <input matInput formControlName="phoneNumber" class=" form-control" pattern="^[0-9]\d*$"
                        placeholder="phone Number" type="text">
                    <mat-error class="font-family-NSB" *ngIf="errorHandling('phoneNumber', 'pattern')">
                        Only allowed Numbers.
                    </mat-error>
                </mat-form-field>
            </div>
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Email</mat-label>
                    <input matInput formControlName="email" class=" form-control" placeholder="Email" type="email">
                    <mat-error class="error"
                    *ngIf="errorHandling('email', 'pattern') || errorHandling('email', 'email')">
                        Please
                        enter a valid e-mail.</mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Insurance Type</mat-label>
                    <mat-select formControlName="insuranceType" multiple>
                        <mat-option *ngFor="let insurance of insuranceType" [value]="insurance.id">{{insurance.title}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start">
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Insurance Status</mat-label>
                    <mat-select formControlName="insuranceStatus">
                        <mat-option *ngFor="let Status of insuranceStatus" [value]="Status">{{Status}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-label fxLayout fxLayout="row" class="font-family-NB mt-1 mb-2">Date Range</mat-label>
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start" fxLayoutGap.xs="0px" fxLayoutGap="10px">
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">From Date</mat-label>
                    <input matInput [matDatepicker]="fromDate" formControlName="fromDate" placeholder="Enter From Date">
                    <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                    <mat-datepicker #fromDate></mat-datepicker>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">To Date</mat-label>
                    <input matInput [matDatepicker]="toDate" formControlName="toDate" placeholder="Enter To Date">
                    <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                    <mat-datepicker #toDate></mat-datepicker>
                </mat-form-field>
            </div>
        </mat-dialog-content>
        <mat-dialog-content fxLayout fxLayout="column" fxFlex="100%" class="font-family-NSB p-4"
                  *ngIf="dialogData.type == 'customer_leads' || dialogData.type == 'agent_leads'">
                  <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">
                      <mat-form-field appearance="outline">
                          <mat-label class="font-family-NB">Search</mat-label>
                          <input matInput formControlName="search" pattern="^[a-zA-Z ]*$" class=" form-control"
                                 placeholder="Name" type="text">
                          <mat-error class="font-family-NSB" *ngIf="errorHandling('name', 'pattern')">
                              Only allowed alphabets.
                          </mat-error>
                      </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label class="font-family-NB">Insurance Type</mat-label>
                        <mat-select formControlName="insurance_type">
                            <mat-option *ngFor="let insurance of insuranceType" [value]="insurance.id">{{insurance.title}}</mat-option>
                        </mat-select>
                    </mat-form-field>

                  </div>
              </mat-dialog-content>
        <mat-dialog-content fxLayout fxLayout="column" fxFlex="100%" class="font-family-NSB p-4"
            *ngIf="dialogData.type == 'article'">
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start">
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Name</mat-label>
                    <input matInput formControlName="name" pattern="^[a-zA-Z0-9 ]*$" class=" form-control" placeholder="Name" type="text">
                    <mat-error class="font-family-NSB" *ngIf="errorHandling('name', 'pattern')">
                        Only allowed alphabets.
                    </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label class="font-family-NB">Learn Date</mat-label>
                    <input matInput [matDatepicker]="learn_date" formControlName="learn_date" placeholder="Enter Learn Date">
                    <mat-datepicker-toggle matSuffix [for]="learn_date"></mat-datepicker-toggle>
                    <mat-datepicker #learn_date></mat-datepicker>
                </mat-form-field>

            </div>
            <div fxLayout fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between" fxLayoutAlign.xs="start" >
                <mat-form-field appearance="outline" fxLayout fxLayout="row" fxFlexFill>
                    <mat-label class="font-family-NB">Title</mat-label>
                    <input matInput formControlName="title" pattern="^[a-zA-Z0-9 ]*$" class=" form-control" placeholder="Title" type="text">
                    <mat-error class="font-family-NSB" *ngIf="errorHandling('title', 'pattern')">
                        Only allowed alphabets.
                    </mat-error>
                </mat-form-field>
            </div>

        </mat-dialog-content>
        <mat-dialog-actions fxLayout fxLayout="row" fxLayoutAlign="end">
            <div *ngIf="errorMessage" class="error-message">{{ errorMessage }}</div>
            <button mat-button type="submit" class="btn btn-yolow btn-md radius-50 font-family-NSB" 
                (click)="applyFilter()">SEARCH</button>
            <!-- <button mat-button type="button" class="btn btn-danger btn-md radius-50 font-family-NSB" (click)="close()"
            mat-dialog-close>CANCEL</button> -->
        </mat-dialog-actions>
    </form>
</div>
