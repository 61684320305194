<mat-dialog-title fxLayout>
    <div class="font-family-NB email-header">{{dialogData.header}}</div>
</mat-dialog-title>
<mat-dialog-content class="mt-2">
    <div class="flex-box" *ngFor="let e of emails; let i = index">
        <p class="font-family-NSB">{{e}}</p>
        <p class="font-family-NSB" style="cursor: pointer;" (click)="removeEmail(i)">REMOVE</p>
        <!-- <input type="checkbox" /> -->
    </div>
    <div class="flex-box add-button cursor-pointer" (click)="newEmail()" *ngIf="!addNewEmail">
        <mat-icon class="btn-home material-icons-outlined" matBadgeColor="primary" matBadgeSize="small">add</mat-icon>
        <p class="font-family-NSB">Add New Email</p>
    </div>

    <div class="flex-box add-button" *ngIf="addNewEmail">
        <input type="text" class="newEmailInput" [(ngModel)]="newEmailText" (keydown.enter)="validateAndDumpNewEmail()"
            (ngModelChange)="onInputChange($event)" name="newEmail" />
        <!-- <mat-icon class="btn-home material-icons-outlined" (click)="validateAndDumpNewEmail()" matBadgeColor="primary"
            matBadgeSize="small">add</mat-icon> -->
        <p class="font-family-NSB" style="cursor: pointer;" (click)="validateAndDumpNewEmail()">ADD</p>
    </div>
    <div *ngIf="isInvalidEmail" class="error-message">
        Please enter a valid email.
    </div>

</mat-dialog-content>
<mat-dialog-actions fxLayout fxLayout="row" fxLayoutAlign="center" class="model-footer">
    <button mat-button class="btn footer-button radius-50 font-family-NB" [disableRipple]="true" [disabled]="!emails.length"
        (click)="sendEmail()">{{dialogData.okbutton}}</button>
    <button mat-button class="btn footer-button gray radius-50 font-family-NB" [disableRipple]="true"
        (click)="cancel()">{{dialogData.cancelbutton}}</button>
</mat-dialog-actions>