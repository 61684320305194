import React, { createContext, useContext, useReducer, useState } from "react";
import { AuthContext } from "./AuthContext";

export const ChatContext = createContext<any>(null);

export const ChatContextProvider = ({ children }: any) => {
  //  //const { currentUser } = useContext(AuthContext);

  //   var arr = [{
  //     "token": "f8Yrm7lZSsWXKlnPdBQybb:APA91bGpaYYf9ifdPAh6XaW-jpP_HJ_WfQ4aL3tVwKkHv1svfiJsLwjTT8ybgV7A-u11H81fyrJW5sx9NaEjGIRN3x_oIyOk2G94fs6W0OC7GJNxOl-Rc9HjGpZo3jMv8n2Gz2LagGpx",
  // "current_support_id": "90dcc508-71ea-4301-9ab1-e7067d9b8de7",
  // "user_name": "Darshak",
  // "id": 69,
  // "status": "assigned",
  // "last_message": "7WJ3ytwySHftgTmOsjKTFA==",
  // "device_type": "android",
  // "support_name": "darshak@theintellify.com",
  // "last_message_timestamp": 1654257646095
  // }];

  // localStorage.setItem("currentUser",JSON.stringify(arr));
  //   const  currentUser  = [{
  //     "token": "f8Yrm7lZSsWXKlnPdBQybb:APA91bGpaYYf9ifdPAh6XaW-jpP_HJ_WfQ4aL3tVwKkHv1svfiJsLwjTT8ybgV7A-u11H81fyrJW5sx9NaEjGIRN3x_oIyOk2G94fs6W0OC7GJNxOl-Rc9HjGpZo3jMv8n2Gz2LagGpx",
  //     "current_support_id": "90dcc508-71ea-4301-9ab1-e7067d9b8de7",
  //     "user_name": "Darshak",
  //     "id": 69,
  //     "status": "assigned",
  //     "last_message": "7WJ3ytwySHftgTmOsjKTFA==",
  //     "device_type": "android",
  //     "support_name": "darshak@theintellify.com",
  //     "last_message_timestamp": 1654257646095
  // }];

  localStorage.removeItem("selectedUser");
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "")[0];

  const INITIAL_STATE: any = {
    userid: "null",
    user: {},
  };

  const chatReducer = (state: any, action: any): any => {
    switch (action.type) {
      case "CHANGE_USER":
        return {
          user: action?.payload,
          id:  action?.payload?.id,
          adminuser: currentUser,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = React.useReducer(chatReducer, INITIAL_STATE);
  return (
    <ChatContext.Provider value={{ data: state, dispatch }}>
      {children}
    </ChatContext.Provider>
  );
};
