import {Injectable} from '@angular/core';
import {APIWrapperService} from './api-wrapper.service';
import {AWSHelperService} from './aws4-helper.service';
import {allURLS} from './allURL';
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AgentService {

  apiUrl = environment.api_endpoint;

  constructor(private APIWrapperService: APIWrapperService, private awsHelper: AWSHelperService, private http: HttpClient) {
  }

  // get customer list detail.
  getAgentList(payload: any) {
    return this.http.post(this.apiUrl, payload).toPromise()
  }

  updateAgentStatus(payload: any) {
    return this.http.post(this.apiUrl, payload).toPromise()
  }

}
