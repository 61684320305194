<div class="ml-5 mr-5" fxLayout="column">
  <div fxLayout style="align-items: center !important;">
    <h5 class="card-title ml-1 font-family-NB mt-4" fxLayout fxFlex="97">User Insurances</h5>
    <!-- <button mat-button (click)="addInsuranceDialog()" fxLayout fxFlex="8" fxLayoutGap="20" class="btn btn-sm btn-yolow text-center font-family-NSB  mr-2">
      <mat-icon class="material-icons-outlined">add</mat-icon> Add Insurance
    </button> -->
    <button mat-icon-button (click)="clearFilter()" *ngIf="!isLoading && isFilter"
      class="btn btn-yolow btn-clear-filter text-center mr-2" #tooltip="matTooltip" matTooltip="Clear Filter"
      matTooltipPosition="below" fxLayout fxFlex="8" fxLayoutGap="20">
      <mat-icon class="ml-2 material-icons-outlined">clear</mat-icon>Clear Filter
    </button>
    <button mat-icon-button (click)="openFilterDialog()" *ngIf="!isLoading" class="btn btn-yolow btn-filter text-center"
      #tooltip="matTooltip" matTooltip="Filter list" matTooltipPosition="below" fxLayout fxLayoutGap="20">
      <mat-icon class="ml-2 material-icons-outlined">filter_list</mat-icon>
    </button>
  </div>
  <div fxLayout fxLayoutAlign="center" fxLayoutGap="30px" *ngIf="isLoading">
    <mat-spinner style="margin:0 auto;" *ngIf="isLoading" [diameter]="30"></mat-spinner>
  </div>
  <div class="insurance-container font-family-NSB mb-3" fxLayout fxLayout="column" fxLayoutAlign="start"
    fxLayoutGap="5px" fxLayoutGap.xs="0">
    <mat-card class="example-container mb-3" [ngClass]="!isLoading ? 'd-block':'d-none'" fxLayout fxLayout="column">
      <mat-card-content class="font-family-NSB" fxLayout fxLayout="column">
        <div fxLayout fxLayout="column" *ngIf="!isLoading">
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
            [matSortActive]="sortD.sortField" [matSortDirection]="sortD.sortOrder">

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
              <td mat-cell *matCellDef="let row">{{ row.name }}</td>
            </ng-container>

            <!-- Phone Number Column -->
            <ng-container matColumnDef="phoneNumber">
              <th mat-header-cell *matHeaderCellDef>Phone Number</th>
              <td mat-cell *matCellDef="let row">{{ row.phoneNumber }}</td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
              <td mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>

            <!-- Insurance Type Column -->
            <ng-container matColumnDef="insuranceType">
              <th mat-header-cell *matHeaderCellDef>Insurance Type</th>
              <td mat-cell *matCellDef="let row">{{ getInsuranceType(row.insuranceType) }}</td>
            </ng-container>

            <!-- Insurance Status Column -->
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Insurance Status</th>
              <td mat-cell *matCellDef="let row" [ngClass]="row.status == 'basic' ? 'text-warning' :
              row.status == 'complete' ? 'text-success' : 'text-danger'">
                {{row.status == 'basic' ? 'BASIC' : row.status == 'complete' ? 'COMPLETE' : 'PENDING'}}
              </td>
            </ng-container>

            <!-- Insurance Create Column -->
            <ng-container matColumnDef="createdDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="createdDate">Created</th>
              <td mat-cell *matCellDef="let row">{{ getCreate(row.createdDate) }}</td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row">
                <span class="d-flex">
                  <button (click)="editInsurance(row)" title="Edit" class="btn btn-outline-dark btn-sm mr-2 ">
                    <mat-icon class="btn-edit material-icons-outlined">edit</mat-icon>
                  </button>
                  <button (click)="deleteInsuranceDialog(row)" title="Delete" class="btn btn-outline-dark btn-sm">
                    <mat-icon class="btn-edit material-icons-outlined">delete_outline</mat-icon>
                  </button>
                </span>
              </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td [colSpan]="displayedColumns.length" class="mat-cell text-center">No data found</td>
            </tr>
            <tr class="font-family-NB" mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr class="font-family-NSB" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>

        <!--  fxLayout fxLayoutAlign="end"  fxFlex="100" fxFlex.xs="100" fxLayoutGap="10px" -->
        <mat-paginator [ngClass]="!isLoading ? 'd-block':'d-none'" class="font-family-NB ml-max" fxLayout
          fxLayoutAlign="end" fxFlex="100" fxFlex.xs="100" fxLayoutGap.xs="0px" fxLayoutGap="10px"
          (page)="pageChanged($event)" [pageSize]="page.limit" [pageSizeOptions]="[ 10, 25, 50, 100]">
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
