import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { UserDataService } from "../../../services/user-data.service";
import { InsuranceDataService } from '../../../services/insurance-data.service';
import { CommonService } from '../../../services/common.service';
import { AppState } from '../../../services/app.state';
import {InsuranceService} from "../../../services/insurance.service";

@Component({
  selector: 'app-add-insurance',
  templateUrl: './add-insurance.component.html',
  styleUrls: ['./add-insurance.component.scss']
})
export class AddInsuranceComponent implements OnInit {

  isAddInsurance: boolean = false;
  isLoading: boolean = false;
  isFile: boolean = false;
  isImage: boolean = false;
  dialogData: any;
  customer: any;
  userData: any;
  addInsurancePayload = {
    "header": {
      "message_uuid": "",
      "message_type": "Q",
      "version": "1.0",
      "message_name": "admin_user_insurance-C",
      "correlation_uuid": "",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      "user_profile_sequence_id": "",
      "insurance_type": "",
      "insurance_documents": []
    }
  }
  getInsuranceTypesPayLoad = {
    "header": {
      "message_uuid": "",
      "message_name": "insurance_type-R",
      "message_type": "Q",
      "correlation_uuid": "",
      "request_id": "",
      "version": "1.0",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      // "agent_cognito_id": "",
      "start": 0,
      "limit": 5
    }
  };
  addInsuranceDocumentPayLoad = {
    "header":
    {
      "message_uuid": "",
      "message_type": "Q",
      "version": "1.0",
      "message_name": "admin_user_insurance_document-C",
      "correlation_uuid": "",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      "user_id": "",
      "insurance_id": "",
      "insurance_documents": []
    }
  };
  addInsurance: FormGroup = this.fb.group({
    insuranceType: ["", [Validators.required]],
    // insuranceDocuments: ["", Validators.compose([Validators.required])]
  });
  myfilename = 'Select File';
  insuranceTypes: any = [];
  // insuranceType: string[] = ['Auto Insurance', 'Umbrella Insurance', 'Condo Insurance', 'Home Warranty Insurance', 'Health Insurance', 'Dental Insurance'];

  @ViewChild('UploadFile') uploadFile: ElementRef;
  insuranceDocuments: any = [];
  AssetsURL: string = environment.assetsURL;

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<AddInsuranceComponent>, private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any, private insuranceDataService: InsuranceDataService,
    private commanService: CommonService,
    private insuranceService: InsuranceService,
    public appState: AppState,
    public userDataService: UserDataService) {
    this.dialogData = { ...data };
  }

  ngOnInit(): void {
    this.customer = this.appState.get("CUSTOMER");
    this.userData = this.userDataService.getUserData();
    this.getInsuranceTypes();
  }

  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      var allCheck = this.validateFileInput(fileInput);
      if (allCheck.doCheck) {
        this.myfilename = '';
        Array.from(fileInput.target.files).forEach((file: any) => {
          let reader = new FileReader();
          reader.onload = async () => {
            if (allCheck.flag) {
              let documents = await this.commanService.compressFile(reader.result);
              this.insuranceDocuments.push(documents);
            }
            else {
              this.insuranceDocuments.push(reader.result);

            }
          }
          reader.readAsDataURL(file);
        });
        let name = Array.from(fileInput.target.files).map((x: any) => x.name);
        this.myfilename = name.join(',');
      }
    }
  }
  validateFileInput(fileInput: any) {
    this.myfilename = 'Select File';
    this.insuranceDocuments = [];
    this.isFile = false;
    this.isImage = false;
    let doCheck = true;
    let flag = false;
    if (Array.from(fileInput.target.files).some((f: any) => f.name.includes('.pdf'))) {
      let validate = Array.from(fileInput.target.files).some((f: any) => f.size > 3000000);
      doCheck = !validate && fileInput.target.files.length == 1 ? true : false;
      this.isFile = validate || fileInput.target.files.length > 1 ? true : false;
      flag = false;
    }
    else if (Array.from(fileInput.target.files).some((f: any) => f.name.includes('.jpg') ||
      f.name.includes('.jpeg') || f.name.includes('.png'))
      && fileInput.target.files.length > 4) {
      doCheck = false;
      this.isImage = true;
      flag = true;
    }
    return { doCheck: doCheck, flag: flag };
  }

  get addInsuranceF() {
    return this.addInsurance.controls;
  }
  async getInsuranceTypes() {
    // check insuranceType having data or not
    this.insuranceDataService.getInsuranceType.subscribe((result: any) => {
      this.insuranceTypes = result;
    });

    if (this.insuranceTypes == null) {
      let parseResponse: any = await this.insuranceService.getInsurances(this.getInsuranceTypesPayLoad);

      if (parseResponse) {
        this.insuranceTypes = parseResponse.insuranceTypes;
        this.insuranceDataService.setInsurancetype(this.insuranceTypes);
      }
    }
  }
  async addNewInsurance() {
    this.isAddInsurance = true;
  
    if (this.dialogData.type == 'add') {
      if (this.addInsurance.invalid || this.insuranceDocuments.length <= 0) {
        return;
      }  
      this.isLoading = true;
      if (this.addInsurance.valid) {
        this.isAddInsurance = false;

        this.addInsurancePayload.header.created_by = this.userData != undefined ? this.userData.cognitoId : '';
        this.addInsurancePayload.body.user_profile_sequence_id = this.customer.id;

        this.addInsurancePayload.body.insurance_type = this.addInsurance.controls['insuranceType'].value;
        this.addInsurancePayload.body.insurance_documents = this.insuranceDocuments;

        let parseResponse: any = await this.insuranceService.getInsurances(this.addInsurancePayload);
        this.isLoading = false;
        this.addInsurance.controls['insuranceType'].setValue("");
        this.insuranceDocuments = [];
        this.dialogRef.close({ action: 'add' });
        if (parseResponse && parseResponse.code == 201) {
          this.snackBar.open("New Insurance saved Successfully.", 'close', {
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ['success-snackbar']
          });
        } else if (parseResponse && parseResponse.error) {
          this.snackBar.open(parseResponse.error, 'close', {
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ['error-snackbar']
          });
        } else {
          this.snackBar.open("Something went wrong.!", 'close', {
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: "right",
            panelClass: ['error-snackbar']
          });
        }
      }

    }
    else if (this.dialogData.type == 'update') {
      if (this.insuranceDocuments.length <= 0) {
        return;
      }
      this.isAddInsurance = false;
      this.isAddInsurance = false;

      this.addInsuranceDocumentPayLoad.header.created_by = this.userData != undefined ? this.userData.cognitoId : '';
      this.addInsuranceDocumentPayLoad.body.user_id = this.dialogData.userId;
      this.addInsuranceDocumentPayLoad.body.insurance_id = this.dialogData.insuranceId;

      // this.addInsurancePayload.body.insurance_type = this.addInsurance.controls['insuranceType'].value;
      this.addInsuranceDocumentPayLoad.body.insurance_documents = this.insuranceDocuments;

      let parseResponse: any = await this.insuranceService.getInsurances(this.addInsuranceDocumentPayLoad);
      if (parseResponse) {
        this.isLoading = false;
        this.insuranceDocuments = [];
        this.dialogRef.close({ action: 'add' });
        this.snackBar.open(parseResponse.message, 'close', {
          duration: 3000,
          verticalPosition: "top",
          horizontalPosition: "right",
          panelClass: ['success-snackbar']
        });
      }


    }

  }
  back() {
    throw new Error('Method not implemented.');
  }


  close() {
    this.dialogRef.close({ action: 'close' });
  }
  handleClick() {
    this.uploadFile.nativeElement.value = '';
    this.uploadFile.nativeElement.click();
  }
}
