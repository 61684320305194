import { Component, OnInit } from '@angular/core';
import { AppState } from "../../../services/app.state";
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';
import { CommonService } from '../../../services/common.service';
@Component({
  selector: 'app-details-article',
  templateUrl: './details-article.component.html',
  styleUrls: ['./details-article.component.scss']
})
export class DetailsArticleComponent implements OnInit {

  article: any={};
  AssetsURL: string = environment.assetsURL;
  constructor(private commanService: CommonService, private appState: AppState, private router: Router) { }

  ngOnInit(): void {
    let article = this.appState.get("ARTICLE");
    if (article == null) {
      this.router.navigate(['/learn']);
    }
    this.article = article;

  }
  back() {
    this.router.navigate(['/learn']);
  }
}
