<section class="section">
    <div class="row">
        <div class="col-md-12">
            <div class="logo">
                <img [src]="AssetsURL+'/img/login_logo.png'">
            </div>
            <div class="text" style="align-items:center">
                404
            </div>
            <div class="box" style="align-items:center">
                This is not the webpage you are looking for.
            </div>
        </div>
    </div>
</section>
