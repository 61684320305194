<div fxLayout="column">
    <span class="text-center" *ngFor="let menuItem of menuItems">
        <span *ngIf="menuItem.title!='Logout'; else NotLogout">
            <a mat-button routerLinkActive="active-list-item" [routerLink]="[menuItem.path]">
                <p class="title">{{ menuItem.title }}</p>
            </a>
        </span>
        <ng-template #NotLogout>
            <a routerLinkActive="active-list-item" (click)="signOut()">
                <p class="title">{{ menuItem.title }}
                    <i [class]="menuItem.icon" data-pack="default" data-tags="sign in"></i>
                </p>
            </a>
        </ng-template>
    </span>
    <a mat-button>
        <span class="mat-list-base mr-2" routerLink="/chat">
            <mat-icon class="material-icons-outlined"> chat</mat-icon>
        </span>
    </a>
    <a mat-button>
        <span class="mat-list-base mr-2" #ddTrigger="matMenuTrigger" [matMenuTriggerFor]="dd">
            <mat-icon matBadge="{{notificationCount}}" matBadgeColor="warn">notifications_none</mat-icon>
            <!-- <mat-icon matBadge="{{notificationCount}}" matBadgeColor="warn">inbox</mat-icon> -->
        </span>
    </a>

    <span class="navbar" mat-button placement="bottom right">
        <span class="nav-item dropdown">
            <a class="nav-link dropdown-toggle " href="#" role="button" data-toggle="dropdown" aria-haspopup="true"
                aria-expanded="false">
                <img [src]="profile_image" width="40" height="40" class="rounded-circle">
                {{ userData != null && userData != undefined ? userData.email : ''}}
            </a>
            <div class="dropdown-menu" id="navbarDropdownMenuLink" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Edit Profile</a>
                <a class="dropdown-item" href="#" routerLink="/logout">Log Out</a>
            </div>
        </span>
    </span>
</div>
<mat-menu #dd="matMenu" [overlapTrigger]="false">
    <div *ngIf="notifications && notifications.length > 0; else NoNotifications">
        <div class="notification-content mt-3">
            <div class="dropdown" *ngFor="let notif of notifications">
                <mat-card>
                    <div (click)="notification(notif)">
                        <span>{{getNotificationText(notif)}}</span>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>
    <ng-template #NoNotifications>
        <div class="notification-content mt-3">
            <div class="no-notifications-dropdown">
                <span>You're All Caught Up!</span>
            </div>
        </div>
    </ng-template>


</mat-menu>