export class User {
    constructor(
      private _sessionToken: string | null,
      private _secretAccessKey: string | null,
      private _accessKey: string | null,
      private _tokenExpirationDate: Date,
      public _idToken:string | null,
      public cognitoId?: string,
      public email?: string | null,
      private _refreshToken?: string | null,
      public type?: string | null,
      public name?: string | null
    ) {}
  
    set sessionToken(sessionToken) {
      this._sessionToken = sessionToken;
    }

    set secretAccessKey(secretAccessKey) {
      this._secretAccessKey = secretAccessKey;
    }

    set accessKey(accessKey) {
      this._accessKey = accessKey;
    }

    set tokenExpirationDate(tokenExpirationDate:any) {
      this._tokenExpirationDate = tokenExpirationDate;
    }

    get sessionToken() {
      if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
        return null;
      }
      return this._sessionToken;
    }

    get refreshToken() {
      if (this._refreshToken === '') {
        return null;
      }
      return this._refreshToken;
    }

    get accessKey() {
      if (this._accessKey === '') {
        return null;
      }
      return this._accessKey;
    }

    get secretAccessKey() {
      if (this._secretAccessKey === '') {
        return null;
      }
      return this._secretAccessKey;
    }

  }