import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from './services/authentication.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(private authenticationService: AuthenticationService, private router: Router) {
  }

  canActivate(): any {
    let data = localStorage.getItem('userDataSimba');
    let awsCred = localStorage.getItem('userAwsCred');
    if (data && awsCred) {
      // this.router.navigate(['dashbord']);
      return true;
    } else {
      // window.location.reload();
      this.router.navigate(['/login']);
      return false;
    }
  }
}
