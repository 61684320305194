<div class="enrollment-page h-100">
  <div class="col-lg-12 col-md-12 col-sm-12 row ">
    <!-- background-image:url('{{AssetsURL}}/img/login_background.png'); background-repeat: no-repeat;background-size: 100% 100%; background-attachment: cover;height: 100vh; -->
    <div class="col-lg-5 col-md-5 col-sm-5 enrollment-part1">
      <!-- <img src="{{AssetsURL}}/img/login_background.png" width="100%" alt="Login Logo"> -->
    </div>
    <mat-card class="card col-lg-7 col-md-7 col-sm-7 bg-enrollment p-2" style="height: 100vh;"
      *ngIf="typeOfEnrollment == 'Agent'; else venderEnrollment">
      <h3>Agent Entrollment</h3>
      <img style="margin-left: 0;" src="{{AssetsURL}}/img/line.png" />
      <mat-stepper [linear]="true"  #stepper>
        <mat-step [stepControl]="personalDetailForm" [editable]="personalDetailForm.valid && documents.length >= 0">
          <ng-template matStepLabel>Personal Detail</ng-template>
          <div class="container-business h-100">
            <form [formGroup]="personalDetailForm" fxLayout="column" (ngSubmit)="submitPersonal(stepper)" novalidate>
              <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%" fxLayoutAlign.sm="start"
                fxLayoutAlign.xs="start" fxLayoutAlign="space-between" fxLayoutGap="30px" fxLayoutGap.xs="0px">
                <mat-form-field appearance="outline">
                  <mat-label>Agent Name</mat-label>
                  <input matInput formControlName="name" placeholder="Agent Name" required>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingPersonal('name', 'required')">
                    Agent Name is Required.
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Mobile Number</mat-label>
                  <input matInput formControlName="mobileNumber" placeholder="Mobile Number" pattern="^[0-9]\d*$"
                    maxlength="14" minlength="10" required>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingPersonal('mobileNumber', 'minlength')">
                    Mobile Number is Min Length 10.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingPersonal('mobileNumber', 'maxlength')">
                    Mobile Number is Max Length 14.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingPersonal('mobileNumber', 'required')">
                    Mobile Number is Required.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingPersonal('mobileNumber', 'pattern')">
                    Only allowed Numbers.
                  </mat-error>
                </mat-form-field>
              </div>
              <mat-form-field class="mt-1" appearance="outline" fxFlexFill>
                <mat-label>Residential Address</mat-label>
                <input matInput formControlName="residentialAddress" placeholder="Residential Address">
              </mat-form-field>
              <div class="myfilebrowser" fxLayout="column" fxFlex="100%">
                <mat-label class="font-family-NB mb-3">Upload Document</mat-label>
                <input type="file" #uploadFile id="fileUpload" (change)="uploadFileChange($event)" name="fileUpload"
                  multiple="multiple" accept="image/*, .pdf" />

                <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="10px" fxFlex="100%"
                  fxLayoutAlign="space-between">
                  <img [src]="AssetsURL+'/img/upload_file.png'" fxLayout="column" fxLayoutGap="20px" width="100px"
                    height="100px" />
                  <span fxLayout="column" fxFlex="85" fxLayoutGap="20px">
                    <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="10px">
                      <button type="button" fxFlex="35" (click)="handleClick()" mat-button
                        class="btn btn-sm btn-yolow h-40 text-center">Upload
                        Document(s)</button>
                      <div class="filename font-family-NSB word-wrap ml-3" fxLayout="row" fxLayoutGap="10px">
                        {{fileDocuments}}</div>
                    </span>
                    <span>Note : <span class="text-gray">( Driving license or a passport )</span></span>
                  </span>
                </span>
                <mat-error class="font-family-NSB font-75" *ngIf="isPersonalDetail && documents.length <= 0">
                  Please upload atleast one Document.
                </mat-error>
              </div>
              <div class="mt-2">
                <button mat-button class="btn btn-md btn-yolow h-40" type="submit" >Next <mat-icon>
                    arrow_right_alt</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </mat-step>
        <mat-step [stepControl]="businessDetailForm" [editable]="businessDetailForm.valid">
          <ng-template matStepLabel>Business Details</ng-template>
          <div class="container-business h-100">
            <form [formGroup]="businessDetailForm" fxLayout="column" (ngSubmit)="submitBusiness(stepper)" novalidate>

              <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%" fxLayoutAlign="space-between"
                fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlexFill.sm="true">
                  <mat-label>Business Name</mat-label>
                  <input matInput formControlName="name" placeholder="Business Name" required>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('name', 'required')">
                    Business Name is Required.
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill.sm="true">
                  <mat-label>Business Register Number</mat-label>
                  <input matInput formControlName="registerNumber" placeholder="Business Register Name"
                    pattern="^[0-9]\d*$" maxlength="14" minlength="10">
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('registerNumber', 'minlength')">
                    Business Register Number is Min Length 10.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('registerNumber', 'maxlength')">
                    Business Register Number is Max Length 14.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('registerNumber', 'pattern')">
                    Only allowed Numbers.
                  </mat-error>
                </mat-form-field>
              </span>

              <span fxLayout="row" class="mt-1" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%"
                fxLayoutAlign="space-between" fxLayoutGap="30px">
                <mat-form-field appearance="outline" fxFlexFill.sm="true">
                  <mat-label>Business Email</mat-label>
                  <input matInput formControlName="email" type="email" placeholder="Business Email Name" required>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('email', 'required')">
                    Business Email is Required.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('email', 'email')">
                    Please enter the valid email.
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill.sm="true">
                  <mat-label>Business Telephone</mat-label>
                  <input matInput formControlName="telephoneNumber" placeholder="Business Telephone"
                    pattern="^[0-9]\d*$" maxlength="14" minlength="10" required>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('telephoneNumber', 'minlength')">
                    Business Telephone is Min Length 10.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('telephoneNumber', 'maxlength')">
                    Business Telephone is Max Length 14.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('telephoneNumber', 'required')">
                    Business Telephone is Required.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('telephoneNumber', 'pattern')">
                    Only allowed Numbers.
                  </mat-error>
                  <!-- <mat-error *ngIf="telephoneNumber.hasError('required')">Business Telephone is required!</mat-error> -->
                  <!-- <mat-error *ngIf="telephoneNumber.hasError('pattern')">Only Numbers Allowed.</mat-error> -->
                </mat-form-field>
              </span>
              <mat-form-field class="mt-1" appearance="outline" fxFlexFill>
                <mat-label>Business Address</mat-label>
                <input matInput formControlName="address" type="text" placeholder="Business Address" required>
                <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('address', 'required')">
                  Business Address is Required.
                </mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>Business Website Link</mat-label>
                <input matInput formControlName="websiteLink" type="url" placeholder="Business Website Link">
              </mat-form-field>

              <mat-form-field appearance="outline" fxFlexFill>
                <mat-label class="font-family-NB">Types of Provided Insurance</mat-label>
                <mat-select class="mb-2" matInput formControlName="insuranceType" multiple>
                  <mat-option *ngFor="let insurance of insuranceType" [value]="insurance.id">{{insurance.title}}</mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>Agent Licensed States</mat-label>
                <input matInput formControlName="licensedState" placeholder="Agent Licensed States">
              </mat-form-field>
              <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>Agent License Number</mat-label>
                <input matInput formControlName="licenseNumber" placeholder="Agent License Name">
              </mat-form-field>
              <div class="mt-2">
                <button mat-button class="btn btn-md btn-black" type="button" matStepperPrevious>Back</button>
                <button mat-button class="btn btn-md btn-yolow h-40" type="submit" >Next <mat-icon>
                    arrow_right_alt</mat-icon>
                </button>
              </div>
            </form>
          </div>
        </mat-step>
        <mat-step [editable]="affiliationDetailForm.valid">
          <!-- [stepControl]="affiliationDetailForm" -->
          <ng-template matStepLabel>Affiliation Details</ng-template>
          <button (click)="addCompanyDetails()" class="btn btn-md btn-yolow">
            <mat-icon>add</mat-icon> <span class="v-m">Add Company</span>
          </button>
          <form (ngSubmit)="submitAgentData()" novalidate>
            <div class="container-company mt-2" *ngIf="isAddDetails">
              <div *ngFor="let item of affiliationDetailForm; let i = index" fxLayout fxLayout="column"
                class="mt-3 customer-details">
                <div fxLayout fxLayout="column" fxLayoutGap="20px">
                  <div fxLayout fxFlex="100%" fxLayoutAlign="end">
                    <button mat-icon-button (click)="deleteCompanyDetailsRow(i)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                  <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%" fxFlex.xs="50%"
                    fxLayoutAlign="space-around" fxLayoutGap="30px" fxLayoutGap.xs="10px" fxLayoutGap.sm="10px">
                    <mat-form-field appearance="outline">
                      <mat-label>Company Name</mat-label>
                      <input matInput type="text" name="companyName{{i}}" title="Company Name is Required."
                        [(ngModel)]="item.companyName" placeholder="Comapny Name"
                        [required]="item.companyName == '' && isAddCompanyDetails" />
                      <mat-error class="font-family-NSB" *ngIf="item.companyName == '' && isAddCompanyDetails">
                        Company Name is Required.
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <mat-label> No Of Year</mat-label>
                      <input matInput type="number" pattern="^[0-9]\d*$" name="noOfYear{{i}}" maxlength="2"
                        [(ngModel)]="item.noOfYear" placeholder="No Of Year" />
                      <!-- <mat-error class="font-family-NSB" *ngIf="item.noOfYear == '' && isAddCompanyDetails">
                        No Of Year is Required.
                      </mat-error> -->
                    </mat-form-field>
                  </span>
                  <div class="myfilebrowser" fxLayout="column" fxFlex="100%">
                    <mat-label class="font-family-NB">Upload Document</mat-label>
                    <!-- id="fileUpload" -->
                    <input type="file" mat-Input id="fileUpload" #uploadCompanyDocs
                      (change)="uploadCompanyDocuments($event,i)" name="fileUpload" multiple="multiple"
                      accept="image/*, .pdf" />

                    <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="10px" fxFlex="100%"
                      fxLayoutAlign="space-between">

                      <img [src]="AssetsURL+'/img/upload_file.png'" fxLayout="column" fxLayoutGap="20px" width="100px"
                        height="100px" />

                      <span fxLayout="column" fxFlex="78" fxLayoutGap="20px">
                        <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="10px">
                          <!-- (click)="onDocumentsSelect()"  -->
                          <button type="button" fxFlex="35" mat-button (click)="onDocumentsSelect(i)"
                            class="btn btn-sm btn-yolow h-40 text-center">Upload
                            Document(s)</button>
                          <div class="filename font-family-NSB word-wrap ml-3" fxLayout="row" fxLayoutGap="10px">
                            {{item.companyfileDocuments}}</div>
                        </span>
                        <span class="mt-2">Note : <span class="text-gray">( upload documents such as latest commission
                            statements, or
                            acknowledgments, or proof of
                            latest policy sales, etc. ) </span></span>
                      </span>
                    </span>

                    <mat-error class="font-family-NSB font-75" *ngIf="isAddCompanyDetails && item.uploadDocument.length <= 0">
                      Please upload atleast one Document.
                    </mat-error>

                  </div>
                </div>
              </div>
            </div>
            <div class="mt-1 sticky-footer">
              <button mat-button class="btn btn-md btn-black" type="button" matStepperPrevious>Back</button>
              <button mat-button class="btn btn-md btn-yolow h-40" type="submit" >Done
              </button>
            </div>
          </form>
        </mat-step>
      </mat-stepper>
    </mat-card>
    <ng-template #venderEnrollment>
      <mat-card class="card col-lg-7 col-md-7 col-sm-7 bg-enrollment p-2" style="height: 100vh;">
        <h3>Vender Entrollment</h3>
        <img style="margin-left: 0;" src="{{AssetsURL}}/img/line.png" />
        <mat-stepper [linear]="true"  #stepper>
          <mat-step [stepControl]="ownerOrCorrespondentForm" [editable]="ownerOrCorrespondentForm.valid">
            <ng-template matStepLabel>Owner/Correspondent Details</ng-template>
            <div class="container-business h-100">
              <form [formGroup]="ownerOrCorrespondentForm" fxLayout="column" (ngSubmit)="submitOwnerOrCorrespondent(stepper)"
                novalidate>
                <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%" fxLayoutAlign.sm="start"
                  fxLayoutAlign.xs="start" fxLayoutAlign="space-between" fxLayoutGap="30px" fxLayoutGap.xs="0px">
                  <mat-form-field appearance="outline">
                    <mat-label>Owner/Correspondent Name</mat-label>
                    <input matInput formControlName="name" placeholder="Owner/Correspondent Name" required>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingOwner('name', 'required')">
                      Owner/Correspondent Name is Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlexFill.sm="true">
                    <mat-label>Owner/Correspondent Email</mat-label>
                    <input matInput formControlName="email" type="email" placeholder="Owner/Correspondent Email Name"
                      required>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingOwner('email', 'required')">
                      Owner/Correspondent Email is Required.
                    </mat-error>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingOwner('email', 'email')">
                      Please enter the valid email.
                    </mat-error>
                  </mat-form-field>
                </div>
                <mat-form-field appearance="outline">
                  <mat-label>Owner/Correspondent Phone</mat-label>
                  <input matInput formControlName="mobileNumber" placeholder="Owner/Correspondent Phone"
                    pattern="^[0-9]\d*$" maxlength="14" minlength="10" required>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingOwner('mobileNumber', 'minlength')">
                    Owner/Correspondent Phone is Min Length 10.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingOwner('mobileNumber', 'maxlength')">
                    Owner/Correspondent Phone is Max Length 14.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingOwner('mobileNumber', 'required')">
                    Owner/Correspondent Phone is Required.
                  </mat-error>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingOwner('mobileNumber', 'pattern')">
                    Only allowed Numbers.
                  </mat-error>
                </mat-form-field>
                <div class="mt-3">
                  <button mat-button class="btn btn-md btn-yolow h-40" type="submit" >Next <mat-icon>
                      arrow_right_alt</mat-icon>
                  </button>
                </div>
              </form>
            </div>
          </mat-step>
          <mat-step [stepControl]="businessDetailForm" [editable]="businessDetailForm.valid && documents.length >= 0">
            <ng-template matStepLabel>Business Details</ng-template>
            <div class="container-business h-100">
              <form [formGroup]="businessDetailForm" fxLayout="column" (ngSubmit)="submitBusiness(stepper)" novalidate>

                <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%"
                  fxLayoutAlign="space-between" fxLayoutGap="30px">
                  <mat-form-field appearance="outline" fxFlexFill.sm="true">
                    <mat-label>Business Name</mat-label>
                    <input matInput formControlName="name" placeholder="Business Name" required>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('name', 'required')">
                      Business Name is Required.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="mt-1" appearance="outline" fxFlexFill.sm="true">
                    <mat-label>Business Address</mat-label>
                    <input matInput formControlName="address" type="text" placeholder="Business Address" required>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('address', 'required')">
                      Business Address is Required.
                    </mat-error>
                  </mat-form-field>
                </span>

                <span fxLayout="row" class="mt-1" fxLayout.xs="column" fxLayout.sm="column" fxFlex="100%"
                  fxLayoutAlign="space-between" fxLayoutGap="30px">

                  <mat-form-field appearance="outline" fxFlexFill.sm="true">
                    <mat-label>Business Telephone</mat-label>
                    <input matInput formControlName="telephoneNumber" placeholder="Business Telephone"
                      pattern="^[0-9]\d*$" maxlength="14" minlength="10" required>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('telephoneNumber', 'minlength')">
                      Business Telephone is Min Length 10.
                    </mat-error>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('telephoneNumber', 'maxlength')">
                      Business Telephone is Max Length 14.
                    </mat-error>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('telephoneNumber', 'required')">
                      Business Telephone is Required.
                    </mat-error>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('telephoneNumber', 'pattern')">
                      Only allowed Numbers.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field appearance="outline" fxFlexFill.sm="true">
                    <mat-label>Business Email</mat-label>
                    <input matInput formControlName="email" type="email" placeholder="Business Email Name" required>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('email', 'required')">
                      Business Email is Required.
                    </mat-error>
                    <mat-error class="font-family-NSB" *ngIf="errorHandlingBusiness('email', 'email')">
                      Please enter the valid email.
                    </mat-error>
                  </mat-form-field>
                </span>

                <mat-form-field class="mt-1" appearance="outline" fxFlexFill>
                  <mat-label>Business Website Link</mat-label>
                  <input matInput formControlName="websiteLink" type="url" placeholder="Business Website Link">
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlexFill>
                  <mat-label class="font-family-NB">Types of Provided Insurance</mat-label>
                  <mat-select class="mb-2" matInput formControlName="insuranceType" multiple>
                    <mat-option *ngFor="let insurance of insuranceType" [value]="insurance.id">{{insurance.title}}</mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlexFill>
                  <mat-label>Business Registration Number</mat-label>
                  <input matInput formControlName="registrationNumber" placeholder="Business Registration Name">
                </mat-form-field>
                <div class="myfilebrowser" fxLayout="column" fxFlex="100%">
                  <mat-label class="font-family-NB mb-3">Upload Document</mat-label>
                  <input type="file" #uploadFile id="fileUpload" (change)="uploadFileChange($event)" name="fileUpload"
                    multiple="multiple" accept="image/*, .pdf" />

                  <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="10px" fxFlex="100%"
                    fxLayoutAlign="space-between">
                    <img [src]="AssetsURL+'/img/upload_file.png'" fxLayout="column" fxLayoutGap="20px" width="100px"
                      height="100px" />
                    <span fxLayout="column" fxFlex="85" fxLayoutGap="20px">
                      <span fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayoutGap.xs="10px">
                        <button type="button" fxFlex="35" (click)="handleClick()" mat-button
                          class="btn btn-sm btn-yolow h-40 text-center">Upload
                          Document(s)</button>
                        <div class="filename font-family-NSB word-wrap ml-3" fxLayout="row" fxLayoutGap="10px">
                          {{fileDocuments}}</div>
                      </span>
                      <span>Note : <span class="text-gray">( upload documents such as Property or shop
                          Photo)</span></span>
                    </span>
                  </span>
                  <mat-error class="font-family-NSB font-75" *ngIf="isbusinessDetail && documents.length <= 0">
                    Please upload atleast one Document.
                  </mat-error>
                </div>
                <div class="mt-2">
                  <button mat-button class="btn btn-md btn-black" type="button" matStepperPrevious>Back</button>
                  <button mat-button class="btn btn-md btn-yolow h-40" type="submit" >Next <mat-icon>
                      arrow_right_alt</mat-icon>
                  </button>
                </div>
              </form>
            </div>
          </mat-step>
          <mat-step [stepControl]="billingInfoForm" [editable]="billingInfoForm.valid">
            <ng-template matStepLabel>Billing Details</ng-template>
            <div class="container-business h-100">
              <form [formGroup]="billingInfoForm" fxLayout="column" (ngSubmit)="submitBillingInfo(stepper)" novalidate>
                <mat-form-field appearance="outline" fxFlexFill>
                  <mat-label>Payment Type</mat-label>
                  <input matInput formControlName="paymentType" placeholder="Payment Type" required>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBilling('paymentType', 'required')">
                    paymentType is Required.
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlexFill>
                  <mat-label>Address</mat-label>
                  <input matInput formControlName="address" type="text" placeholder="Address" required>
                  <mat-error class="font-family-NSB" *ngIf="errorHandlingBilling('address', 'required')">
                    Address is Required.
                  </mat-error>

                </mat-form-field>

                <div class="mt-2">
                  <button mat-button class="btn btn-md btn-black" type="button" matStepperPrevious>Back</button>
                  <button mat-button class="btn btn-md btn-yolow h-40" type="submit" >Done
                  </button>
                </div>
              </form>
            </div>
          </mat-step>
        </mat-stepper>
      </mat-card>
    </ng-template>
  </div>
</div>
