import { Component, Input, ViewChild } from '@angular/core';
import { UserInterface } from './../../../interfaces/user.interface';
import { AuthenticationService } from './../../../services/authentication.service';
import { UserDataService } from './../../../services/user-data.service';
import Menu from '../../../../assets/data/Menu.json';
import { environment } from '../../../environments/environment'
import { MatMenuTrigger } from '@angular/material/menu';
import { ProfileService } from './../../../services/profile.service';
import { CryptoService } from './../../../services/crypto.service';
import { InsuranceService } from './../../../services/insurance.service';
import { Router } from '@angular/router';
import { AppState } from './../../../services/app.state';
import { InsuranceDataService } from '../../../services/insurance-data.service';
import { CommonService } from '../../../services/common.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  isLoggedIn: boolean;
  menuItems: any[];
  AssetsURL: string = environment.assetsURL;
  InitalCharacterURL: string = environment.initialCharacterURL;
  userData: UserInterface;
  notificationCount: number = 0;
  notifications: any[] = [];
  profile_image: string = "";
  Menu: { "path": string, "title": string, "icon": string, "class": string }[] = Menu.ROUTES;
  //  {
  //   "path": "/logout",
  //   "title": "Logout",
  //   "icon": "ion-log-in",
  //   "class": ""
  // }

  @ViewChild(MatMenuTrigger) ddTrigger: MatMenuTrigger | any;


  loadNotificationPayload = {
    "header": {
      "message_uuid": "",
      "message_name": "admin_notifications-R",
      "message_type": "Q",
      "correlation_uuid": "",
      "request_id": "",
      "version": "1.0",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      "agent_cognito_id": ""
    }
  };

  getInsuranceTypesPayLoad = {
    "header": {
      "message_uuid": "",
      "message_name": "insurance_type-R",
      "message_type": "Q",
      "correlation_uuid": "",
      "request_id": "",
      "version": "1.0",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      // "agent_cognito_id": "",
      "start": 0,
      "limit": 5
    }
  };
  insuranceTypes: any[] = [];

  readInsurancePayLoad = {
    "header": {
      "message_uuid": "",
      "message_name": "admin_notifications-U",
      "message_type": "Q",
      "correlation_uuid": "",
      "request_id": "",
      "version": "1.0",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      "agent_cognito_id": "",
      "sequence_id": ""
    }
  };


  constructor(private insuranceService: InsuranceService, private authenticationService: AuthenticationService, private userDataService: UserDataService,
    private profileService: ProfileService, private cryptoService: CryptoService,
    private insuranceDataService: InsuranceDataService,
    private commanService: CommonService,
    private router: Router, private appState: AppState) {

    // this.Menu=Menu.ROUTES
    this.isLoggedIn = false;
    this.menuItems = this.Menu; //Menu.ROUTES;
    this.userData = this.userDataService.getUserData();
    if (this.userData) {
      this.isLoggedIn = true;
      // this.userData.email.substring(0,1).toUpperCase()
      this.profile_image= this.userData != null && this.userData != undefined ?
      this.InitalCharacterURL + '/coverages-icons-disabled/' + this.userData.email.toString().charAt(0).toLowerCase()+'-disabled.png':
      this.AssetsURL+"/img/User_Profile_Icon.png";
    }
    this.getInsuranceTypesPayLoad.header.created_by = this.userData.cognitoId;
    // this.getInsuranceTypesPayLoad.body.agent_cognito_id = this.userData.cognitoId;
    this.loadNotificationPayload.header.created_by = this.userData.cognitoId;
    this.loadNotificationPayload.body.agent_cognito_id = this.userData.cognitoId;

    this.readInsurancePayLoad.header.created_by = this.userData.cognitoId;
    this.readInsurancePayLoad.body.agent_cognito_id = this.userData.cognitoId;

    this.getInsuranceTypes();
    this.loadNotifications();
    this.fetchNotificationsJob();
  }

  public signOut() {
    this.authenticationService.logout();
  }


  public loadNotifications() {
    let responseData: any = this.profileService.getUsersDetails(this.loadNotificationPayload);
    if (responseData) {
        if (responseData && responseData.userInsurances && responseData.userInsurances.length > 0) {
          this.notificationCount = responseData.userInsurances.length;
          this.notifications = responseData.userInsurances;
        } else {
          this.notificationCount = 0;
          this.notifications = [];
        }
    }
  }

  async getInsuranceTypes() {
    // check insuranceType having data or not
    this.insuranceDataService.getInsuranceType.subscribe((result: any) => {
      this.insuranceTypes = result;
    });

    if (this.insuranceTypes == null) {
      let parseResponse: any = await this.insuranceService.getInsurances(this.getInsuranceTypesPayLoad);

      if (parseResponse) {
        this.insuranceTypes = parseResponse.insuranceTypes;
        this.insuranceDataService.setInsurancetype(this.insuranceTypes);
      }
    }

  }

  getNotificationText(notif: any) {

    let type = this.insuranceTypes.find(i => i.id === notif.insuranceType);
    let insuranceTitle = "";
    if (type) {
      insuranceTitle = type.title;
    }
    let notification = notif.first_name + " " + notif.last_name + " added " + insuranceTitle + " insurance";
    return notification;
  }

  notification(notif: any) {
    let insurance = {
      id: notif.insuranceSequenceId,
      firstName: notif.first_name,
      lastName: notif.last_name,
      email: notif.email,
      insuranceType: notif.insuranceType,
      cognitoUid: notif.cognitoUid
    };
    this.readNotification(notif);
    this.appState.set("INSURANCE", insurance);
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/update-insurances']);
    });

  }

  readNotification(notif: any) {

    //encrypt the created_by as cognitoId
    var cognitoIdEnc = this.cryptoService.getBase64String(this.readInsurancePayLoad.header.created_by);
    this.readInsurancePayLoad.body.sequence_id = notif.id;

    //stringify the payload
    var payload1 = JSON.stringify(this.readInsurancePayLoad).toString();
    var customerListPayload = this.cryptoService.getEncryptedBody(this.readInsurancePayLoad.header.created_by, payload1);
    let responseObs: any = this.insuranceService.getInsurances({
      "headers": { "x-shyld-app-id": cognitoIdEnc },
      "body": customerListPayload.toString()
    });

    responseObs.subscribe(
      (data: any) => {
        let responseData: any = data;
        let response: any = this.cryptoService.getDecryptedBody(this.readInsurancePayLoad.header.created_by, responseData.body);
        let parsedResponse = JSON.parse(response);
        this.loadNotifications();
      }
    );
  }

  fetchNotificationsJob() {
    setInterval(() => {
      // this.loadNotifications();
    }, 10000)
  }


}
