<!-- <div class="chat-container">
  <h5 class="ml-5 header-text font-family-NB">Chat</h5>
  <div
    class="chat"
    fxLayout
    fxLayout.lg="row"
    fxLayout.md="row"
    fxLayout.xs="column"
    fxLayoutAlign="space-between"
    fxLayoutGap="10px"
    fxLayoutGap.xs="20px"
  >
    <mat-card
      class="h-70 mb-3"
      style="padding: 0 !important"
      fxLayout
      fxLayout="column"
      fxLayout.xs="column"
      fxFlex="30%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
    >
      <nav mat-tab-nav-bar fxLayout="row">
        <a
          mat-tab-link
          fxLayout
          fxLayout="row"
          fxLayout.xs="column"
          fxFlex="100%"
          *ngFor="let link of links"
          [ngClass]="activeLink == link ? 'activeLink' : 'link'"
          (click)="tabChange(link)"
          [active]="activeLink == link"
        >
          <span *ngIf="link == 'My Chats'">
            <span
              *ngIf="totalUnreadMsgMyChat > 0"
              matBadge="{{ totalUnreadMsgMyChat }}"
              matBadgeColor="accent"
              matBadgeOverlap="false"
              matBadgePosition="above"
              >{{ link }} &nbsp;</span
            >
            <span *ngIf="totalUnreadMsgMyChat <= 0">{{ link }}</span>
          </span>
          <span *ngIf="link == 'Pending'">
            <span
              *ngIf="totalUnreadMsgPending > 0"
              matBadge="{{ totalUnreadMsgPending }}"
              matBadgeColor="accent"
              matBadgeOverlap="false"
              matBadgePosition="above"
              >{{ link }} &nbsp;</span
            >
            <span *ngIf="totalUnreadMsgPending <= 0">{{ link }}</span>
          </span>
          <span *ngIf="link == 'Idle'">
            <span
              *ngIf="totalUnreadMsgIdle > 0"
              matBadge="{{ totalUnreadMsgIdle }}"
              matBadgeColor="accent"
              matBadgeOverlap="false"
              matBadgePosition="above"
              >{{ link }} &nbsp;</span
            >
            <span *ngIf="totalUnreadMsgIdle <= 0">{{ link }}</span>
          </span>
        </a>
      </nav>
      <div class="container h-70 mb-3">
        <div class="mt-3" fxLayout fxLayout="column">
          <div class="user-list" fxLayout fxLayout="column" fxFlex="100%">
            <form class="search-form">
              <mat-form-field class="search" appearance="outline">
                <mat-label>Search</mat-label>
                <input
                  matInput
                  placeholder="Search"
                  class="form-control"
                  (input)="searchUser($event)"
                  [(ngModel)]="search"
                  [ngModelOptions]="{ standalone: true }"
                />
              </mat-form-field>
            </form>
            <mat-spinner *ngIf="isLoading" [diameter]="50"></mat-spinner>
            <div
              fxLayout
              fxLayout="column"
              *ngIf="myChatUsers.length > 0"
              [ngClass]="activeLink == 'My Chats' ? '' : 'd-none'"
            >
              <mat-card
                class="example-card"
                style="margin: 0.5rem 1rem 0.2rem 0rem"
                fxLayout
                fxFlex="100%"
                *ngFor="let user of myChatUsers"
                [ngClass]="isSelectedUser == user.id ? 'active' : 'no-active'"
                fxLayoutGap="20px"
              >
                <mat-card-header
                  fxLayout
                  fxLayout="row"
                  fxFlex="100%"
                  fxLayoutAlign="space-between"
                  class="user-listing-card"
                  (click)="loadChats(user)"
                >
                  <div
                    fxLayout
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                    fxFlex="100%"
                    fxLayoutGap="20px"
                  >
                    <div fxLayout fxLayout="row" fxLayoutAlign="space-around">
                      <img
                        mat-card-avatar
                        fxLayout
                        fxLayout="column"
                        class="rounded-circle color"
                        [src]="
                          user.profile_img
                            ? user.profile_img
                            : '/assets/img/user1.png'
                        "
                        alt="User Profile"
                        width="50px"
                        height="50px"
                      />
                      <span
                        fxLayout
                        fxLayout="column"
                        class="online d-none"
                        matBadge="1"
                        matBadgeColor="primary"
                        matBadgePosition="after"
                        matBadgeSize="small"
                        matBadgeOverlap="true"
                      ></span>
                    </div>
                    <div
                      fxLayout
                      fxLayout="column"
                      fxLayoutAlign="space-between"
                      fxFlex="86%"
                    >
                      <mat-card-title
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        fxFlexOrder="2"
                      >
                        <span
                          fxLayout="row"
                          fxFlex="70%"
                          class="header-text word-wrap-name"
                          >{{ user.user_name }}</span
                        >
                        <span fxLayout="row" fxFlex="30%">{{
                          user.last_message_timestamp
                        }}</span>
                      </mat-card-title>
                      <mat-card-subtitle
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        fxFlexOrder="3"
                      >
                        <span
                          fxLayout
                          fxLayoutAlign="start"
                          fxFlex="70%"
                          class="word-wrap"
                        >
                          {{ decryptMessage(user.last_message) }}
                        </span>
                        <span
                          fxLayout
                          fxLayoutAlign="center"
                          class="badge-container"
                          matBadge="{{ user.unreadMsgCount }}"
                          matBadgeColor="accent"
                          matBadgeOverlap="false"
                          matBadgePosition="before"
                        >
                        </span>
                      </mat-card-subtitle>
                    </div>
                  </div>
                </mat-card-header>
              </mat-card>
            </div>
            <div
              *ngIf="myChatUsers.length == 0"
              [ngClass]="activeLink == 'My Chats' ? '' : 'd-none'"
            >
              <span class="text-center">No User Found</span>
            </div>
            <div
              fxLayout
              fxLayout="column"
              *ngIf="pendingUsers.length > 0"
              [ngClass]="activeLink == 'Pending' ? '' : 'd-none'"
            >
              <mat-card
                class="example-card"
                style="margin: 0.5rem 1rem 0.2rem 0rem"
                fxLayout
                fxFlex="100%"
                *ngFor="let user of pendingUsers"
                [ngClass]="isSelectedUser == user.id ? 'active' : 'no-active'"
                fxLayoutGap="20px"
              >
                <mat-card-header
                  fxLayout
                  fxLayout="row"
                  fxFlex="100%"
                  fxLayoutAlign="space-between"
                  class="user-listing-card"
                  (click)="loadChats(user)"
                >
                  <div
                    fxLayout
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                    fxFlex="100%"
                    fxLayoutGap="20px"
                  >
                    <div fxLayout fxLayout="row" fxLayoutAlign="space-around">
                      <img
                        mat-card-avatar
                        fxLayout
                        fxLayout="column"
                        class="rounded-circle color"
                        [src]="
                          user.profile_img
                            ? user.profile_img
                            : AssetsURL + 'img/user1.png'
                        "
                        alt="User Profile"
                        width="50px"
                        height="50px"
                      />
                      <span
                        fxLayout
                        fxLayout="column"
                        class="online d-none"
                        matBadge="{{ user.unreadMsgCount }}"
                        matBadgeColor="primary"
                        matBadgePosition="after"
                        matBadgeSize="small"
                        matBadgeOverlap="true"
                      ></span>
                    </div>
                    <div
                      fxLayout
                      fxLayout="column"
                      fxLayoutAlign="space-between"
                      fxFlex="86%"
                    >
                      <mat-card-title
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        fxFlexOrder="2"
                      >
                        <span
                          fxLayout="row"
                          fxFlex="70%"
                          class="header-text word-wrap-name"
                          >{{ user.user_name }}</span
                        >
                        <span fxLayout="row" fxFlex="30%">{{
                          user.last_message_timestamp
                        }}</span>
                      </mat-card-title>
                      <mat-card-subtitle
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        fxFlexOrder="3"
                      >
                        <span
                          fxLayout
                          fxLayoutAlign="start"
                          fxFlex="70%"
                          class="word-wrap"
                        >
                          {{ decryptMessage(user.last_message) }}
                        </span>
                        <span
                          fxLayout
                          fxLayoutAlign="center"
                          class="badge-container"
                          matBadge="{{ user.unreadMsgCount }}"
                          matBadgeColor="accent"
                          matBadgeOverlap="false"
                          matBadgePosition="before"
                        >
                        </span>
                      </mat-card-subtitle>
                    </div>
                  </div>
                </mat-card-header>
              </mat-card>
            </div>
            <div
              *ngIf="pendingUsers.length == 0"
              [ngClass]="activeLink == 'Pending' ? '' : 'd-none'"
            >
              <span class="text-center">No User Found</span>
            </div>
            <div
              fxLayout
              fxLayout="column"
              *ngIf="idleUsers.length > 0"
              [ngClass]="activeLink == 'Idle' ? '' : 'd-none'"
            >
              <mat-card
                class="example-card"
                style="margin: 0.5rem 1rem 0.2rem 0rem"
                fxLayout
                fxFlex="100%"
                *ngFor="let user of idleUsers"
                [ngClass]="isSelectedUser == user.id ? 'active' : 'no-active'"
                fxLayoutGap="20px"
              >
                <mat-card-header
                  fxLayout
                  fxLayout="row"
                  fxFlex="100%"
                  fxLayoutAlign="space-between"
                  class="user-listing-card"
                  (click)="loadChats(user)"
                >
                  <div
                    fxLayout
                    fxLayout="row"
                    fxLayoutAlign="space-between"
                    fxFlex="100%"
                    fxLayoutGap="20px"
                  >
                    <div fxLayout fxLayout="row" fxLayoutAlign="space-around">
                      <img
                        mat-card-avatar
                        fxLayout
                        fxLayout="column"
                        class="rounded-circle color"
                        [src]="
                          user.profile_img
                            ? user.profile_img
                            : AssetsURL + 'img/user1.png'
                        "
                        alt="User Profile"
                        width="50px"
                        height="50px"
                      />
                      <span
                        fxLayout
                        fxLayout="column"
                        class="online d-none"
                        matBadge="1"
                        matBadgeColor="primary"
                        matBadgePosition="after"
                        matBadgeSize="small"
                        matBadgeOverlap="true"
                      ></span>
                    </div>
                    <div
                      fxLayout
                      fxLayout="column"
                      fxLayoutAlign="space-between"
                      fxFlex="86%"
                    >
                      <mat-card-title
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        fxFlexOrder="2"
                      >
                        <span
                          fxLayout="row"
                          fxFlex="70%"
                          class="header-text"
                          class="word-wrap"
                          >{{ user.user_name }}</span
                        >
                        <span fxLayout="row" fxFlex="30%">{{
                          user.last_message_timestamp
                        }}</span>
                      </mat-card-title>
                      <mat-card-subtitle
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        fxFlexOrder="3"
                      >
                        <span
                          fxLayout
                          fxLayoutAlign="start"
                          fxFlex="70%"
                          class="word-wrap"
                        >
                          {{ decryptMessage(user.last_message) }}
                        </span>
                        <span
                          fxLayout
                          fxLayoutAlign="center"
                          class="badge-container"
                          matBadge="{{ user.unreadMsgCount }}"
                          matBadgeColor="accent"
                          matBadgeOverlap="false"
                          matBadgePosition="before"
                        >
                        </span>
                      </mat-card-subtitle>
                    </div>
                  </div>
                </mat-card-header>
              </mat-card>
            </div>
            <div
              *ngIf="idleUsers.length == 0"
              [ngClass]="activeLink == 'Idle' ? '' : 'd-none'"
            >
              <span class="text-center">No User Found</span>
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <div
      fxLayout="column"
      class="h-70"
      fxLayout.xs="row"
      fxLayout.sm="row"
      fxFlex="70%"
      fxFlex.sm="100%"
      fxFlex.xs="100%"
      fxLayoutGap="10px"
    >
      <mat-card
        class="chat-area-card mb-3"
        fxLayout
        fxLayout="column"
        fxFlex="100%"
        *ngIf="!selectedUser"
      >
        <mat-card-header></mat-card-header>
        <mat-card-content
          class="chat-area-msg-no-data"
          fxLayout
          fxFlex="100%"
          fxLayoutAlign="center"
          ><img
            [src]="AssetsURL + 'img/no_data_found.png'"
            fxLayoutAlign="center"
            fxLayout
            fxFlex="50"
            class="chat-area-msg-no-data mt-5"
          />
        </mat-card-content>
        <mat-card-footer></mat-card-footer>
      </mat-card>
      <mat-card
        class="chat-area-card mb-3"
        fxLayout
        fxLayout="column"
        fxFlex="94%"
        *ngIf="selectedUser"
      >
        <mat-card-header
          class="chat-area-header"
          fxLayout="column"
          fxLayoutAlign.sm="space-between"
          fxFlex="100%"
        >
          <div fxLayout fxLayout="row" fxFlex="100">
            <div
              fxLayout
              fxLayout="column"
              fxFlex="55px"
              fxFlexOffset="10px"
              fxLayoutAlign="start"
            >
              <div
                fxLayout
                fxLayout="row"
                fxLayoutAlign="space-around"
                (click)="customerDetails(selectedUser)"
              >
                <img
                  mat-card-avatar
                  fxLayout
                  fxLayout="column"
                  class="rounded-circle color-chat-area"
                  [src]="
                    selectedUser.profile_img
                      ? selectedUser.profile_img
                      : AssetsURL + 'img/user1.png'
                  "
                  alt="User Profile"
                  width="50px"
                  height="50px"
                />
                <span
                  fxLayout
                  fxLayout="column"
                  class="online-chat-area d-none"
                  matBadge="1"
                  matBadgeColor="primary"
                  matBadgePosition="after"
                  matBadgeSize="small"
                  matBadgeOverlap="true"
                >
                </span>
              </div>
            </div>
            <div fxLayout fxLayout="column" fxFlex="88">
              <span
                fxLayout
                fxLayout="row"
                fxFlex="100%"
                fxLayoutAlign="space-between"
              >
                <mat-card-title fxLayout="column" fxFlex="70%">
                  <span
                    class="mt-2 cursor-pointer"
                    (click)="customerDetails(selectedUser)"
                  >
                    {{ selectedUser.user_name || "" }}
                  </span>
                </mat-card-title>
                <button
                  type="button"
                  mat-button
                  [disableRipple]="true"
                  *ngIf="
                    selectedUser.status == 'waiting' ||
                    selectedUser.status == 'idle'
                  "
                  (click)="assign()"
                >
                  <span
                    fxLayout
                    fxLayout="row"
                    fxFlex="65px"
                    class="btn btn-sm btn-success accept-btn"
                  >
                    Accept
                  </span>
                </button>

                <button
                  type="button"
                  mat-button
                  [disableRipple]="true"
                  *ngIf="selectedUser.status == 'assigned'"
                  (click)="complete()"
                >
                  <span
                    fxLayout
                    fxLayout="row"
                    class="btn btn-sm btn-success accept-btn"
                  >
                    Resolved
                  </span>
                </button>
              </span>
            </div>
          </div>
        </mat-card-header>
        <mat-card-content
          class="chat-area-msg"
          fxLayout
          fxLayout="column"
          fxFlex="100"
          fxLayoutGap="10px"
          fxFlexFill
          #scrollMe
          [scrollTop]="scrollMe.scrollHeight"
        >
          <div fxLayout fxLayout="row" fxFlex="100" class="d-none">
            <div
              style="
                width: 100%;
                height: 20px;
                border-bottom: 1px solid black;
                text-align: center;
              "
            >
              <span
                style="
                  font-size: 20px;
                  background-color: #f3f5f6;
                  padding: 0 10px;
                "
              >
                12th Dec, 2020
              </span>
            </div>
          </div>

          <div class="chatwrapper" fxLayout="column">
            <div
              fxLayout="column"
              *ngFor="let msg of chatMessages; let i = index"
            >
              <div
                fxLayout
                fxLayout="row"
                fxFlex="50"
                fxFlex.sm="50"
                fxFlex.xs="50"
                class="mt-2 ml-3"
                [class.float-right]="fromMe"
                [class.border-primary]="fromMe"
                fxLayoutAlign="start"
                *ngIf="msg.from == 'user'"
              >
                <div
                  fxLayout
                  fxLayout="column"
                  fxFlex="55px"
                  fxLayoutAlign="start"
                >
                  <img
                    mat-card-avatar
                    class="rounded-circle"
                    [src]="
                      msg.profile_img
                        ? msg.profile_img
                        : AssetsURL + 'img/user1.png'
                    "
                    alt="User Profile Icon"
                    width="50px"
                    height="50px"
                  />
                </div>
                <div fxLayout fxLayout="column">
                  <div
                    class="card mb-2 chat-message"
                    [ngClass]="
                      fromMe ? 'rounded-diff-sender' : 'rounded-diff-receiver '
                    "
                  >
                    <div class="card-body p-2 border-0">
                      <div
                        class="clearfix"
                        fxLayout
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        fxFlex="100"
                      >
                        <span *ngIf="msg.message">{{
                          decryptMessage(msg.message)
                        }}</span>

                        <span
                          *ngIf="
                            msg.attachment_url &&
                            (msg.mime_type == 'image/png' ||
                              msg.mime_type == 'image/jpg' ||
                              msg.mime_type == 'image/jpeg')
                          "
                        >
                          <a
                            href="{{ decryptMessage(msg.attachment_url) }}"
                            target="_blank"
                          >
                            <img
                              src="{{ decryptMessage(msg.attachment_url) }}"
                              class="image-blur rounded"
                              width="150px"
                              height="80px"
                            />
                          </a>
                          <span class="download">
                            <mat-icon
                              class="material-icons-outlined vm-ml mt-1 ml-2"
                              (click)="downloadFile(msg, i)"
                              >get_app</mat-icon
                            >
                          </span>
                        </span>

                        <span
                          *ngIf="
                            msg.attachment_url &&
                            msg.mime_type == 'application/pdf'
                          "
                        >
                          <a
                            href="{{ decryptMessage(msg.attachment_url) }}"
                            target="_blank"
                          >
                            <mat-icon class="material-icons-outlined vm-m"
                              >picture_as_pdf
                            </mat-icon>
                            Attachment{{ i }}.pdf
                          </a>
                          <mat-icon
                            class="material-icons-outlined vm-m"
                            (click)="downloadFile(msg, i)"
                            >get_app</mat-icon
                          >
                        </span>
                      </div>
                    </div>
                  </div>
                  <span>{{ msg.timestamp | momentAgo }}</span>
                </div>
              </div>

              <div
                fxLayout
                fxLayout="row"
                fxLayoutAlign="end"
                fxFlex="50"
                fxFlex.sm="50"
                fxLayoutGap="10px"
                fxFlex.xs="50"
                [class.float-right]="fromMe"
                class="mt-1 mr-3"
                [class.border-primary]="fromMe"
                *ngIf="
                  msg.from == 'support' &&
                  msg.support_id == loggedInUser.cognitoId
                "
              >
                <div fxLayout fxLayout="column" fxFlex="55px" fxFlexOrder="2">
                  <img
                    mat-card-avatar
                    class="mt-4 rounded-circle"
                    [src]="
                      msg.profile_img
                        ? msg.profile_img
                        : AssetsURL + 'img/user1.png'
                    "
                    alt="User Profile Icon"
                    width="50px"
                    height="50px"
                  />
                </div>
                <div class="mt-3" fxLayout fxLayout="column" fxFlexOrder="1">
                  <span fxLayout fxLayout="row" fxLayoutAlign="end">
                    {{ msg.support_name }}
                  </span>
                  <div
                    class="card mb-2 chat-message"
                    [ngClass]="
                      !fromMe ? 'rounded-diff-sender' : 'rounded-diff-receiver'
                    "
                  >
                    <div class="card-body p-2 border-0">
                      <div
                        class="clearfix"
                        fxLayout
                        fxLayout="row"
                        fxLayoutAlign="space-between"
                        fxFlex="100"
                      >
                        <span *ngIf="msg.message">{{
                          decryptMessage(msg.message)
                        }}</span>
                        <span
                          *ngIf="
                            msg.attachment_url &&
                            (msg.mime_type == 'image/png' ||
                              msg.mime_type == 'image/jpg' ||
                              msg.mime_type == 'image/jpeg')
                          "
                        >
                          <a
                            href="{{ decryptMessage(msg.attachment_url) }}"
                            target="_blank"
                          >
                            <img
                              src="{{ decryptMessage(msg.attachment_url) }}"
                              class="img-thumbnail rounded"
                              width="150px"
                              height="80px"
                            />
                          </a>
                        </span>

                        <span
                          *ngIf="
                            msg.attachment_url &&
                            msg.mime_type == 'application/pdf'
                          "
                        >
                          <mat-icon class="material-icons-outlined vm-m"
                            >picture_as_pdf
                          </mat-icon>
                          Attachment{{ i }}.pdf

                          <a
                            href="{{ decryptMessage(msg.attachment_url) }}"
                            target="_blank"
                          >
                            <mat-icon class="material-icons-outlined vm-m"
                              >open_in_new
                            </mat-icon>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  <span fxLayout fxLayout="row" fxLayoutAlign="end">
                    {{ msg.timestamp | momentAgo }}

                    <span class="ml-2" *ngIf="msg.user_read">
                      <mat-icon
                        class="material-icons-outlined"
                        style="color: #34b7f1"
                        >done_all
                      </mat-icon>
                    </span>
                    <span class="ml-2" *ngIf="!msg.user_read">
                      <mat-icon class="material-icons-outlined">done</mat-icon>
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div> -->

<div class="chat-container">
  <div id="iFrameWrapper">
    <app-my-component></app-my-component>
  </div>
</div>
