import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '../../../services/app.state';
import { UserInterface } from '../../../interfaces/user.interface';
import { MatSortable, Sort } from '@angular/material/sort';
import { InsuranceDataService } from '../../../services/insurance-data.service';
import { UserDataService } from '../../../services/user-data.service';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FilterDialogComponent } from '../../shared/filter-dialog/filter-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { CommonService } from '../../../services/common.service';
import { AddInsuranceComponent } from '../../insurance/add-insurance/add-insurance.component';
import { InsuranceService } from '../../../services/insurance.service';

@Component({
  selector: 'app-list-insurance',
  templateUrl: './list-insurance.component.html',
  styleUrls: ['./list-insurance.component.css'],
})
export class ListInsuranceComponent implements OnInit {
  userData: UserInterface | undefined;
  displayedColumns: string[] = [
    'name',
    'phoneNumber',
    'email',
    'insuranceType',
    'status',
    'createdDate',
    'action',
  ];

  search: string = '';
  isLoading: boolean = true;
  isFilter: boolean = false;

  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  @ViewChild(MatPaginator) paginator: MatPaginator | any = [];
  @ViewChild(MatSort) sort: MatSort | any = [];

  page: any = { start: 0, limit: 10 };
  sortD: any = { sortField: 'createdDate', sortOrder: 'desc' };

  getInsurancesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'admin-insurances-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      start: 0,
      limit: 5,
      name: '',
      phoneNumber: '',
      email: '',
      insuranceType: '',
      status: '', // true - Verified, false - Pending,
      fromDate: '', //2022-01-09
      toDate: '', //2022-01-09,
      sortField: 'createdDate', // name, email, status, insuranceType, createdDate
      sortOrder: 'desc', // asc, desc
    },
  };

  getInsuranceTypesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'insurance_type-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      // "agent_cognito_id": "",
      start: 0,
      limit: 5,
    },
  };

  getDeleteInsurancePayLoad = {
    header: {
      message_uuid: '',
      message_type: 'Q',
      version: '1.0',
      message_name: 'admin_user_insurance_policy-D',
      correlation_uuid: '',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      user_id: '',
      insurance_id: '',
    },
  };

  userInsurances: any[] = [];
  insuranceTypes: any[] = [];

  totalRecords = 0;

  constructor(
    private userDataService: UserDataService,
    private appState: AppState,
    private router: Router,
    public dialog: MatDialog,
    private insuranceDataService: InsuranceDataService,
    private insuranceService: InsuranceService,
    private datepipe: DatePipe,
    public snackBar: MatSnackBar,
    private commanService: CommonService
  ) {}

  ngOnInit() {
    this.userData = this.userDataService.getUserData();
    this.getInsurancesPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getInsuranceTypesPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';

    this.getInsurancesPayLoad.body.start = this.page.start;
    this.getInsurancesPayLoad.body.limit = this.page.limit;

    this.getInsurancesPayLoad.body.sortField = this.sortD.sortField;
    this.getInsurancesPayLoad.body.sortOrder = this.sortD.sortOrder;

    this.insuranceDataService.getInsuranceType.subscribe((result: any) => {
      this.insuranceTypes = result;
    });

    if (this.insuranceTypes == null) {
      this.getInsuranceTypes();
    }

    this.getUserInsurances();
  }

  async getInsuranceTypes() {
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsuranceTypesPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.insuranceTypes = parseResponse.insuranceTypes;
      this.insuranceDataService.setInsurancetype(this.insuranceTypes);
    }
  }

  clearFilter = () => {
    this.isFilter = false;
    // this.getInsurancesPayLoad.body.start = 0;
    // this.getInsurancesPayLoad.body.limit = 10;
    // this.getInsurancesPayLoad.body.sortField = this.sortD.sortField;
    // this.getInsurancesPayLoad.body.sortOrder = this.sortD.sortOrder;
    this.getInsurancesPayLoad.body.name = '';
    this.getInsurancesPayLoad.body.email = '';
    this.getInsurancesPayLoad.body.phoneNumber = '';
    this.getInsurancesPayLoad.body.insuranceType = '';
    this.getInsurancesPayLoad.body.status = '';
    this.getInsurancesPayLoad.body.fromDate = '';
    this.getInsurancesPayLoad.body.toDate = '';
    this.getUserInsurances();
  };

  async deleteInsurance() {
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getDeleteInsurancePayLoad
    );

    if (parseResponse) {
      this.snackBar.open(parseResponse.message, 'close', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
      this.getUserInsurances();
    }
  }

  deleteInsuranceDialog(row: any) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        header: 'Delete',
        description: `Are You Sure want to delete Insurance and If you delete the Insurance then IVS is also delete.`,
        // description: `Are you sure want to delete ${row.insuranceType} ?`,
        id: row.id,
        action: 'DELETE',
        okbutton: 'DELETE',
        cancelbutton: 'CANCEL',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.event == 'DELETE') {
        this.getDeleteInsurancePayLoad.header.created_by =
          this.userData != undefined ? this.userData.cognitoId : '';
        this.getDeleteInsurancePayLoad.body.user_id =
          row.userId != undefined ? row.userId : '';
        this.getDeleteInsurancePayLoad.body.insurance_id = row.id;
        this.deleteInsurance();
      }
    });
  }

  openFilterDialog = () => {
    let insuranceStatus =
      this.getInsurancesPayLoad.body.status.trim() != ''
        ? this.getInsurancesPayLoad.body.status.trim() == 'true'
          ? 'Verified'
          : this.getInsurancesPayLoad.body.status.trim() == 'false'
          ? 'Pending'
          : ''
        : '';

    const dialogRef = this.dialog.open(FilterDialogComponent, {
      data: {
        header: 'Search Insurance',
        allInsuranceType: this.insuranceTypes,
        name: this.getInsurancesPayLoad.body.name,
        email: this.getInsurancesPayLoad.body.email,
        phoneNumber: this.getInsurancesPayLoad.body.phoneNumber,
        insuranceType: this.getInsurancesPayLoad.body.insuranceType,
        insuranceStatus: insuranceStatus,
        fromDate: this.getInsurancesPayLoad.body.fromDate,
        toDate: this.getInsurancesPayLoad.body.toDate,
        type: 'insurance',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.action == 'Search') {
        // this.getInsurancesPayLoad.body.start = 0;
        // this.getInsurancesPayLoad.body.limit = 10;

        // this.getInsurancesPayLoad.body.sortField = this.sortD.sortField;
        // this.getInsurancesPayLoad.body.sortOrder = this.sortD.sortOrder;

        this.isFilter = true;

        let name = result.filterInsurance.name.trim();
        let email = result.filterInsurance.email.trim();
        let phoneNumber = result.filterInsurance.phoneNumber.trim();

        let insuranceType =
          result.filterInsurance.insuranceType.length > 0
            ? result.filterInsurance.insuranceType.join(',')
            : '';

        let insuranceStatus =
          result.filterInsurance.insuranceStatus.trim() == 'Verified'
            ? 'complete'
            : result.filterInsurance.insuranceStatus.trim() == 'Pending'
            ? 'pending'
            : '';
        let fromDate =
          result.filterInsurance.fromDate != '' &&
          result.filterInsurance.fromDate != null
            ? '' +
              this.datepipe.transform(
                result.filterInsurance.fromDate,
                'yyyy-MM-dd'
              )
            : '';
        let toDate =
          result.filterInsurance.toDate != '' &&
          result.filterInsurance.toDate != null
            ? '' +
              this.datepipe.transform(
                result.filterInsurance.toDate,
                'yyyy-MM-dd'
              )
            : '';
        this.getInsurancesPayLoad.body.name = '';
        this.getInsurancesPayLoad.body.email = '';
        this.getInsurancesPayLoad.body.phoneNumber = '';
        this.getInsurancesPayLoad.body.insuranceType = '';
        this.getInsurancesPayLoad.body.status = '';
        this.getInsurancesPayLoad.body.fromDate = '';
        this.getInsurancesPayLoad.body.toDate = '';

        if (name != '') this.getInsurancesPayLoad.body.name = name;
        if (email != '') this.getInsurancesPayLoad.body.email = email;
        if (phoneNumber != '')
          this.getInsurancesPayLoad.body.phoneNumber = phoneNumber;
        if (insuranceType != '')
          this.getInsurancesPayLoad.body.insuranceType = insuranceType;
        if (insuranceStatus != '')
          this.getInsurancesPayLoad.body.status = insuranceStatus;
        if (fromDate != '')
          this.getInsurancesPayLoad.body.fromDate = fromDate.trim();
        if (toDate != '') this.getInsurancesPayLoad.body.toDate = toDate.trim();

        this.paginator.firstPage();
        this.getUserInsurances();
      }
    });
  };

  async getUserInsurances() {
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsurancesPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.userInsurances = parseResponse.userInsurances;
      this.dataSource.data = this.userInsurances;
      this.totalRecords = parseResponse.totalRecords;
      this.paginator.length = this.totalRecords;
    }
  }

  //ngAfterViewInit() {
  //   this.sort.sortChange.emit(this.sortD);
  // this.dataSource.paginator = this.paginator;
  // this.dataSource.sort = this.sort;
  // }

  sortData(sort: Sort) {
    this.sortD.sortField = sort.active;
    this.sortD.sortOrder = sort.direction;
    // this.sort.sortChange.emit(this.sortD);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getInsurancesPayLoad.body.start = this.page.start;
    this.getInsurancesPayLoad.body.limit = this.page.limit;

    this.getInsurancesPayLoad.body.sortField = this.sortD.sortField;
    this.getInsurancesPayLoad.body.sortOrder = this.sortD.sortOrder;
    this.getUserInsurances();
  }

  pageChanged(event: any) {
    this.page.limit = event.pageSize;
    this.page.start = event.pageIndex * event.pageSize;
    this.getInsurancesPayLoad.body.start = this.page.start;
    this.getInsurancesPayLoad.body.limit = this.page.limit;
    this.getUserInsurances();
  }

  editInsurance(i: any) {
    this.appState.set('INSURANCE', i);
    this.appState.set('ROUTE', '/insurances');
    this.router.navigate(['/update-insurances']);
  }

  getCreate(created: any) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'MM-dd-yyyy  ::  h:mm a');
  }

  getInsuranceType(key: any) {
    let type = this.insuranceTypes.find((i) => i.id === key);
    if (type) {
      return type.title;
    } else {
      return '';
    }
  }

  addInsuranceDialog(): void {
    const dialogRef = this.dialog.open(AddInsuranceComponent, {
      width: '500px',
      data: { header: 'Add Insurance', type: 'add' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result.action == 'add') {
        this.getUserInsurances();
      }
    });
  }
}
