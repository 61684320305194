import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent implements OnInit {
  dialogData: any;
  errorMessage: string = '';

  // customer filter form
  filterCustomer: FormGroup = this.fb.group({
    name: ['', [Validators.pattern('^[a-zA-Z ]*$')]],
    phoneNumber: ['', [Validators.pattern('^[0-9]*$')]],
    email: [
      '',
      [
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,63}$'),
      ],
    ],
    // activePolicies: [''],
    // comingRenewals: [''],
  });

  // insurance filter form
  filterInsurance: FormGroup = this.fb.group({
    name: ['', [Validators.pattern('^[a-zA-Z ]*$')]],
    phoneNumber: ['', [Validators.pattern('^[0-9]*$')]],
    email: [
      '',
      [
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,63}$'),
      ],
    ],
    insuranceType: [''],
    insuranceStatus: [''],
    fromDate: [''],
    toDate: [''],
  });

  // insurance filter form
  filterLeads: FormGroup = this.fb.group({
    search: ['', [Validators.pattern('^[a-zA-Z ]*$')]],
    insurance_type: [''],
  });

  // article filter form
  filterArticle = this.fb.group({
    title: ['', [Validators.pattern('^[a-zA-Z0-9 ]*$')]],
    name: ['', [Validators.pattern('^[a-zA-Z0-9 ]*$')]],
    learn_date: [''],
  });

  insuranceType: any[];
  insuranceStatus: string[] = ['Pending', 'Verified'];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<FilterDialogComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datepipe: DatePipe
  ) {
    this.dialogData = { ...data };

    if (this.dialogData.type == 'customer') {
      this.filterCustomer = this.fb.group({
        name: [this.dialogData.name, [Validators.pattern('^[a-zA-Z ]*$')]],
        phoneNumber: [
          this.dialogData.phoneNumber,
          [Validators.pattern('^[0-9]*$')],
        ],
        email: [
          this.dialogData.email,
          [
            Validators.email,
            Validators.pattern(
              '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,63}$'
            ),
          ],
        ],
        // activePolicies: [''],
        // comingRenewals: [''],
      });
    } else if (this.dialogData.type == 'insurance') {
      this.insuranceType = this.dialogData.allInsuranceType;
      this.dialogData.insuranceType = this.dialogData.insuranceType.split(',');

      this.filterInsurance = this.fb.group({
        name: [this.dialogData.name, [Validators.pattern('^[a-zA-Z ]*$')]],
        phoneNumber: [
          this.dialogData.phoneNumber,
          [Validators.pattern('^[0-9]*$')],
        ],
        email: [
          this.dialogData.email,
          [
            Validators.email,
            Validators.pattern(
              '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,63}$'
            ),
          ],
        ],
        insuranceType: [this.dialogData.insuranceType],
        insuranceStatus: [this.dialogData.insuranceStatus],
        fromDate: [this.dialogData.fromDate],
        toDate: [this.dialogData.toDate],
      });
    } else if (
      this.dialogData.type == 'customer_leads' ||
      this.dialogData.type == 'agent_leads'
    ) {
      this.insuranceType = this.dialogData.allInsuranceType;
      // this.dialogData.insuranceType = this.dialogData.insuranceType.split(',');

      this.filterLeads = this.fb.group({
        search: [this.dialogData.search, [Validators.pattern('^[a-zA-Z ]*$')]],
        insurance_type: [this.dialogData.insurance_type],
      });
    } else if (this.dialogData.type == 'article') {
      this.filterArticle = this.fb.group({
        name: [this.dialogData.name, [Validators.pattern('^[a-zA-Z0-9 ]*$')]],
        title: [this.dialogData.title, [Validators.pattern('^[a-zA-Z0-9 ]*$')]],
        learn_date: [this.dialogData.learn_date],
      });
    }
  }

  public errorHandling = (control: string, error: string) => {
    return this.dialogData.type == 'customer'
      ? this.filterCustomer.controls[control].hasError(error)
      : this.dialogData.type == 'insurance'
      ? this.filterInsurance.controls[control].hasError(error)
      : this.filterArticle.controls[control].hasError(error);
  };

  ngOnInit(): void {}

  isFieldNotEmpty(obj: any): any {
    if (obj != null && typeof obj === 'object') {
      return Object.values(obj).some((value) => {
        if (Array.isArray(value)) {
          return value.some(
            (item) => typeof item === 'string' && item.trim() !== ''
          );
        }
        return typeof value === 'string' && value.trim() !== '';
      });
    }

    return false;
  }

  applyFilter() {
    if (this.dialogData.type == 'customer') {
      const fieldFilled = this.isFieldNotEmpty(this.filterCustomer.value);
      if (fieldFilled) {
        this.dialogRef.close({
          action: 'Search',
          filterCustomer: this.filterCustomer.value,
        });
      } else {
        this.errorMessage = 'Please enter the details';
      }
    } else if (this.dialogData.type == 'insurance') {
      const fieldFilled = this.isFieldNotEmpty(this.filterInsurance.value);

      if (fieldFilled) {
        this.dialogRef.close({
          action: 'Search',
          filterInsurance: this.filterInsurance.value,
        });
      } else {
        this.errorMessage = 'Please enter the details';
      }
    } else if (this.dialogData.type == 'article') {
      const fieldFilled = this.isFieldNotEmpty(this.filterArticle.value);
      if (fieldFilled) {
        this.dialogRef.close({
          action: 'Search',
          filterArticle: this.filterArticle.value,
        });
      } else {
        this.errorMessage = 'Please enter the details';
      }
    } else if (
      this.dialogData.type == 'customer_leads' ||
      this.dialogData.type == 'agent_leads'
    ) {
      const fieldFilled = this.isFieldNotEmpty(this.filterLeads.value);
      if (fieldFilled) {
        this.dialogRef.close({
          action: 'Search',
          filterLeads: this.filterLeads.value,
        });
      } else {
        this.errorMessage = 'Please enter the details';
      }
    }
  }
  close() {
    this.dialogRef.close({ action: 'Cancel' });
  }
}
