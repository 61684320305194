<mat-dialog-title fxLayout>
    <div class="font-family-NB email-header">{{dialogData.header}}</div>
</mat-dialog-title>
<mat-dialog-content class="mt-2 mb-4">
    <div class="flex-box email_status" *ngFor="let a of emails; let i = index">
        <p class="font-family-NSB">{{a}}</p>
        <p class="send font-family-NSB"  (click)="delete()">{{emailStatus}}</p>
    </div>
</mat-dialog-content>

