<div class="ml-5 mr-5" fxLayout="column">
  <div fxLayout style="align-items: center !important;">
    <h5 class="card-title ml-1 font-family-NB mt-4" fxLayout fxFlex="97">Agent List</h5>
    <button mat-icon-button (click)="clearFilter()" *ngIf="isFilter"
      class="btn btn-yolow btn-clear-filter text-center mr-2" #tooltip="matTooltip" matTooltip="Clear Filter"
      matTooltipPosition="below" fxLayout fxFlex="8" fxLayoutGap="20">
      <mat-icon class="ml-2">clear</mat-icon>Clear Filter
    </button>
    <button mat-icon-button (click)="filterDialog()" class="btn btn-yolow btn-filter text-center"
      #tooltip="matTooltip" matTooltip="Filter list" matTooltipPosition="below" fxLayout fxLayoutGap="20">
      <mat-icon class="ml-2">filter_list</mat-icon>
    </button>
  </div>

  <div class="customer-container font-family-NSB mb-3" fxLayout fxLayout="column" fxLayoutAlign="start"
    fxLayoutGap="5px" fxLayoutGap.xs="0">
    <mat-card class="example-container mb-3"  fxLayout fxLayout="column">
      <!-- mat-elevation-z8 -->
      <mat-card-content class="  font-family-NSB" fxLayout fxLayout="column">
        <div fxLayout fxLayout="column" >
          <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
            [matSortActive]="sortD.sortField" [matSortDirection]="sortD.sortOrder">

            <!-- profilePicture Column -->
            <ng-container matColumnDef="profilePicture">
              <th mat-header-cell *matHeaderCellDef>Profile</th>
              <td mat-cell *matCellDef="let row">
                <img
                  [src]="row.profilePicture && row.profilePicture!='' ? row.profilePicture :
                  AssetsURL+'/img/User_Profile_Icon.png'"
                  class="rounded-circle" width="40px" height="40px" />
              </td>
            </ng-container>

            <!-- Name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="name">Name</th>
              <td mat-cell *matCellDef="let row">{{ row.name }}</td>
            </ng-container>

            <!-- Email Column -->
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="email">Email</th>
              <td mat-cell *matCellDef="let row">{{ row.email }}</td>
            </ng-container>

            <!-- Phone Number Column -->
            <ng-container matColumnDef="personal_phone_number">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="personal_phone_number">Phone Number</th>
              <td mat-cell *matCellDef="let row">{{ row.personal_phone_number }}</td>
            </ng-container>

            <!-- Active Policies Column -->
<!--            <ng-container matColumnDef="state">-->
<!--              <th mat-header-cell *matHeaderCellDef>State</th>-->
<!--              <td mat-cell *matCellDef="let row">{{ row.state }}</td>-->
<!--            </ng-container>-->

<!--            &lt;!&ndash; Upcoming Renewals Column &ndash;&gt;-->
<!--            <ng-container matColumnDef="insuranceType">-->
<!--              <th mat-header-cell *matHeaderCellDef>Insurance Type</th>-->
<!--              <td mat-cell *matCellDef="let row">{{ row.insuranceType }}</td>-->
<!--            </ng-container>-->

            <!-- Upcoming Renewals Column -->
            <ng-container matColumnDef="created_date">
              <th mat-header-cell *matHeaderCellDef  mat-sort-header="created_date">Create Date</th>
              <td mat-cell *matCellDef="let row">{{getCreate(row.created_date)}}</td>
            </ng-container>

            <!-- Action Column -->
            <ng-container matColumnDef="action" >
              <th mat-header-cell *matHeaderCellDef>Action</th>
              <td mat-cell *matCellDef="let row" >
                <span class="d-flex">
                  <!-- <mat-slide-toggle (change)="statusChange($event, row)" [checked]="row.agent_verification_status ==
                  'approved'"></mat-slide-toggle> -->
                <!-- <button  title="Edit" class="btn btn-yolow btn-sm col-md-4">
                      Accept
                    </button> -->
                <a [routerLink]="['/agent-details/', row.cognitoId]" title="Details" class="btn btn-outline-dark btn-sm">
                  <mat-icon class="btn-edit material-icons-outlined">description</mat-icon>
                </a>
                </span>
              </td>
            </ng-container>
            <tr class="mat-row" *matNoDataRow>
              <td [colSpan]="displayedColumns.length" class="mat-cell text-center">No data found</td>
            </tr>

            <tr class="font-family-NB" mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr class="font-family-NSB" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
        <mat-paginator [ngClass]="customers.length > 0 ? 'd-block':'d-none'" class=" font-family-NB ml-max"
          fxLayout fxLayoutAlign="end" fxFlex="100%" fxLayoutGap.xs="0px" fxLayoutGap="10px" (page)="pageChanged($event)"
          [length]="totalRecords" [pageSize]="page.limit" [pageSizeOptions]="[ 10, 25, 50, 100]">
        </mat-paginator>
      </mat-card-content>
    </mat-card>
  </div>
</div>
