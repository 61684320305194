import { Component, OnInit } from '@angular/core';
import { UserDataService } from '../services/user-data.service';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-confirmation-enrollment',
  templateUrl: './confirmation-enrollment.component.html',
  styleUrls: ['./confirmation-enrollment.component.scss']
})
export class ConfirmationEnrollmentComponent implements OnInit {
  typeOfEnrollment: any;
  AssetsURL: string = environment.assetsURL;

  constructor(private router: Router, private userDataService: UserDataService,) { }

  ngOnInit(): void {
    this.typeOfEnrollment = this.userDataService.getType();
  }
  back() {
    this.userDataService.removeType();
    this.router.navigate(['/login']);
  }

}
