import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { serverCodes } from '../server-codes';
import { Subject, Subscription } from 'rxjs';
import { UserDataService } from '../services/user-data.service';
import { environment } from '../environments/environment';
import { UserInterface } from '../interfaces/user.interface';
import { CommonService } from '../services/common.service';
import { AuthenticationService } from '../services/authentication.service';
import { CognitoService } from '../services/cognito.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription | any;

  hidePassword: boolean = true;
  incorrectPassword: boolean = false;
  isNotValidEmail: boolean = false;
  isLoading: boolean = false;
  interval: any;
  AssetsURL: string = environment.assetsURL;

  tabs = ['ADMIN', 'AGENT', 'VENDER'];
  activeTab = this.tabs[0];
  userData: UserInterface | undefined;
  // client: AWSAppSyncClient<any> | undefined;
  flag = 0;
  incorrectOTP: boolean = false;

  loginForm: FormGroup = this.fb.group({
    user_email: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-z]{2,63}$'),
      ],
    ],
    user_password: [
      '',
      [
        Validators.required,
        Validators.pattern(
          '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&]).{8,30})'
        ),
      ],
    ],
    user_type: ['ADMIN'],
    otp: [],
  });

  payLoadLogin = {
    header: {
      message_uuid: '',
      message_name: 'admin_login',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: 'fb4b4032-9b2d-4d5f-a105-b9a53904e21d',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      login: {},
    },
  };

  constructor(
    private router: Router,
    private userDataService: UserDataService,
    private fb: FormBuilder,
    private cognito: CognitoService,
    private commanService: CommonService,
    private toastr: ToastrService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit(): void {
    this.authenticationService.autoLogin(true);
    // const email :any = this.authenticationService.getUserDetails()['email'];
    // if (email) {
    //   this.loginForm.get('user_email').setValue(email);
    // }
    let type = this.userDataService.getType();
    if (type) {
      this.userDataService.removeType();
    }
    this.subscription = this.loginForm.controls[
      'user_password'
    ].valueChanges.subscribe((value) => {
      if (this.incorrectPassword) {
        this.isLoading = false;
        this.incorrectPassword = false;
      }
    });
  }

  onTabChange(activeTab: any) {
    this.activeTab = activeTab;
    this.loginForm.controls['user_type'].setValue(activeTab);
  }

  async signIn() {
    let message = '';
    let data = this.loginForm.value;
    let email = data.user_email;
    let password = data.user_password;
    
    if(email&& password){
      this.isLoading = true;
      this.cognito
        .signIn(email, password)
        .then(async (res: any) => {
          let payload: any = {
            _idToken: res.signInUserSession.idToken.jwtToken,
            cognitoId: res.username,
            email: res.attributes.email,
            _refreshToken: res.signInUserSession.refreshToken.token,
            type: 'ADMIN',
          };
  
          const awsCred: any = await this.cognito.buildCognitoCreds(
            res.signInUserSession.getIdToken().getJwtToken()
          );
  
          localStorage.setItem('username', res.attributes.name);
          this.userDataService.setUserData(payload);
          this.userDataService.setAwsCred(awsCred);
          this.isLoading = false;
          this.router.navigate(['/customers']);
        })
        .catch((err: any) => {
          message = err.message;
          this.toastr.error(err.message);
          this.isLoading = false;
  
          if (message.includes('Incorrect username or password')) {
            this.loginForm.controls['user_email'].markAsTouched();
            this.loginForm.controls['user_password'].markAsTouched();
            this.loginForm.controls['user_email'].setErrors({ invalid: true });
            this.loginForm.controls['user_password'].setErrors({ invalid: true });
            this.isNotValidEmail = true;
            this.incorrectPassword = true;
          }
        });
    }
  }

  // signIn1 = () => {
  //   this.isLoading = true;
  //   this.payLoadLogin.body.login = this.loginForm.value;
  //   var cognitoIdEnc = this.cryptoService.getBase64String(this.payLoadLogin.header.created_by);

  //   var payload1 = JSON.stringify(this.payLoadLogin).toString();
  //   var loginPayload = this.cryptoService.getEncryptedBody(this.payLoadLogin.header.created_by, payload1);

  //   let responseObs: any = this.authenticationService.login({
  //     "headers": { "x-shyld-app-id": cognitoIdEnc },
  //     "body": loginPayload.toString()
  //   });

  //   responseObs.subscribe(
  //     (data: any) => {
  //       let responseData: any = data;
  //       var response = this.cryptoService.getDecryptedBody('fb4b4032-9b2d-4d5f-a105-b9a53904e21d', responseData.body);
  //       var statusCode = JSON.parse(response).code;

  //       switch (statusCode) {
  //         case serverCodes['USER_NOT_EXIST']: {
  //           this.isLoading = false;
  //           this.loginForm.controls['user_email'].markAsTouched();
  //           this.loginForm.controls['user_email'].setErrors({ 'invalid': true });
  //           this.isNotValidEmail = true;
  //           this.incorrectPassword = false;
  //           break;
  //         }
  //         case serverCodes['USER_INVALID_OTP']: {
  //           this.isLoading = false;
  //           this.loginForm.controls['otp'].markAsTouched();
  //           this.loginForm.controls['otp'].setErrors({ 'invalid': true });
  //           this.incorrectOTP = true;
  //           break;
  //         }
  //         case serverCodes['USER_NOT_FOUND']: {
  //           this.loginForm.controls['user_email'].markAsTouched();
  //           this.loginForm.controls['user_email'].setErrors({ 'invalid': true });
  //           // this.loginForm.controls['user_password'].markAsTouched();
  //           this.loginForm.controls['user_password'].setErrors({ 'invalid': true });
  //           this.isNotValidEmail = true;
  //           this.incorrectPassword = true;
  //           this.isLoading = false;
  //           break;
  //         }
  //         case serverCodes['INCORRECT_USERNAME_OR_PASSWORD']: {
  //           this.loginForm.controls['user_password'].markAsTouched();
  //           this.loginForm.controls['user_password'].setErrors({ 'invalid': true });
  //           // this.isNotValidEmail=true;
  //           this.incorrectPassword = true;
  //           this.isLoading = false;
  //           break;
  //         }
  //         case serverCodes['INCORRECT_PASSWORD']: {
  //           this.loginForm.controls['user_password'].markAsTouched();
  //           this.loginForm.controls['user_password'].setErrors({ 'invalid': true });
  //           this.incorrectPassword = true;
  //           this.isLoading = false;
  //           break;
  //         }
  //         case serverCodes['USER_UNREGISTERED']: {
  //           this.authenticationService.setUserDetails(JSON.parse(response).userType, JSON.parse(response).Email,);
  //           this.isLoading = false;
  //           this.router.navigate(['/login']);
  //           break;
  //         }
  //       }
  //       if (Object.keys(responseData.statusCode)) {
  //         switch (responseData.statusCode) {
  //           case serverCodes['USER_REGISTERED']: {
  //             if (JSON.parse(response).userType == "ADMIN") {
  //               this.router.navigate(['/customers']);
  //               this.isLoading = false;

  //             }
  //             else if (JSON.parse(response).userType == "VENDER") {
  //               this.router.navigate(['/admin']);
  //             }
  //             else if (JSON.parse(response).userType == "AGENT") {
  //               this.router.navigate(['/admin']);
  //             }
  //             break;
  //           }
  //         }
  //       }
  //     }
  //   );

  // }

  /* Redirects the user to forgot password page.*/
  forgotPassword = () => {
    this.router.navigate(['/forgot-password'], {
      queryParams: { email: this.loginForm.value.user_email },
    });
  };

  typeChange(type: string) {
    this.userDataService.setType(type);
    this.router.navigate(['/enrollment']);
  }

  /* Deletes all the google user data from the session storage.*/
  signOut = (): void => {
    // this.authService.signOut();
  };

  /* Unsubscribes all the subscriptions.*/
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
