<div class="ml-5 mr-5" fxLayout="column">
    <div fxLayout style="align-items: center !important;">
        <h5 class="card-title ml-1 font-family-NB mt-4" fxLayout fxFlex="97">Learn List</h5>
        <button mat-icon-button (click)="clearFilter()" *ngIf="!isLoading && isFilter"
            class="btn btn-yolow btn-clear-filter text-center font-family-NSB mr-2" #tooltip="matTooltip"
            matTooltip="Clear Filter" matTooltipPosition="below" fxLayout fxFlex="8" fxLayoutGap="20">
            <mat-icon class="material-icons-outlined ml-1 font-22px">clear</mat-icon> Clear Filter
        </button>
        <!-- <button (click)="addArticle()" *ngIf="!isLoading" class="btn btn-sm btn-yolow btn-filter text-center"
            #tooltip="matTooltip" matTooltip="Add Article" matTooltipPosition="below" fxLayout fxLayoutGap="20">
            <mat-icon>add</mat-icon> Add Article
        </button> -->
        <button mat-button (click)="addArticle()" *ngIf="!isLoading" fxLayout fxFlex="10" fxLayoutGap="20"
            class="btn btn-sm btn-add-article btn-yolow text-center font-family-NSB mr-2">
            <mat-icon class="material-icons-outlined font-22px">add</mat-icon> Add Article
        </button>
        <button mat-icon-button (click)="filterDialog()" *ngIf="!isLoading"
            class="btn btn-yolow btn-filter font-family-NSB text-center" #tooltip="matTooltip" matTooltip="Filter list"
            matTooltipPosition="below" fxLayout fxLayoutGap="20">
            <mat-icon class="ml-2">filter_list</mat-icon>
        </button>
    </div>

    <div fxLayout fxLayoutAlign="center" fxLayoutGap="30px" *ngIf="isLoading">
        <mat-spinner style="margin:0 auto;" *ngIf="isLoading" [diameter]="30"></mat-spinner>
    </div>
    <div class="article-container font-family-NSB mb-3" fxLayout fxLayout="column" fxLayoutAlign="start"
        fxLayoutGap="5px" fxLayoutGap.xs="0" [ngClass]="!isLoading ? 'd-block':'d-none'">
        <mat-card class="example-container mb-3" [ngClass]="!isLoading ? 'd-block':'d-none'" fxLayout fxLayout="column">
            <mat-card-content class="font-family-NSB" fxLayout fxLayout="column">
                <div fxLayout fxLayout="column" *ngIf="!isLoading && articles.length">
                    <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)"
                        [matSortActive]="sortD.sortField" [matSortDirection]="sortD.sortOrder">

                        <!-- learn_image Column -->
                        <ng-container matColumnDef="learn_image">
                            <th mat-header-cell *matHeaderCellDef>Photo</th>
                            <td mat-cell *matCellDef="let row">
                                <img [src]="row.learn_image && row.learn_image!='' ? row.learn_image :
                                AssetsURL+'/img/User_Profile_Icon.png'"
                                    class="rounded-circle" width="40px" height="40px" />
                            </td>
                        </ng-container>

                        <!-- source Column -->
                        <ng-container matColumnDef="learn_source">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="learn_source">Article Source</th>
                            <td mat-cell *matCellDef="let row">{{ row.learn_source }}</td>
                        </ng-container>

                        <!-- Title Column -->
                        <ng-container matColumnDef="learn_title">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="learn_title">Article Title</th>
                            <td mat-cell *matCellDef="let row">{{ row.learn_title }}</td>
                        </ng-container>

                        <!-- discription Column -->
                        <ng-container matColumnDef="learn_description">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header="learn_description">Article
                                Description</th>
                            <td mat-cell *matCellDef="let row">{{ row.learn_description }}</td>
                        </ng-container>

                        <!-- Action Column -->
                        <ng-container matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef>Action</th>
                            <td mat-cell *matCellDef="let row">
                                <span class="d-flex">
                                    <button (click)="editArticle(row)" title="Edit"
                                        class="btn btn-outline-dark btn-sm mr-2 ">
                                        <mat-icon class="btn-edit material-icons-outlined">edit</mat-icon>
                                    </button>
                                    <button (click)="viewArticleDetails(row)" title="Details"
                                        class="btn btn-outline-dark btn-sm mr-2">
                                        <mat-icon class="btn-edit material-icons-outlined">description
                                        </mat-icon>
                                    </button>
                                    <button (click)="deleteArticleDialog(row)" title="Delete"
                                        class="btn btn-outline-dark btn-sm mr-2">
                                        <mat-icon class="btn-edit material-icons-outlined">delete_outline</mat-icon>
                                    </button>
                                </span>
                            </td>
                        </ng-container>
                        <tr class="mat-row" *matNoDataRow>
                            <td [colSpan]="displayedColumns.length" class="mat-cell text-center">No data found</td>
                        </tr>

                        <tr class="font-family-NB" mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
                        <tr class="font-family-NSB" mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                    </table>
                </div>
                <mat-paginator [ngClass]="!isLoading && articles.length > 0 ? 'd-block':'d-none'"
                    class="ml-max font-family-NB " fxLayout fxLayoutAlign="end" fxFlex="100" fxFlex.xs="100"
                    fxLayoutGap="10px" (page)="pageChanged($event)" [pageSize]="page.limit"
                    [pageSizeOptions]="[ 10, 25, 50, 100]">
                </mat-paginator>
            </mat-card-content>
        </mat-card>
    </div>
</div>
