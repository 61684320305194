import {User} from '../models/user.model';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {
  user: any;

  setType(type: string) {
    localStorage.setItem('type', type);
  }

  getType() {
    return localStorage.getItem('type');
  }

  removeType() {
    localStorage.removeItem('type');
  }

  getUserData() {
    this.user = localStorage.getItem('userDataSimba');
    return JSON.parse(this.user);
  }

  setUserData(user: any) {
    localStorage.setItem('userDataSimba', JSON.stringify(user));
  }

  removeUserData() {
    localStorage.removeItem('userDataSimba');
  }

  getAwsCred() {
    const data = localStorage.getItem('userAwsCred');
    return data ? JSON.parse(data) : null
  }

  setAwsCred(cred: any) {
    localStorage.setItem('userAwsCred', JSON.stringify(cred));
  }

  getUserProfileData() {
    this.user = localStorage.getItem('userProfileData');
    return JSON.parse(this.user);
  }

  setUserProfileData(user: any) {

    localStorage.setItem('userProfileData', JSON.stringify(user));
  }

  removeUserProfileData() {
    localStorage.removeItem('userProfileData');
  }
}
