<html>

<head>
    <meta name="viewport" content="width=device-width,initial-scale=1.0">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css"
        integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous">


    <script src="https://code.jquery.com/jquery-3.2.1.slim.min.js"
        integrity="sha384-KJ3o2DKtIkvYIK3UENzmM7KCkRr/rE9/Qpg6aAZGJwFDMVNA/GpGFF93hXpG5KkN"
        crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.12.9/umd/popper.min.js"
        integrity="sha384-ApNbgh9B+Y1QKtv3Rn7W3mgPxhU9K/ScQsAP7hUibX39j7fakFPskvXusvfa0b4Q"
        crossorigin="anonymous"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/js/bootstrap.min.js"
        integrity="sha384-JZR6Spejh4U02d8jOt6vLEHfe/JQGiRRSQQxSfFWpi1MquVdAyjUar5+76PVCmYl"
        crossorigin="anonymous"></script>
    <style>
        @font-face {
            font-family: 'NBK';
            src: url('./assets/fonts/Nunito-Black.ttf');
            font-weight: normal;
            font-style: normal;
        }

        @font-face {
            font-family: 'NBKI';
            src: url('./assets/fonts/Nunito-BlackItalic.ttf');
            font-weight: normal;
            font-style: italic;
        }

        @font-face {
            font-family: 'NB';
            src: url('./assets/fonts/Nunito-Bold.ttf');
            font-weight: bold;
            font-style: Bold;
        }

        @font-face {
            font-family: 'NBI';
            src: url('./assets/fonts/Nunito-BoldItalic.ttf');
            font-weight: bold;
            font-style: italic;
        }

        @font-face {
            font-family: 'NEB';
            src: url('./assets/fonts/Nunito-ExtraBold.ttf');
            font-weight: bold;
            font-style: normal;
        }

        @font-face {
            font-family: 'NEBI';
            src: url('./assets/fonts/Nunito-ExtraBoldItalic.ttf');
            font-weight: bold;
            font-style: italic;
        }

        @font-face {
            font-family: 'NEBL';
            src: url('./assets/fonts/Nunito-ExtraLight.ttf');
            font-weight: normal;
            font-style: normal;
        }

        @font-face {
            font-family: 'NEBLI';
            src: url('./assets/fonts/Nunito-ExtraLightItalic.ttf');
            font-weight: normal;
            font-style: italic;
        }

        @font-face {
            font-family: 'NI';
            src: url('./assets/fonts/Nunito-Italic.ttf');
            font-weight: normal;
            font-style: italic;
        }

        @font-face {
            font-family: 'NL';
            src: url('./assets/fonts/Nunito-Light.ttf');
            font-weight: normal;
            font-style: normal;
        }

        @font-face {
            font-family: 'NLI';
            src: url('./assets/fonts/Nunito-LightItalic.ttf');
            font-weight: normal;
            font-style: italic;
        }

        @font-face {
            font-family: 'NR';
            src: url('./assets/fonts/Nunito-Regular.ttf');
            font-weight: normal;
            font-style: normal;
        }

        @font-face {
            font-family: 'NSB';
            src: url('./assets/fonts/Nunito-SemiBold.ttf');
            font-weight: bold;
            font-style: normal;
        }

        @font-face {
            font-family: 'NSBI';
            src: url('./assets/fonts/Nunito-SemiBoldItalic.ttf');
            font-weight: bold;
            font-style: italic;
        }

        html,
        body {
            font-family: 'NBK, NBKI, NB, NBI, NEB, NEB, NEBI, NEBL, NI, NL, NLI, NR, NSB, NSBI';
            width: 100%;
            height: 100%;
            overflow: hidden;
            /* overflow-y: hidden; Hide vertical scrollbar */
            /* overflow-x: hidden; Hide horizontal scrollbar */
        }

        .font-family-NSB {
            font-family: 'NSB';
        }
        .font-family-NL {
            font-family: 'NL';
        }

        .font-family-NB {
            font-family: 'NB';
        }

        .term-container {
            margin: auto;
            padding: 10px;
            /* width: 30%; */
        }

        .center {
            text-align: center;
        }

        .confirmation-container {
            overflow-x: hidden;
            overflow-y: auto;
        }

        .insurance {
            display: block;
            margin: auto;
        }

        .margin-max {
            margin: auto 8%;
        }

        .font-2x {
            font-size: 22px;
            word-wrap: break-word;
        }

        .fw-900 {
            font-weight: 900;
        }

        .flex-container {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
        }

        @media screen and (max-width: 920px) {
            .contain-text {
                margin: auto 18% !important;
                width: 70% !important;
            }

        }

        @media screen and (max-width: 820px) {


            .contain-text {
                margin: auto 17% !important;
                width: 70% !important;
            }

        }

        @media screen and (max-width: 768px) {
            .margin-max {
                margin: auto 14.7% !important;
            }

            .contain-text {
                margin: auto 21% !important;
                width: 80% !important;
            }

        }

        @media screen and (max-width: 540px) {
            .margin-max {
                margin: auto 12.9% !important;
            }

            .contain-text {
                margin: auto 15% !important;
                width: 80% !important;
            }
        }

        @media screen and (max-width: 280px) {
            .margin-max {
                margin: auto 10.9% !important;
            }

            .contain-text {
                margin: auto 18% !important;
                width: 77% !important;
            }
        }

        .contain-text {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: left;
            margin: auto 19%;
        }
    </style>
</head>

<body>
    <div class="h-100 d-flex justify-content-center flex-container confirmation-container " fxLayout.lg="row"
        fxLayout.md="row" fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap="20px" fxLayoutGap.xs="20px">
        <div class="text-center justify-container term-container center"  fxLayout="column" fxLayoutGap="30px"
            fxLayoutGap.xs="20px" fxLayout.xs="column" fxFlex="50%" fxFlex.sm="50%" fxFlex.xs="50%">
            <div><img src="./assets/img/terms.png" class="insurance" width="350px" height="250px" alt="Terms image">
            </div>
            <h3 class="font-family-NB font-2x" style="margin-bottom: 0;">Trust Us</h3>

            <p class="font-family-NB fw-900 mr-auto" style="margin-top: 0;color:#FFB600">Let's be Transparent</p>
            <p class="font-family-NSB  margin-max" style="word-break: break-word">Our first order of business
                is to protect your data!</p>
            <div class="text-center contain-text mt-3">
                <h5 class="font-family-NB float-left" style="margin-bottom: 0px;text-align: left;">Data Privacy</h5>
                <img src="./assets/img/line.png" style="width: 30px;height:2.3px;float: left;" />
                <p class="font-family-NL" style="text-align: left;">Your personal data is not for sale!
                </p>
                <h5 class="font-family-NB float-left" style="margin-bottom: 0px;text-align: left;">Data Security</h5>
                <img src="./assets/img/line.png" style="width: 30px;height:2.3px;float: left;" />
                <p class="font-family-NL" style="text-align: left;">Your data is protected safely with multiple locks and keys!</p>
                <h5 class="font-family-NB float-left" style="margin-bottom: 0px;text-align: left;">Data Usage</h5>
                <img src="./assets/img/line.png" style="width: 30px;height:2.3px;float: left;" />
                <p class="font-family-NL" style="text-align: left;">With your consent, we will use your data to give smart recommendations
                    tailored for you!</p>
            </div>
        </div>
    </div>
</body>

</html>