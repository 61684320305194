//import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";

//import '../src/pages/Custom.css';
import "./Custom.css";
import Home from "./pages/Home";
import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
} from "firebase/firestore";
import { db } from "./firebase";
import { ChatContextProvider } from "./Context/ChatContext";
import Agent from "./Agent";

function App() {
  localStorage.setItem("scroll", "false");

  return (
    <div className="App">
      <ChatContextProvider>
        <Agent />
        <Home />
      </ChatContextProvider>
    </div>
  );
}

export default App;
