import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { UserInterface } from 'src/app/interfaces/user.interface';
import { AppState } from 'src/app/services/app.state';
import { CommonService } from 'src/app/services/common.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import { UserDataService } from 'src/app/services/user-data.service';
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  userData: UserInterface | undefined;
  displayedColumns: string[] = [
    'name',
    'email',
    'phone_number',
    'rating',
    'description',
    'created_date',
  ];

  isLoading: boolean = true;
  dataSource: MatTableDataSource<any> | any;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  page: any = { start: 0, limit: 10 };
  totalRecords = 0;
  isFilter: boolean = false;
  sortD: any = { sortField: 'created_date', sortOrder: 'desc' };

  feedbacks: any = [];
  errorMessage: string = '';

  getFeedbacksPayLoad = {
    header: {
      message_uuid: '',
      message_type: 'Q',
      version: '1.0',
      message_name: 'feedback-R',
      correlation_uuid: '',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      start: 0,
      limit: 5,
      sortField: 'created_date',
      sortOrder: 'desc',
    },
  };

  constructor(
    private commanService: CommonService,
    private userDataService: UserDataService,
    private feedbackService: FeedbackService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.userData = this.userDataService.getUserData();
    this.getFeedbacksPayLoad.header.created_by =
      this.userData != undefined ? this.userData.cognitoId : '';
    this.getFeedbacksPayLoad.body.start = this.page.start;
    this.getFeedbacksPayLoad.body.limit = this.page.limit;
    this.getFeebacks();
  }

  async getFeebacks() {
    this.isLoading = true;
    this.feedbacks = [];
    let parseResponse: any = await this.feedbackService.getFeedbacks(
      this.getFeedbacksPayLoad
    );
    if (parseResponse) {
      this.feedbacks = [];
      this.feedbacks = parseResponse.feedbacks;
      this.dataSource = new MatTableDataSource(this.feedbacks);
      this.totalRecords = parseResponse.total;
      this.paginator.length = this.totalRecords;
      this.isLoading = false;
    }
  }

  sortData(sort: Sort) {
    this.sortD.sortField = sort.active;
    this.sortD.sortOrder = sort.direction;

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getFeedbacksPayLoad.body.start = this.page.start;
    this.getFeedbacksPayLoad.body.limit = this.page.limit;

    this.getFeedbacksPayLoad.body.sortField = this.sortD.sortField;
    this.getFeedbacksPayLoad.body.sortOrder = this.sortD.sortOrder;
    this.getFeebacks();
  }

  pageChanged(event: any) {
    this.page.limit = event.pageSize;
    this.page.start = event.pageIndex * event.pageSize;
    this.getFeedbacksPayLoad.body.start = this.page.start;
    this.getFeedbacksPayLoad.body.limit = this.page.limit;

    this.getFeebacks();
  }

  getCreate(created: any) {
    let date = new Date(created);
    return this.datepipe.transform(date, 'MM-dd-yyyy  ::  h:mm a');
  }
}
