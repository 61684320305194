import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler, HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, finalize, map, tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {CryptoService} from "../services/crypto.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {environment} from "../environments/environment";
import {AwsSignature, AwsSignatureInputData} from "../classes/AwsSignature";
import {UserDataService} from "../services/user-data.service";
import {AuthenticationService} from "../services/authentication.service";

@Injectable()
export class EncryptDecryptInterceptor implements HttpInterceptor {
  constructor(private router: Router,
              private cryptoService: CryptoService,
              private userDataService: UserDataService,
              private authenticationService: AuthenticationService,
              private snackBar: MatSnackBar) {
  }

  // If you want to some exclude api call from Encryption then add here.
  excludeURLs = [
    // '/xyz'
  ];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lastResponse: HttpEvent<any>;
    let errorResponse: HttpErrorResponse;
    let createdBy: any = null;
    // We have encrypted the GET and POST call before pass payload to API
    for (const skippUrl of this.excludeURLs) {
      if (new RegExp(skippUrl).test(request.url)) {
        return next.handle(request);
      }
    }
    if (request.method == "GET") {
      // Todo: In future we need to do encryption on payload/params of GET API call.
      // if (request.url.indexOf("?") > 0) {
      //   let encriptURL = request.url.substr(0, request.url.indexOf("?") + 1) + this.encryptPayload(request.url.substr(request.url.indexOf("?") + 1, request.url.length));
      //   const cloneReq = request.clone({
      //     url: encriptURL
      //   });
      //   return next.handle(cloneReq);
      // }
    } else if (request.method == "POST") {
      if (request.body || request.body.length > 0) {
        createdBy = request.body.header?.created_by
        const awsCred = this.userDataService.getAwsCred();
        const encryptedBody = this.encryptPayload(request.body)
        let headers = new HttpHeaders();
        let domainDetails = new URL(environment.api_endpoint)
        if (awsCred) {
          const opts = {
            method: 'POST',
            host: domainDetails.host,
            canonicalUri: domainDetails.pathname,
            requestParameters: JSON.stringify(encryptedBody)
          }
          const signatureHeaders:any = AwsSignature.generateSignature(new AwsSignatureInputData(opts, awsCred))
          headers = new HttpHeaders(signatureHeaders)
        }
        request = request.clone({body: encryptedBody, headers : headers})
      }
    }
    return next.handle(request)
      .pipe(
        map(res => {
          return res;
        }),
        tap((response: any) => {
          const responseData: any = response;
          lastResponse = response;
          if (response.type === HttpEventType.Response) {
            if (responseData.hasOwnProperty('errorMessage')) {
              this.snackBar.open(responseData.errorMessage, 'close', {
                duration: 3000,
                verticalPosition: "top",
                horizontalPosition: "right",
                panelClass: ['error-snackbar']
              });
              return throwError(responseData);
            } else {
              let res: any = this.cryptoService.getDecryptedBody(createdBy, responseData.body.body);
              response.body = JSON.parse(res)
            }
          }
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          errorResponse = error;
          if (error.url === environment.api_endpoint && error.status === 403 && error.statusText === 'OK') {
            this.authenticationService.logout();
          }
          return throwError(error);
        }),
        finalize(() => {
          if (lastResponse.type === HttpEventType.Sent && !errorResponse) {
            // last response type was 0, and we haven't received an error
          }
        })
      );
  }

  encryptPayload(payload: any): { headers: any, body: any } {
    const cognitoIdEnc = this.cryptoService.getBase64String(payload.header.created_by);
    //stringify the payload
    const payload_stringify = JSON.stringify(payload).toString();
    const payload_createdId = payload.header.created_by;
    const encrypted_Payload = this.cryptoService.getEncryptedBody(payload_createdId, payload_stringify);
    return {
      "headers": {"x-shyld-app-id": cognitoIdEnc},
      "body": encrypted_Payload.toString()
    }
  }

  decryptResponse(event: any, responseData: any) {
    return this.cryptoService.getDecryptedBody(event.header.created_by, responseData);
  }
}
