import { Component, OnInit, ViewChild } from '@angular/core';
import { UserInterface } from '../../../interfaces/user.interface';
import { UserDataService } from '../../../services/user-data.service';
import { CommonService } from '../../../services/common.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { FilterDialogComponent } from '../../shared/filter-dialog/filter-dialog.component';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '../../../services/app.state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from "@angular/common";
import {ArticleService} from "../../../services/article.service";

@Component({
  selector: 'app-list-article',
  templateUrl: './list-article.component.html',
  styleUrls: ['./list-article.component.scss']
})
export class ListArticleComponent implements OnInit {

  userData: UserInterface | undefined;
  displayedColumns: string[] = ['learn_image','learn_source','learn_title','learn_description', 'action'];

  isLoading: boolean = true;
  dataSource: MatTableDataSource<any> | any
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  page: any = { start: 0, limit: 10 };
  totalRecords = 0;
  isFilter: boolean = false;
  sortD: any = { sortField: "title", sortOrder: "asc" };

  articles: any = [];
  errorMessage: string = '';
  AssetsURL: string = environment.assetsURL;

  getArticleListPayLoad = {
    "header": {
      "message_uuid": "",
      "message_type": "Q",
      "version": "1.0",
      "message_name": "learn_section-R",
      "correlation_uuid": "",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      "start": 0,
      "limit": 5,
      learn_source: "",
      learn_date: "",
      learn_title: "",
      sortField: "learn_source", // learn_source, learn_date, learn_title
      sortOrder: "asc" // asc, desc
    }
  };
  getDeleteArticlePayLoad = {
    "header": {
      "message_uuid": "",
      "message_type": "Q",
      "version": "1.0",
      "message_name": "learn_section-D",
      "correlation_uuid": "",
      "service_completion_status_code": "",
      "created_by": "",
      "created_timestamp":new Date().toLocaleString('en-GB')
    },
    "body": {
      "sequence_id": 0
    }
  }

  constructor(private router: Router, private commanService: CommonService,
              private userDataService: UserDataService,
              private articleService: ArticleService,
              public dialog: MatDialog, private appState: AppState,
              public snackBar: MatSnackBar, private datepipe: DatePipe) {

  }

  ngOnInit() {

    this.userData = this.userDataService.getUserData();
    this.getArticleListPayLoad.header.created_by = this.userData != undefined ? this.userData.cognitoId : '';
    this.getDeleteArticlePayLoad.header.created_by = this.userData != undefined ? this.userData.cognitoId : '';
    this.getArticleListPayLoad.body.start = this.page.start;
    this.getArticleListPayLoad.body.limit = this.page.limit;
    this.getArticleList();
  }

  // ngAfterViewInit() {
  //   this.dataSource.paginator = this.paginator;
  //   this.dataSource.sort = this.sort;
  // }
  async getArticleList() {
    this.isLoading = true;
    this.articles = [];
    let parseResponse: any = await this.articleService.getArticle(this.getArticleListPayLoad);
    if (parseResponse) {
      this.articles = [];
      this.articles = parseResponse.learnSections;
      this.dataSource = new MatTableDataSource(this.articles);
      this.totalRecords = parseResponse.total;
      this.paginator.length = this.totalRecords;
      this.isLoading = false;
    }
  }
  sortData(sort: Sort) {

    this.sortD.sortField = sort.active;
    this.sortD.sortOrder = sort.direction;
    // this.sort.sortChange.emit(this.sortD);

    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.getArticleListPayLoad.body.start = this.page.start;
    this.getArticleListPayLoad.body.limit = this.page.limit;

    this.getArticleListPayLoad.body.sortField = this.sortD.sortField;
    this.getArticleListPayLoad.body.sortOrder = this.sortD.sortOrder;
    this.getArticleList();
  }
  pageChanged(event: any) {
    this.page.limit = event.pageSize;
    this.page.start = event.pageIndex * event.pageSize;
    this.getArticleListPayLoad.body.start = this.page.start;
    this.getArticleListPayLoad.body.limit = this.page.limit;
    this.getArticleList();
  }
  clearFilter = () => {
    this.isFilter = false;
    this.getArticleListPayLoad.body.start = 0;
    this.getArticleListPayLoad.body.limit = 10;
    this.getArticleListPayLoad.body.learn_source = '';
    this.getArticleListPayLoad.body.learn_date = '';
    this.getArticleListPayLoad.body.learn_title = '';
    this.getArticleList();
  }
  filterDialog = () => {

    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '500px',
      data: {
        header: 'Search Article',
        name: this.getArticleListPayLoad.body.learn_source,
        title: this.getArticleListPayLoad.body.learn_title,
        learn_date: this.getArticleListPayLoad.body.learn_date,
        type: "article"
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {

      if (result.action == "Search") {
        this.getArticleListPayLoad.body.start = 0;
        this.getArticleListPayLoad.body.limit = 10;
        this.isFilter = true;
        this.getArticleListPayLoad.body.learn_source = '';
        this.getArticleListPayLoad.body.learn_title = '';
        this.getArticleListPayLoad.body.learn_date = '';
        let title = result.filterArticle.title.trim();
        let name = result.filterArticle.name.trim();
        let date = result.filterArticle.learn_date != '' && result.filterArticle.learn_date != null ? '' + this.datepipe.transform(result.filterArticle.learn_date, 'yyyy-MM-dd') : '';
        if (title != '')
          this.getArticleListPayLoad.body.learn_title = title;
        if (name != '')
          this.getArticleListPayLoad.body.learn_source = name;
        if (date != '')
          this.getArticleListPayLoad.body.learn_date = date;

        this.getArticleList();
      }

    });
  }
  deleteArticleDialog(row: any){

    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      width: '500px',
      data: {
        header: 'Delete',
        description: `Are you sure want to delete ${row.sequence_id} ?`,
        id: row.sequence_id,
        action: 'DELETE',
        okbutton: 'DELETE',
        cancelbutton: 'CANCEL'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == 'DELETE') {
        this.getDeleteArticlePayLoad.header.created_by = this.userData != undefined ? this.userData.cognitoId : '';
        // this.getDeleteArticlePayLoad.body.user_id = row.userId != undefined ? row.userId : '';
        this.getDeleteArticlePayLoad.body.sequence_id = row.sequence_id;
        this.deleteArticle();
      }
    });
  }
  async deleteArticle() {
    let parseResponse: any = await this.articleService.deleteArticle(this.getDeleteArticlePayLoad);
    if (parseResponse) {
      this.snackBar.open(parseResponse.message, 'close', {
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ['success-snackbar']
      });
      this.getArticleList();
    }
  }
  editArticle(row:any){
    this.appState.set('ARTICLE', row);
    this.router.navigate(['/add-learn']);
  }
  addArticle(){
    this.appState.set('ARTICLE', null);
    this.router.navigate(['/add-learn']);
  }
  viewArticleDetails(row: any) {
    this.appState.set('ARTICLE', row);
    this.router.navigate(['/details-learn']);
  }
}
