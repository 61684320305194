import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
// import { CryptoService } from "../../../services/crypto.service";
// import { InsuranceService } from '../../../services/insurance.service';
import { AppState } from '../../../services/app.state';
import { UserInterface } from '../../../interfaces/user.interface';
import { UserDataService } from '../../../services/user-data.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { DeleteDialogComponent } from '../../shared/delete-dialog/delete-dialog.component';
// import {AddInsuranceComponent} from "../add-insurance/add-insurance.component";
import { CommonService } from '../../../services/common.service';
import { environment } from '../../../environments/environment';
import { InsuranceService } from '../../../services/insurance.service';
import { SendEmailDialogComponent } from '../../shared/send-email-dialog/send-email.component';
import { SendStatusDialogComponent } from '../../shared/send-status-dialog/send-status.component';
import { SaveChangesComponent } from '../../shared/changes-save-dialog/save-dialog.component';
import { VoiService } from 'src/app/services/voi-list';
import { ToastrService } from 'ngx-toastr';
import { HttpClient, HttpHeaders } from '@angular/common/http';

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-dd',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const Loader = './assets/img/loading.gif';
@Component({
  selector: 'app-update-voi',
  templateUrl: './update-voi.component.html',
  styleUrls: ['./update-voi.component.css'],
})
export class UpdateVoiComponent implements OnInit {
  isFormValid: boolean;

  @ViewChild('myForm') myForm: any;
  insurancePDFselector = new FormControl(0);
  isLoading: boolean = true;
  isLoadingUser: boolean = true;
  isDisableSave: boolean = false;
  leadId: any;
  pdfSrc: string = '';
  emailStatus: string = '';
  viewPDFsrc = '';
  responseData: any;
  mainResponse: any = {};
  actionControl = new FormControl();
  deleteCoverages: any = [];
  basicInsuranceState: boolean = true;
  insuranceProviderState: boolean = true;
  insuranceEntitiesConverageState: boolean = true;
  insuranceEntitiesState: boolean = true;
  insuranceConverageState: boolean = true;
  insuranceStatus: string = 'pending';
  documents: string[] = [];
  selectedDocIndex;
  download_svg: string = './assets/img/Download.svg';

  InitalCharacterURL: string = environment.initialCharacterURL;

  PDFList: any = [];
  //  = ['Auto Insurance', 'Umbrella Insurance', 'Condo Insurance', 'Home Warranty Insurance', 'Health Insurance', 'Dental Insurance'];
  getVoiDetailsPayLoad = {
    body: {
      verificationSeqId: '',
    },
    header: {
      version: '1.0',
      created_by: '',
      request_id: '',
      message_name: 'voi_details-R',
      message_type: 'Q',
      message_uuid: '',
      correlation_uuid: '',
      created_timestamp: '2020-01-01000000.000',
      service_completion_status_code: '',
    },
  };
  savePdfDetailsPayLoad = {
    body: {
      sequence_id: 123,
      user_sequence_id: 45,
      'property address': 'test',
      policyNumber: 'test',
      policyTerm: 'test',
      premiumAmount: 'test',
      insuranceStartDate: 'test',
      initialPaymentDetails: 'test',
      renewal_Details: 'test',
      insurerNameContact: 'test',
      agencyNameContact: 'test',
      mortgagee_clause: 'test',
      mortgagee_details: [],
      isGeneratePdf: true,
      user_profile_sequence_id: '',
      insurance_lead_uuid: null,
      loan_number: 0,
      valid_closing_date: {
        valid: false,
        message: '',
      },
      valid_borrower_name: {
        valid: false,
        message: '',
      },
      valid_co_borrower_name: {
        valid: false,
        message: '',
      },
      valid_loan_amount: {
        valid: false,
        message: '',
      },
      valid_property_address: {
        valid: false,
        message: '',
      },
      validation_summary: '',
      coverages: [],
      userCognitoId: '',
    },
    header: {
      version: '1.0',
      created_by: '',
      request_id: '',
      message_name: 'pdf_generate-C',
      message_type: 'Q',
      message_uuid: '',
      correlation_uuid: '',
      created_timestamp: '2020-01-01000000.000',
      service_completion_status_code: '',
    },
  };
  sendEmailPayload: {
    body: {
      wmailList: ['sanjay@mail.com', 'yash@mail.com'];
    };
    header: {
      version: '1.0';
      created_by: '';
      request_id: '';
      message_name: 'email_send-C';
      message_type: 'Q';
      message_uuid: '';
      correlation_uuid: '';
      created_timestamp: '2020-01-01000000.000';
      service_completion_status_code: '';
    };
  };
  notifyStatusPayload = {
    body: {},
    header: {
      version: '1.0',
      created_by: '',
      request_id: '',
      message_name: 'send_ivs_details_correction_email-C',
      message_type: 'Q',
      message_uuid: '',
      correlation_uuid: '',
      created_timestamp: '2020-01-01000000.000',
      service_completion_status_code: '',
    },
  };
  sendIVSPayload = {
    body: {},
    header: {
      version: '1.0',
      created_by: '',
      request_id: '',
      message_name: 'send_ivs-C',
      message_type: 'Q',
      message_uuid: '',
      correlation_uuid: '',
      created_timestamp: '2020-01-01000000.000',
      service_completion_status_code: '',
    },
  };
  userData: UserInterface | any;
  formGroup: FormGroup = this.fb.group({
    insuredName: ['', Validators.required],
    propertyAddress: ['', Validators.required],
    policyNumber: ['', Validators.compose([])],
    policyTerm: ['', Validators.required],
    premiumAmount: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(/^-?\d*[.,]?\d{0,2}$/),
      ]),
    ],
    insuranceStartDate: ['', Validators.compose([Validators.required])],
    initialPaymentDetails: ['', Validators.compose([Validators.required])],
    renewal_Details: ['', Validators.compose([Validators.required])],
    insurerNameContact: ['', Validators.compose([Validators.required])],
    agencyNameContact: ['', Validators.compose([Validators.required])],
    mortgagee_clause: ['', Validators.compose([Validators.required])],
    mortgagee_details: this.fb.array([
      this.fb.group({
        mortgagee: this.fb.control(
          '',
          Validators.compose([Validators.required])
        ),
        loan_number: this.fb.control(
          '',
          Validators.compose([Validators.required])
        ),
      }),
    ]),
    // loan_number: [''],
    valid_borrower_name: [''],
    valid_co_borrower_name: [''],
    valid_property_address: [''],
    valid_loan_amount: [''],
    valid_closing_date: [''],
    validation_summary: [''],
    valid_borrower_name_message: [''],
    valid_co_borrower_name_message: [''],
    valid_property_address_message: [''],
    valid_loan_amount_message: [''],
    valid_closing_date_message: [''],
    dwelling_limit: [''],
    dwelling_deductible: [''],
    other_structure_limit: [''],
    other_structure_deductible: [''],
  });

  totalPages: number = 0;
  isLoaded: boolean = false;
  entity: any = {};
  insurance: any = {};
  insuranceSummary: any = {};
  insuranceCoverages: any[] = [];
  userInsuranceAbstract: any = {};
  userInsuranceContact: any = {};
  insuranceTypeDetails: any[] = [];
  formDisabled: boolean = false;
  registeredEmails = [];

  // @ViewChild('scrollMe') scrollEntities: ElementRef | any;
  zoom = 1.0;
  images: any = [];
  getInsuranceConveragesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'user_insurance_section_coverage-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      agent_cognito_id: '',
      user_id: '',
      sequence_id: 0,
    },
  };
  updateInsuranceConveragesPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'user_insurance_section_coverage-U',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      agent_cognito_id: '',
      user_id: '',
      status: '',
      sequence_id: 0,
      coverages: '',
      userInsuranceAbstract: {},
      userInsuranceContact: {},
      deleteCoverages: [],
    },
  };
  getInsuranceTypeDetailsPayLoad = {
    header: {
      message_uuid: '',
      message_name: 'insurance_type_details-R',
      message_type: 'Q',
      correlation_uuid: '',
      request_id: '',
      version: '1.0',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      agent_cognito_id: '',
      start: 0,
      limit: 5,
    },
  };
  getDeleteInsuranceDocumentPayLoad = {
    header: {
      message_uuid: '',
      message_type: 'Q',
      version: '1.0',
      message_name: 'admin_user_insurance_document-D',
      correlation_uuid: '',
      service_completion_status_code: '',
      created_by: '',
      created_timestamp: new Date().toLocaleString('en-GB'),
    },
    body: {
      user_id: '',
      insurance_id: '',
      insurance_document_id: '',
    },
  };

  incrementZoom(amount: number) {
    this.zoom += amount;
  }

  handleEvent(event: any) {
    switch (event.name) {
      case 'print':
        break;
    }
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
    this.isLoaded = true;
  }

  constructor(
    private fb: FormBuilder,
    private appState: AppState,
    private router: Router,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private snackBar: MatSnackBar,
    private voiServiceData: VoiService,
    private commanService: CommonService,
    private insuranceService: InsuranceService,
    private userDataService: UserDataService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.formGroup.valueChanges.subscribe(() => {
      this.isFormValid = Object.values(this.formGroup.getRawValue()).every(
        (value) => value
      );
    });
    let insurance = this.appState.get('INSURANCE');
    this.leadId = this.activatedRoute.snapshot.params['id'];
    let createdId = this.userDataService.getLocalStorageValue('currentUser')|| '';
    let data = JSON.parse(createdId);
    let currentSupportId = data[0].current_support_id || '';
    this.getVoiDetailsPayLoad.header.created_by = currentSupportId;
    this.notifyStatusPayload.header.created_by = currentSupportId;
    this.sendIVSPayload.header.created_by = currentSupportId;
    this.getVoiDetailsPayLoad.body.verificationSeqId = parseInt(
      this.leadId
    ).toString();

    this.getVoiDetails();
    this.insuranceSummary = insurance;
    this.insuranceStatus = this.insuranceSummary?.status;
    let route = this.appState.get('ROUTE');

    this.initForm();

    let items = this.formGroup.get('entity') as FormArray;

    if (this.insurance.fields) {
      for (let i = 0; i < this.insurance.fields.length; i++) {
        items.push(this.addNewEntityFormGroup(this.insurance.fields[i]));
      }
    }
  }

  viewPDF() {
    if (this.viewPDFsrc) {
      window.open(this.viewPDFsrc, '_blank', 'noopener,noreferrer');
    }
  }

  async getVoiDetails() {
    this.isLoading = true;
    let parseResponse: any = await this.voiServiceData.getVoiDetailsData(
      this.getVoiDetailsPayLoad
    );
    this.emailStatus = parseResponse?.data?.email_notification_status;

    this.mainResponse = parseResponse;
    this.responseData = JSON.parse(
      parseResponse.data?.verification_input_fields
    );

    this.registeredEmails = parseResponse.data?.lead_notification_email_list;

    this.viewPDFsrc = parseResponse.data.verification_document_loc;
    this.documents = parseResponse.data.insurance_documents;
    if (
      this.documents &&
      this.documents instanceof Array &&
      this.documents.length
    ) {
      this.selectedDocIndex = 0;
      this.pdfSrc = this.documents[this.selectedDocIndex];
    }
    const coverages = this.responseData?.coverages || [];
    if (parseResponse) {
      this.isLoading = false;
      this.formGroup.setValue({
        insuredName: this.responseData?.insuredName || '',
        propertyAddress: this.responseData?.propertyAddress || '',
        policyNumber: this.responseData?.policyNumber || '',
        policyTerm: this.responseData?.policyTerm || '',
        premiumAmount: this.responseData?.premiumAmount || '',
        insuranceStartDate: this.responseData?.insuranceStartDate || '',
        initialPaymentDetails: this.responseData?.initialPaymentDetails || '',
        renewal_Details: this.responseData?.renewal_Details || '',
        insurerNameContact: this.responseData?.insurerNameContact || '',
        agencyNameContact: this.responseData?.agencyNameContact || '',
        mortgagee_clause: this.responseData?.mortgagee_clause || '',
        valid_borrower_name: this.responseData?.valid_borrower_name?.valid
          ? 'Valid'
          : 'Invalid',
        valid_borrower_name_message:
          this.responseData && this.responseData.valid_borrower_name
            ? this.responseData.valid_borrower_name.message
            : '',
        valid_co_borrower_name:
          this.responseData &&
          this.responseData.valid_co_borrower_name &&
          this.responseData.valid_co_borrower_name.valid
            ? 'Valid'
            : 'Invalid',
        valid_co_borrower_name_message:
          this.responseData && this.responseData.valid_co_borrower_name
            ? this.responseData.valid_co_borrower_name.message
            : '',
        valid_property_address:
          this.responseData &&
          this.responseData.valid_property_address &&
          this.responseData.valid_property_address.valid
            ? 'Valid'
            : 'Invalid',
        valid_property_address_message:
          this.responseData && this.responseData.valid_property_address
            ? this.responseData.valid_property_address.message
            : '',
        valid_loan_amount:
          this.responseData &&
          this.responseData.valid_loan_amount &&
          this.responseData.valid_loan_amount.valid
            ? 'Valid'
            : 'Invalid',
        valid_loan_amount_message:
          this.responseData && this.responseData.valid_loan_amount
            ? this.responseData.valid_loan_amount.message
            : '',
        valid_closing_date:
          this.responseData &&
          this.responseData.valid_closing_date &&
          this.responseData.valid_closing_date.valid
            ? 'Valid'
            : 'Invalid',
        valid_closing_date_message:
          this.responseData && this.responseData.valid_closing_date
            ? this.responseData.valid_closing_date.message
            : '',
        validation_summary:
          this.responseData && this.responseData.validation_summary
            ? this.responseData.validation_summary
            : '',
        mortgagee_details: this.formGroup.controls.mortgagee_details.value,
        dwelling_limit: coverages?.length ? coverages[0].limit : '',
        dwelling_deductible: coverages?.length ? coverages[0].deductible : '',
        other_structure_limit: coverages?.length >= 1 ? coverages[1].limit : '',
        other_structure_deductible:
          coverages?.length >= 1 ? coverages[1].deductible : '',
        // loan_number: this.responseData && this.responseData.loan_number ? this.responseData.loan_number : '',
        // user_sequence_id: this.responseData?.sequence_id||"",
      });
      this.setMGvals(this?.responseData?.mortgagee_details ?? []);
      if (this.pdfSrc) {
        this.formGroup.disable();
        this.formDisabled = true;
      }
    }
  }

  setMGvals(data) {
    for (let i = 0; i < data.length; i++) {
      if (this.mortgagee_details.controls[i]) {
        this.mortgagee_details.controls[i].controls.mortgagee.setValue(
          data[i].mortgagee
        );
        this.mortgagee_details.controls[i].controls.loan_number.setValue(
          data[i].loan_number
        );
      } else {
        this.mortgagee_details.controls.push(
          this.fb.group({
            mortgagee: new FormControl(data[i].mortgagee),
            loan_number: new FormControl(data[i].loan_number),
          })
        );
      }
    }
  }

  formAction(e) {
    const allValuesValid = Object.values(this.formGroup.getRawValue()).every(
      (value) => value
    );

    if (this.formDisabled) {
      this.formDisabled = false;
      this.formGroup.enable();
    } else if (allValuesValid) {
      this.generatePDF();
    } else {
      this.toastr.error('Please fill-up all details in form');
    }
  }

  async generatePDF() {
    const allValuesValid = Object.values(this.formGroup.getRawValue()).every(
      (value) => value
    );

    if (allValuesValid) {
      this.isLoading = true;
      this.savePdfDetailsPayLoad.body = this.formGroup.getRawValue();
      // this.savePdfDetailsPayLoad.body.policyNumber =
      //   this.responseData.policyNumber;
      // this.savePdfDetailsPayLoad.body.policyTerm = this.responseData.policyTerm;
      // this.savePdfDetailsPayLoad.body['property address'] =
      //   this.responseData.propertyAddress;
      // this.savePdfDetailsPayLoad.body.premiumAmount =
      //   this.responseData.premiumAmount;
      // this.savePdfDetailsPayLoad.body.insuranceStartDate =
      //   this.responseData.insuranceStartDate;
      // this.savePdfDetailsPayLoad.body.initialPaymentDetails =
      //   this.responseData.initialPaymentDetails;
      // this.savePdfDetailsPayLoad.body.renewal_Details =
      //   this.responseData.renewal_Details;
      // this.savePdfDetailsPayLoad.body.insurerNameContact =
      //   this.responseData.insurerNameContact;
      // this.savePdfDetailsPayLoad.body.agencyNameContact =
      //   this.responseData.agencyNameContact;
      this.savePdfDetailsPayLoad.body.sequence_id =
        this.activatedRoute.snapshot.params['id'];
      // this.savePdfDetailsPayLoad.body.user_sequence_id = this.responseData.user_sequence_id;

      this.savePdfDetailsPayLoad.body.insurance_lead_uuid =
        this.mainResponse.data.insurance_lead_uuid || null;
      this.savePdfDetailsPayLoad.body.user_profile_sequence_id =
        this.mainResponse?.data?.user_profile_sequence_id || '';

      this.savePdfDetailsPayLoad.body.isGeneratePdf = true;
      const updateValue = (keyName) => {
        let message = this.formGroup.value[keyName]
          ? this.formGroup.value[keyName]
          : 'Invalid';
        return {
          valid: message?.toLowerCase() === 'valid',
          message: message ?? '',
        };
      };
      if (this.responseData?.coverages?.length > 0) {
        if (
          this.responseData?.coverages[0] &&
          this.responseData?.coverages[0].limit !==
            this.formGroup.value?.dwelling_limit
        ) {
          this.responseData.coverages[0].limit =
            this.formGroup.value?.dwelling_limit;
        }
        if (
          this.responseData?.coverages[0] &&
          this.responseData?.coverages[0].deductible !==
            this.formGroup.value?.dwelling_deductible
        ) {
          this.responseData.coverages[0].deductible =
            this.formGroup.value?.dwelling_deductible;
        }
        if (
          this.responseData?.coverages?.length >= 1 &&
          this.responseData?.coverages[1] &&
          this.responseData?.coverages[1].limit !==
            this.formGroup.value?.other_structure_limit
        ) {
          this.responseData.coverages[1].limit =
            this.formGroup.value?.other_structure_limit;
        }
        if (
          this.responseData?.coverages?.length >= 1 &&
          this.responseData?.coverages[1] &&
          this.responseData?.coverages[1].deductible !==
            this.formGroup.value?.other_structure_deductible
        ) {
          this.responseData.coverages[1].deductible =
            this.formGroup.value?.other_structure_deductible;
        }
      }
      this.savePdfDetailsPayLoad.body.coverages = this.responseData?.coverages;
      this.savePdfDetailsPayLoad.body.agencyNameContact =
        this.formGroup?.value?.agencyNameContact;
      this.savePdfDetailsPayLoad.body.sequence_id = this.leadId;
      this.savePdfDetailsPayLoad.body.user_profile_sequence_id =
        this.mainResponse?.data?.user_profile_sequence_id;
      this.savePdfDetailsPayLoad.body.loan_number =
        this.responseData?.loan_number;
      this.savePdfDetailsPayLoad.body.valid_closing_date =
        updateValue('valid_closing_date');
      this.savePdfDetailsPayLoad.body.valid_borrower_name = updateValue(
        'valid_borrower_name'
      ); //this.responseData?.valid_borrower_name ?? defaultRes;
      this.savePdfDetailsPayLoad.body.valid_co_borrower_name = updateValue(
        'valid_co_borrower_name'
      ); // this.responseData?.valid_co_borrower_name ?? defaultRes;
      this.savePdfDetailsPayLoad.body.valid_loan_amount =
        updateValue('valid_loan_amount');
      this.savePdfDetailsPayLoad.body.valid_property_address = updateValue(
        'valid_property_address'
      );
      this.savePdfDetailsPayLoad.body.validation_summary =
        (this.formGroup.value.validation_summary ||
          this.responseData?.validation_summary) ??
        '';
      let createdId = this.userDataService.getLocalStorageValue('currentUser') || '';
      let data = JSON.parse(createdId);
      let currentSupportId = data[0].current_support_id || '';
      this.savePdfDetailsPayLoad.header.created_by = currentSupportId || '';
      const {
        dwelling_limit,
        other_structure_limit,
        dwelling_deductible,
        other_structure_deductible,
        ...pdfDetailPayload
      } = this.savePdfDetailsPayLoad as any;
      let parseResponse: any = await this.voiServiceData.saveGeneratePDF(
        pdfDetailPayload
      );

      this.getVoiDetails();
      if (parseResponse) {
        this.formGroup.disable();
        this.isLoading = false;
        this.formDisabled = true;
      }
    } else {
      this.toastr.error('Please fill-up all details in form');
    }
  }

  viewEmailStatus() {
    const dialogStatusRef = this.dialog.open(SendStatusDialogComponent, {
      width: '500px',
      data: {
        header: 'Email Status',
        emails: this.registeredEmails,
        status: this.emailStatus,
      },
    });
  }
  async download() {
    const pdfUrl =
      'https://yoloh-customer-docs-dev.s3.us-west-2.amazonaws.com/cba8d3f1-c94f-4ed2-9df2-f8ab347d0db9/policies/47227854-32a2-4840-bec3-50ffdc5b0ead.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240101T121139Z&X-Amz-SignedHeaders=host&X-Amz-Expires=3599&X-Amz-Credential=AKIA2XVO33WCX7YNVBVL%2F20240101%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Signature=e464baa80dc2fc5768c8e31edf645c04d208ad5eb60d31f3878377e1f099fe68';
    window.open(this.pdfSrc, '_blank', 'noopener,noreferrer');
  }

  resetFormValues() {
    let tempFormVal = this.responseData;
    delete tempFormVal.generatePdf;
    delete tempFormVal.sequence_id;
    delete tempFormVal.isGeneratePdf;
    // delete tempFormVal.userCognitoId;
    delete tempFormVal.user_sequence_id;
    // this.formGroup.setValue(tempFormVal);
    this.formGroup.reset();
    // this.formDisabled = true;
    // this.formGroup.disable();
  }

  async sendEmail() {
    // sendEmailPayload
  }
  async deleteInsuranceDocument() {
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getDeleteInsuranceDocumentPayLoad
    );

    if (parseResponse) {
      this.snackBar.open(parseResponse.message, 'close', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
      this.loadInsuranceCoverage();
    }
  }

  initForm() {
    this.formGroup = this.fb.group({
      id: [this.insuranceSummary.id, Validators.compose([])],
      policynumber: ['', Validators.compose([Validators.required])],
      totalpremium: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(/^-?\d*[.,]?\d{0,2}$/),
        ]),
      ],
      expirationdate: ['', Validators.compose([Validators.required])],
      effectivedate: ['', Validators.compose([Validators.required])],
      policyprovider: ['', Validators.compose([Validators.required])],
      partyinsured: ['', Validators.compose([])],

      policyprovidercontactnumber: ['', Validators.compose([])],
      policyprovideremail: [
        '',
        Validators.compose([
          ,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+.[a-zA-Z]{2,3}$'),
        ]),
      ],
      agentname: ['', Validators.compose([])],
      agentcontactnumber: ['', Validators.compose([])],
      agentemail: [
        '',
        Validators.compose([
          ,
          Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+.[a-zA-Z]{2,3}$'),
        ]),
      ],
      mortgagee_details: this.fb.array([
        this.fb.group({
          mortgagee: [''],
          loan_number: [''],
        }),
      ]),
      coverages: this.fb.array([]),
    });
  }

  get mortgagee_details() {
    return this.formGroup.controls['mortgagee_details'] as any;
  }

  onClick() {
    this.isDisableSave = false;
  }

  onAlternateClick() {
    this.isDisableSave = true;
  }
  async loadInsuranceTypeDetails() {
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsuranceTypeDetailsPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.insuranceTypeDetails = parseResponse.insuranceTypeDetails;
      await this.loadInsuranceCoverage();
    }
  }

  addCoverage(c: any) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    coverages.push(this.createCoverage(c));
  }

  createCoverage(c: any) {
    return this.fb.group({
      sequence_id: [c.sequence_id || '', Validators.compose([])],
      user_insurance_sequence_id: [
        c.user_insurance_sequence_id || '',
        Validators.compose([]),
      ],
      item_name: [c.item_name || '', Validators.compose([])],
      entity: this.fb.array([]),
      coveragedetails: this.fb.array([]),
    });
  }

  getCoverages() {
    let coverages = this.formGroup.get('coverages') as FormArray;
    return coverages.controls;
  }

  getEntityControls(c: number) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let entities = coverageFormGroup.get('entity') as FormArray;
    return entities.controls;
  }

  addEntity(c: any, e: any): void {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let entities = coverageFormGroup.get('entity') as FormArray;

    entities.push(this.addNewEntityFormGroup(e));
    // this.scrollInsuredItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    // this.scrollInsuredItem.nativeElement.scrollTop = this.scrollInsuredItem.nativeElement.scrollHeight;
  }

  getCoverageDetailsControls(c: any) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let coveragedetails = coverageFormGroup.get('coveragedetails') as FormArray;
    return coveragedetails.controls;
  }

  addNewEntityFormGroup(entity: any): FormGroup {
    return this.fb.group({
      item_title: [entity.item_title || ''],
      item_value: [entity.item_value || ''],
    });
  }

  addCoverageDetails(c: any, cd: any): void {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let coveragedetails = coverageFormGroup.get('coveragedetails') as FormArray;

    coveragedetails.push(this.addNewCoverageDetailsFormGroup(cd));
  }

  addNewCoverageDetailsFormGroup(cd: any): FormGroup {
    return this.fb.group({
      coverage_icon: [cd.coverage_icon || ''],
      coverage_icon_disabled: [cd.coverage_icon_disabled || ''],
      coverage_name: [cd.coverage_name || ''],
      coverage_details: [cd.coverage_details || ''],
      coverage_deductible: [
        cd.coverage_deductible || '',
        [Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)],
      ],
      coverage_enabled: [cd.coverage_enabled],
    });
  }

  removeCoverage(c: number) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c];
    if (coverageFormGroup.value && coverageFormGroup.value.sequence_id) {
      this.deleteCoverages.push(coverageFormGroup.value.sequence_id);
      this.updateInsuranceConveragesPayLoad.body.deleteCoverages =
        this.deleteCoverages;
    }

    coverages.removeAt(c);
  }

  removeCoverageDetails(c: number, i: number) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let coveragedetails = coverageFormGroup.get('coveragedetails') as FormArray;
    coveragedetails.removeAt(i);
  }

  removeInsuredItem(c: number, i: number) {
    let coverages = this.formGroup.get('coverages') as FormArray;
    let coverageFormGroup = coverages.controls[c] as FormGroup;
    let entities = coverageFormGroup.get('entity') as FormArray;
    entities.removeAt(i);
  }
  openSendMailDialogue() {
    const dialogRef = this.dialog.open(SendEmailDialogComponent, {
      width: '500px',
      data: {
        header: 'Select Email',
        emails: this.registeredEmails ?? '',
        id: this?.responseData?.user_sequence_id || '',
        leadId: this.leadId,
        document_url: this.mainResponse.data.document_url ?? '',
        action: 'SEND',
        okbutton: 'SEND',
        cancelbutton: 'CANCEL',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {});
  }

  async openStatusNotificationDialogue() {
    this.isLoading = true;
    let notifyPayload = this.notifyStatusPayload;
    notifyPayload.body = {
      verification_sequence_id: this.leadId,
      user_sequence_id: this.mainResponse.data?.user_profile_sequence_id,
      insurance_lead_uuid: this.mainResponse.data.insurance_lead_uuid || null,
      verification_input_fields: this.responseData,
    };
    let isAllValid = true;
    if (!this.responseData?.valid_borrower_name?.valid) {
      isAllValid = false;
    }
    if (!this.responseData?.valid_co_borrower_name?.valid) {
      isAllValid = false;
    }
    if (!this.responseData?.valid_property_address?.valid) {
      isAllValid = false;
    }
    if (!this.responseData?.valid_loan_amount?.valid) {
      isAllValid = false;
    }
    if (!this.responseData?.valid_closing_date?.valid) {
      isAllValid = false;
    }
    notifyPayload.header.message_name = isAllValid
      ? 'send_ivs_completion_email-C'
      : 'send_ivs_details_correction_email-C';
    let parseResponse: any = await this.voiServiceData.getVoiDetailsData(
      notifyPayload
    );
    if (parseResponse) {
      this.snackBar.open('Notify Status Successfully', 'close', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
    }
    this.isLoading = false;
  }

  async onSendIVS() {
    this.isLoading = true;
    let sendIvsPayload = this.sendIVSPayload;
    sendIvsPayload.body = {
      insurance_lead_uuid: this.mainResponse.data.insurance_lead_uuid || null,
      document_url: this.mainResponse.data.document_url ?? '',
    };
    let parseResponse: any = await this.voiServiceData.getVoiDetailsData(
      sendIvsPayload
    );
    if (parseResponse) {
      this.snackBar.open('IVS send Successfully', 'close', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
    }
    this.isLoading = false;
  }

  submit(): any {
    var configError: MatSnackBarConfig = {
      duration: 1000,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['error-snackbar'],
    };
    if (this.formGroup.invalid) {
      this.snackBar.open(
        'Please fill the all required fields details',
        'close',
        configError
      );
      return null;
    }
    let data = this.formGroup.value;

    let coverages: any[] = [];

    for (let c in data.coverages) {
      let coverage: any = data.coverages[c];

      let cover = coverage.coveragedetails.map((cover: any, index: any) => {
        cover.coverage_icon = cover.coverage_icon
          ? cover.coverage_icon
          : this.InitalCharacterURL +
            '/coverages-icons/' +
            cover.coverage_name.charAt(0).toLowerCase() +
            '-enabled.png';

        cover.coverage_icon_disabled = cover.coverage_icon_disabled
          ? cover.coverage_icon_disabled
          : this.InitalCharacterURL +
            '/coverages-icons-disabled/' +
            cover.coverage_name.charAt(0).toLowerCase() +
            '-disabled.png';
      });

      let item: any = {};
      item.item_name = coverage.item_name;
      item.item_details = coverage.entity;

      let coverageJson = {
        sequence_id: coverage.sequence_id,
        user_insurance_sequence_id: this.insuranceSummary.id,
        insured_item: JSON.stringify(item),
        insurance_coverage: JSON.stringify(coverage.coveragedetails),
      };
      coverages.push(coverageJson);
    }

    let policyEffectiveDate = this.datepipe.transform(
      data.effectivedate,
      'yyyy-MM-dd'
    );
    let expirationDate = this.datepipe.transform(
      data.expirationdate,
      'yyyy-MM-dd'
    );

    let userInsuranceAbstract = {
      user_insurance_sequence_id: this.insuranceSummary.id,
      policy_number: data.policynumber,
      policy_effective_date: policyEffectiveDate,
      policy_expiration_date: expirationDate,
      policy_provider: data.policyprovider,
      party_insured: data.partyinsured,
      total_premium: data.totalpremium,
    };

    let userInsuranceContact = {
      user_insurance_sequence_id: this.insuranceSummary.id,
      policy_provider_contact_number: data.policyprovidercontactnumber,
      policy_provider_email: data.policyprovideremail,
      agent_name: data.agentname,
      agent_contact_number: data.agentcontactnumber,
      agent_email: data.agentemail,
      policy_provider: data.policyprovider,
    };

    this.updateInsuranceConveragesPayLoad.body.status = this.insuranceStatus;
    this.updateInsuranceConveragesPayLoad.body.coverages =
      JSON.stringify(coverages);
    this.updateInsuranceConveragesPayLoad.body.userInsuranceAbstract =
      userInsuranceAbstract;
    this.updateInsuranceConveragesPayLoad.body.userInsuranceContact =
      userInsuranceContact;

    this.updateInsuranceCoverage();
  }

  insurancePDFChange() {
    this.selectedDocIndex = this.insurancePDFselector.value;
    this.pdfSrc = '';

    setTimeout(() => {
      this.pdfSrc = this.documents[this.selectedDocIndex];
    }, 7);
  }

  async loadInsuranceCoverage() {
    this.isLoadingUser = true;
    this.getInsuranceConveragesPayLoad.body.sequence_id =
      this.insuranceSummary.id;
    this.getInsuranceConveragesPayLoad.body.user_id =
      this.insuranceSummary.userId;
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.getInsuranceConveragesPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.images = [];

      this.PDFList = parseResponse.userInsurance
        ? parseResponse.userInsurance.insurance_documents
        : [];
      this.PDFList.filter((x: any) => {
        if (x.url.includes('.pdf')) {
          x.type = 'Document';
        } else {
          x.type = 'Image';
        }
      });
      if (this.PDFList.length > 0) {
        if (
          this.PDFList[0].url.includes('.jpeg') ||
          this.PDFList[0].url.includes('.jpg') ||
          this.PDFList[0].url.includes('.png')
        ) {
          this.images.push(this.PDFList[0].url);
        } else if (this.PDFList[0].url.includes('.pdf')) {
          this.pdfSrc = this.PDFList[0].url;
        }
      }

      if (parseResponse.userInsuranceAbstract) {
        this.userInsuranceAbstract = parseResponse.userInsuranceAbstract;
        this.loadInsuranceAbstractForm();
        this.isLoadingUser = false;
      }
      if (parseResponse.userInsuranceContact) {
        this.userInsuranceContact = parseResponse.userInsuranceContact;
        this.loadInsuranceContactForm();
        this.isLoadingUser = false;
      }
      if (
        parseResponse.userInsuranceCoverages &&
        parseResponse.userInsuranceCoverages.length > 0
      ) {
        this.insuranceCoverages = parseResponse.userInsuranceCoverages;
        this.loadInsuranceCoverageForm();
        this.isLoadingUser = false;
      } else {
        this.populateCoverageForm();
        this.isLoadingUser = false;
      }
    }
  }

  loadInsuranceContactForm() {
    if (
      this.userInsuranceContact &&
      this.userInsuranceContact.policy_provider_contact_number
    ) {
      this.formGroup.controls.policyprovidercontactnumber.patchValue(
        this.userInsuranceContact.policy_provider_contact_number
      );
    }
    if (
      this.userInsuranceContact &&
      this.userInsuranceContact.policy_provider_email
    ) {
      this.formGroup.controls.policyprovideremail.patchValue(
        this.userInsuranceContact.policy_provider_email
      );
    }
    if (this.userInsuranceContact && this.userInsuranceContact.agent_name) {
      this.formGroup.controls.agentname.patchValue(
        this.userInsuranceContact.agent_name
      );
    }
    if (
      this.userInsuranceContact &&
      this.userInsuranceContact.agent_contact_number
    ) {
      this.formGroup.controls.agentcontactnumber.patchValue(
        this.userInsuranceContact.agent_contact_number
      );
    }
    if (this.userInsuranceContact && this.userInsuranceContact.agent_email) {
      this.formGroup.controls.agentemail.patchValue(
        this.userInsuranceContact.agent_email
      );
    }
  }

  loadInsuranceAbstractForm() {
    if (this.userInsuranceAbstract && this.userInsuranceAbstract.sequence_id) {
      this.formGroup.controls.id.patchValue(
        this.userInsuranceAbstract.sequence_id
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.policy_number
    ) {
      this.formGroup.controls.policynumber.patchValue(
        this.userInsuranceAbstract.policy_number
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.policy_effective_date
    ) {
      this.formGroup.controls.effectivedate.patchValue(
        this.userInsuranceAbstract.policy_effective_date
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.policy_expiration_date
    ) {
      this.formGroup.controls.expirationdate.patchValue(
        this.userInsuranceAbstract.policy_expiration_date
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.policy_provider
    ) {
      this.formGroup.controls.policyprovider.patchValue(
        this.userInsuranceAbstract.policy_provider
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.party_insured
    ) {
      this.formGroup.controls.partyinsured.patchValue(
        this.userInsuranceAbstract.party_insured
      );
    }

    if (
      this.userInsuranceAbstract &&
      this.userInsuranceAbstract.total_premium
    ) {
      this.formGroup.controls.totalpremium.patchValue(
        this.userInsuranceAbstract.total_premium
      );
    }
  }

  loadInsuranceCoverageForm() {
    if (this.insuranceCoverages) {
      for (let i in this.insuranceCoverages) {
        let insuranceCoverage = this.insuranceCoverages[i];

        if (
          insuranceCoverage.insured_item &&
          insuranceCoverage.insured_item.length > 0
        ) {
          let insuredItems = JSON.parse(insuranceCoverage.insured_item);
          insuranceCoverage.item_name = insuredItems.item_name;
        }
        this.addCoverage(insuranceCoverage);

        if (
          insuranceCoverage.insured_item &&
          insuranceCoverage.insured_item.length > 0
        ) {
          let insuredItems = JSON.parse(insuranceCoverage.insured_item);
          for (let n in insuredItems.item_details) {
            let item = insuredItems.item_details[n];
            this.addEntity(i, item);
          }
        }

        if (
          insuranceCoverage.insurance_coverage &&
          insuranceCoverage.insurance_coverage.length > 0
        ) {
          let insuranceCoverageDetails = JSON.parse(
            insuranceCoverage.insurance_coverage
          );

          for (let entity in insuranceCoverageDetails) {
            let item = insuranceCoverageDetails[entity];
            this.addCoverageDetails(i, item);
          }
        }
      }
    }
  }

  populateCoverageForm() {
    let insuranceTypeDetails = this.insuranceTypeDetails.find(
      (i) => i.insuranceType === this.insuranceSummary.insuranceType
    );
    if (insuranceTypeDetails) {
      for (let e in insuranceTypeDetails.entities) {
        let entity = insuranceTypeDetails.entities[e];
        let coverage = { item_name: entity.title };
        this.addCoverage(coverage);

        for (let d in entity.insuredEntities) {
          let item = entity.insuredEntities[d];
          let insuredItem = { item_title: item, item_value: '' };
          this.addEntity(e, insuredItem);
        }

        for (let c in entity.coverages) {
          let coverage = entity.coverages[c];
          let coverageDetails = {
            coverage_icon_disabled: coverage.icon_disabled,
            coverage_icon: coverage.icon_enabled,
            coverage_name: coverage.name,
            coverage_details: coverage.details,
            coverage_deductible: coverage.deductibles,
            coverage_enabled: false,
          };
          this.addCoverageDetails(e, coverageDetails);
        }
      }
    }
  }

  async updateInsuranceCoverage() {
    this.updateInsuranceConveragesPayLoad.body.sequence_id =
      this.insuranceSummary.id;
    this.updateInsuranceConveragesPayLoad.body.user_id =
      this.insuranceSummary.userId;
    this.isLoading = true;
    let parseResponse: any = await this.insuranceService.getInsurances(
      this.updateInsuranceConveragesPayLoad
    );
    if (parseResponse) {
      this.isLoading = false;
      this.snackBar.open(parseResponse.message, '', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['success-snackbar'],
      });
      this.back();
    }
  }

  back() {
    this.router.navigate(['/ivs']);
  }
}
