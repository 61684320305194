import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { environment } from '../environments/environment';
import { CognitoIdentity, CognitoIdentityCredentials } from 'aws-sdk';
import { ServiceConfigurationOptions } from 'aws-sdk/lib/service';
import * as AWS from 'aws-sdk/global';

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  constructor() {
    Auth.configure({
      Auth: environment.cognito,
    });
  }

  buildCognitoCreds(idTokenJwt: string) {
    return new Promise((resolve, reject) => {
      let url =
        'cognito-idp.' +
        environment.aws.region.toLowerCase() +
        '.amazonaws.com/' +
        environment.cognito.userPoolId;
      let loginMap: CognitoIdentity.LoginsMap = {};
      loginMap[url] = idTokenJwt;
      let params = {
        IdentityPoolId: environment.cognito.identityPoolId /* required */,
        Logins: loginMap,
      };
      AWS.config.region = environment.aws.region;
      let serviceConfigs = <ServiceConfigurationOptions>{};
      let cred = new CognitoIdentityCredentials(params, serviceConfigs);
      cred.get((err) => {
        if (err) {
          reject(err);
        } else {
          const temCred = {
            _accessKey: cred.accessKeyId,
            _secretAccessKey: cred.secretAccessKey,
            _sessionToken: cred.sessionToken,
            _tokenExpirationDate: cred.expireTime,
          };
          resolve(temCred);
        }
      });
    });
  }

  signUp(username: any, password: any, name: any): any {
    try {
      return Auth.signUp({
        username,
        password,
        attributes: {
          name,
        },
      });
    } catch (error) {}
  }

  signIn(username: any, password: any): any {
    try {
      return Auth.signIn(username, password);
    } catch (error) {}
  }

  confirmSignUp(user: any): any {
    try {
      return Auth.confirmSignUp(user.email, user.code);
    } catch (error) {}
  }

  resendOtp(email: any): any {
    try {
      return Auth.resendSignUp(email);
    } catch (err) {}
  }

  forgotPassword(email: any): any {
    try {
      return Auth.forgotPassword(email);
    } catch (err) {}
  }

  confirmPass(email: any, otp: any, password: any): any {
    try {
      return Auth.forgotPasswordSubmit(email, otp, password);
    } catch (err) {}
  }

  async checkCognitoUserSession() {
    const getAwsCredentials = await Auth.currentCredentials();
    const awsCredentials = await Auth.essentialCredentials(getAwsCredentials);
    // accessKeyId, secretAccessKey, sessionToken post login
    return { awsCredentials };
  }
}
