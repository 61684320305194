import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class AWSHelperService {
    private host = {
        host1: 's7kjy98veh.execute-api.us-west-2.amazonaws.com',
        host2: '6tcewwdp8d.execute-api.us-west-2.amazonaws.com'
    };

    private APIGroup: { [key: string]: string } = {
        '/profile-info': this.host.host2,
        '/insurance': this.host.host2,
        '/article': this.host.host2,
        '/feedback': this.host.host2
    };

    constructor() { }

    getHost = (APIGroupName: any) => {
        return this.APIGroup[APIGroupName];
    }
}
