import { AuthResponseData } from '../interfaces/auth-response.interface';
import { UserInterface } from '../interfaces/user.interface';
import { User } from '../models/user.model';
import { UserDataService } from './user-data.service';
import { CryptoService } from './crypto.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { serverCodes } from '../server-codes';
import { tap } from 'rxjs/operators';
import { allURLS } from './allURL';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import {environment} from "../environments/environment";
// import { AppsyncService } from './appsync.service';

// import AWSAppSyncClient from 'aws-appsync';
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  // client: AWSAppSyncClient<any>;
  client: any;
  isNull: any = null;
  user = new BehaviorSubject<User>(this.isNull);
  // user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;

  payLoadRefreshToken = {
    "header": {
      "message_uuid": "",
      "message_name": "user_temporary_credentials_c",
      "message_type": "Q",
      "correlation_uuid": "",
      "request_id": "",
      "version": "1.0",
      "service_completion_status_code": "",
      "created_by": '',
      "created_timestamp": new Date().toLocaleString('en-GB')
    },
    "body": {
      "refreshToken": ''
    }
  }

  private _userDetails: {} = {
    email: '',
    type: ''
  }

  apiUrl = environment.api_endpoint;

  // private appsync: AppsyncService,
  constructor(private http: HttpClient, private router: Router,
    private userDataService: UserDataService,
    private cryptoService: CryptoService, private snackBar: MatSnackBar) {

  }

  /* function to call the Api for authenticating users
    ** Input params :
          - email
          - password
          - type
     ** Returns void/promise/data object
  */
  login(payLoad: any) {
    try {
      return this.http.post(
        this.apiUrl,
        payLoad
      )
        .pipe(
          tap(
            data => {
              let resData: any = data;
              let body: AuthResponseData = resData.body;
              if (Object.keys(resData.statusCode)) {
                if (resData.statusCode === serverCodes['USER_REGISTERED']) {
                  this.handleAuthentication(body)
                }
              }
            }
          )
        ).toPromise()
    } catch (err) {
      return null;
    }
  }

  autoLogin(forceCheck = false) {
    if (!this.userDataService.getUserData()) {
      return;
    }
    const userLocalData: UserInterface = this.userDataService.getUserData();
    const awsCredData = this.userDataService.getAwsCred();
    const userData : UserInterface = {...userLocalData, ...awsCredData}
    const loadedUser = new User(
      userData._sessionToken,
      userData._secretAccessKey,
      userData._accessKey,
      new Date(userData._tokenExpirationDate),
      userData._idToken,
      userData.cognitoId,
      userData.email,
      userData._refreshToken,
      userData.type,
      userData.name
    );
    this.userDataService.setUserData(loadedUser);
    if (new Date() > new Date(userData._tokenExpirationDate)) {
      this.logout();
      return;
    }
    if (loadedUser.sessionToken) {
      this.user.next(loadedUser);
      const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime();
      this.autoRefreshToken(expirationDuration);
      if (forceCheck) {
        this.router.navigate(['/customers']);
      }
      // this.router.navigate(['']);
    } else {
      this.router.navigate(['']);
    }
  }
  autoRefreshToken(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      // this.refreshToken();
      var configError: MatSnackBarConfig = {
        duration: 1000,
        verticalPosition: "top",
        horizontalPosition: "right",
        panelClass: ['error-snackbar']
      }
      this.snackBar.open('Session Timeout', 'close', configError);
      this.logout();
    }, (expirationDuration - 300000))
    // }, 2000)
  }

  // refreshToken() {
  //   const user = this.userDataService.getUserData();

  //   this.payLoadRefreshToken.body.refreshToken = user._refreshToken;
  //   this.payLoadRefreshToken.header.created_by = user.cognitoId;

  //   let payLoad = {"body": JSON.stringify(this.payLoadRefreshToken).toString()};

  //   try {
  //     return this.http.post(
  //       this.apiUrl,
  //       payLoad
  //     ).subscribe(
  //       data => {
  //         let response:any = data;
  //         let body = JSON.parse(response.body);

  //         let user = this.user.getValue();

  //         user.sessionToken = body.SessionToken;
  //         user.secretAccessKey = body.SecretAccessKey;
  //         user.accessKey = body.AccessKey;
  //         user.tokenExpirationDate = body.ExpirationTime;

  //         this.user.next(user);
  //         this.userDataService.setUserData(user);
  //       }
  //     )
  //   } catch(err) {
  //     return null;
  //   }
  // }

  /* function to call realising resources and clear cache and localstorage
  */
  logout() {
    this.user.next(this.isNull);
    this.router.navigate(['/login']);
    this.userDataService.removeUserData();
    this.userDataService.removeUserProfileData();
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

  private handleAuthentication(body: AuthResponseData) {

    const expirationDate = new Date(new Date(body.expirationTime).getTime());
    const user = new User(
      body.sessionToken,
      body.secretAccessKey,
      body.accessKey,
      expirationDate,
      body.idToken,
      body.cognitoUid,
      body.email,
      body.refreshToken,
      body.userType,
      body.name
    );
    this.user.next(user);
    this.userDataService.setUserData(user);
    // this.autoRefreshToken((expirationDate.getTime() - new Date().getTime()));
    this.userDataService.setUserData(user);
  }

  /* function to call the Api for user sign up
    ** Input params :
          - full name
          - email
          - password
     ** Returns void/promise
  */
  signup(payLoad: any) {
    try {
      return this.http.post(
        this.apiUrl,
        payLoad
      )
    } catch (err) {
      return null;
    }

  }

  /* function to call the Api for confirming users via OTP
      ** Input params :
            - email
            - OTP
       ** Returns void/promise
    */
  confirmSignup(payLoad: any) {
    try {
      return this.http.post(
        this.apiUrl,
        payLoad
      )
    } catch (err) {
      return null;
    }
  }

  /* function to call the Api for resending OTP
      ** Input params :
            - email
       ** Returns void/promise
    */
  resendOTP(payLoad: any) {
    try {
      return this.http.post(
        this.apiUrl,
        payLoad
      );
    } catch (err) {
      return null;
    }
  }

  /* function to call the Api to send OTP for new password(forgot password)
   ** Input params :
         - email
    ** Returns void/promise/data object
 */
  forgotPassword(payLoad: any) {
    try {
      return this.http.post(
        this.apiUrl,
        payLoad
      );
    } catch (err) {
      return null;
    }
  }

  /* function to call the Api for confirming the new password
    ** Input params :
          - email
          - password
          - OTP
     ** Returns void/promise/data object
  */
  confirmForgotPassword(payLoad: any) {
    try {
      return this.http.post(
        this.apiUrl,
        payLoad
      );
    } catch (err) {
      return null;
    }
  }

  /* function to set the user name and email so that it can be retrieved from any component
    ** Input params :
          - name
          - email
     ** Returns void
  */
  setUserDetails(type: string, email: string) {
    this._userDetails = {
      type: type,
      email: email
    }
  }

  /* function to get the user name and email of the current user
    ** No Input params
     ** Returns user data object(name and email)
  */
  getUserDetails() {
    return this._userDetails;
  }

}
