import React, { useEffect } from "react";
import Chats from "./Chats";
import UserList from "./UserList";
import "../Custom.css";

const Home = () => {

  return (
    <>
      <div className="container-fluid" key={"container-fluid"}>
        <div className="row" key={"row"}>
          <UserList key={"hello"} />
        </div>
        {/* <Chats/> */}
      </div>
    </>
  );
};

export default Home;
