import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {

  action: string;
  dialogData: any;
  document:any;

  constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

      this.dialogData = { ...data };
      this.action = this.dialogData.action;
     }

  ngOnInit(): void {
  }
  
  delete(){
    this.dialogRef.close({ event: this.action });
  }
  cancel() {
    this.dialogRef.close();
  }
}
